import React, {Component} from 'react';
import {Button, Popup, TileView} from 'devextreme-react';
import $ from "jquery";
import LRH from "../helpers/LeopardReactHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LDH from "../helpers/LeopardDataHelper";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardPhotoGalleryPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tileViewItems: [],
            popupVisible: false
        };
    }

    tileViewItemImageOnClick = (data) => {
        $(".leopard-tileviewitem-image").removeClass("selected");
        $("." + data.id).addClass("selected");
        $(".leopard-photogallery-enlarged-image").attr("src", data.originalImageUrl);
    }

    photoGalleryImageOnError = () => {
        LRH.ShowToast("Your request has expired or photo could not be found, " +
            "please close this window and try again.", "error", 5000);
    }

    photoGalleryFullScreenOnClick = () => {
        window.open($(".leopard-photogallery-enlarged-image").attr("src"));
    }

    tileViewItem = (data) => {
        let isSelected = "";
        if (data.id === this.state.tileViewItems[0].id) {
            isSelected = "selected";
        }
        return (
            <React.Fragment>
                <div className={"leopard-thumbnail-image"} style={{width: "100%", height: "100%", padding: "5px"}}
                     onClick={() => this.tileViewItemImageOnClick({
                         originalImageUrl: data.originalImageUrl, id: data.id
                     })}>
                    <div className={data.id + " leopard-tileviewitem-image " + isSelected}
                         style={{backgroundImage: `url(${data.imageUrl})`}}/>
                </div>
            </React.Fragment>
        );
    }

    popupOnHide = () => {
        this.setState({popupVisible: false, tileViewItems: []});
        $(".leopard-photogallery-enlarged-image").removeAttr("src");
        $(".leopard-photocallery-container-nophoto").hide();
    }

    popupOnShowing = () => {
        $(".leopard-popupwindow-loading-progress").show();
    }

    popupOnShown = () => {
        let that = this;
        let sourceId = LeopardStaticUIConfig.Global_PopupCustomColumnData.data.dataSourceId;
        let itemId = LeopardStaticUIConfig.Global_PopupCustomColumnData.e.row.data[sourceId];

        LeopardAjaxHelper.GetAttachmentsByItemId(itemId, function (result) {
            let items = [];
            if (result !== null && result.message !== null && result.message.length > 0) {
                for (let i = 0; i < result.message.length; i++) {
                    if (result.message[i].DisplayType === "TN" &&
                        result.message[i].ContentType === "image/jpeg") {
                        let originalImageUrl = "";

                        for (let j = 0; j < result.message.length; j++) {
                            if (result.message[j].FileName === result.message[i].FileName &&
                                result.message[j].DisplayType === "IMG" &&
                                result.message[j].ContentType === "image/jpeg") {
                                originalImageUrl = result.message[j].Url;
                                break;
                            }
                        }

                        items.push({
                            imageUrl: result.message[i].Url,
                            id: LDH.GenerateGuid(),
                            itemId: itemId,
                            originalImageUrl: originalImageUrl
                        });
                    }
                }
            }

            if (items.length === 0) {
                $(".leopard-popupwindow-loading-progress").hide();
                $(".leopard-photocallery-container-nophoto").show();
            } else {
                that.setState({tileViewItems: items}, function () {
                    let firstItem = that.state.tileViewItems[0];
                    let firstOriginalUrl = firstItem.originalImageUrl;
                    $(".leopard-photogallery-enlarged-image").attr("src", firstOriginalUrl);
                    $(".leopard-popupwindow-loading-progress").hide();
                    $(".leopard-photocallery-container-nophoto").hide();
                });
            }
        }, function () {
            LRH.ShowToast("Failed to retrieve photos from the server.", "error", 5000);
            $(".leopard-popupwindow-loading-progress").hide();
            $(".leopard-photocallery-container-nophoto").show();
        });
    }

    render() {
        return (
            <Popup className={'leopard-popup-window'} visible={this.state.popupVisible} dragEnabled={true}
                   closeOnOutsideClick={false} onHiding={this.popupOnHide} onShown={this.popupOnShown}
                   showTitle={true} title={this.props.popupTitle} width={this.props.popupWidth}
                   ref={this.props.popupInstance} height={this.props.popupHeight} onShowing={this.popupOnShowing}>
                <div className={"leopard-popupwindow-loading-progress"}>
                    <i className="fas fa-spinner fa-pulse" style={{color: "#FF8000", fontSize: "25px"}}></i>
                </div>
                <div style={{
                    position: "absolute",
                    zIndex: 1,
                    height: "100%",
                    backgroundColor: "white",
                    width: "100%"
                }}
                     className={"leopard-photocallery-container-nophoto"}>
                    <div style={{textAlign: "center", height: "100%", marginTop: "25%", fontSize: "14px"}}>
                        Sorry, there are no photos attached to this item.
                    </div>
                </div>
                <div style={{display: "flex", height: "560px"}}>
                    <div className={"leopard-photocallery-container"}>
                        <TileView items={this.state.tileViewItems} width={210} height={"100%"}
                                  baseItemHeight={140}
                                  direction={"vertical"} baseItemWidth={210} itemMargin={0}
                                  itemComponent={this.tileViewItem}/>
                    </div>
                    <div style={{width: "100%", padding: "5px"}}>
                        <img className={"leopard-photogallery-enlarged-image"}
                             onError={(e) => this.photoGalleryImageOnError(e)}
                             alt={""}/>
                    </div>
                </div>
                <div style={{padding: "10px 0px 10px 5px", clear: "both"}}>
                        <span style={{padding: "0px 5px 0 0", float: "right"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}}
                                    text={"Full Screen"}
                                    onClick={(e) => this.photoGalleryFullScreenOnClick(e)}/>
                        </span>
                </div>
            </Popup>
        )
    }
}

export default LeopardPhotoGalleryPopup;