import React, {Component} from 'react';
import {Popover} from 'devextreme-react/popover';
import {Button} from "devextreme-react";
import LeopardDataHelper from "../helpers/LeopardDataHelper";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardTooltipWithLink extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    onMouseEnter = () => {
        this.setState({visible: true});
    }

    onMouseLeave = () => {
        this.setState({visible: false});
    }

    render() {
        let additionalClass = "";
        if (!LeopardDataHelper.IsObjectNull(this.props.additionalClass)) {
            additionalClass = this.props.additionalClass;
        }
        return (
            <React.Fragment>
                    <span onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()}>
                    <Button text={this.props.labelText} className={"leopard-buttonlink " + additionalClass}
                            id={this.props.elementId}
                            onClick={(e) => this.props.onClick({
                                e: e, gridViewId: this.props.gridViewId
                            })
                            }/>
                    </span>
                <div style={{position: "absolute"}}>
                    <Popover target={"#" + this.props.elementId} position={'top'}
                             showTitle={true} title={this.props.title}
                             width={this.props.width} visible={this.state.visible}
                             animation={LeopardStaticUIConfig.TooltipAnimationConfig}>
                        {this.props.text}
                    </Popover>
                </div>
            </React.Fragment>);
    }
}

export default LeopardTooltipWithLink;