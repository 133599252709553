import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import LeopardLoginUserInfoPanel from '../components/LeopardLoginUserInfoPanel';

class LeopardTopMenuBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLeftMenuContent: true
        }
    }

    componentDidMount = () => {
        $(".leopard-loggedin-user-tag").off("click").on("click", function () {
            if ($(".leopard-login-userinfo-panel").is(":visible")) {
                $(".leopard-login-userinfo-panel").hide();
            }
            else {
                $(".leopard-login-userinfo-panel").show();
            }
            return false;
        });
    };

    loginUserInfoTagOnClick = () => {
        if (this.state.showLoginUserInfoPanel === false) {
            this.setState({ showLoginUserInfoPanel: true });
        }
        else {
            this.setState({ showLoginUserInfoPanel: false });
        }
    };

    navHideButtonOnClick = (e) => {
        let that = this;
        if (this.state.showLeftMenuContent === false) {
            this.setState({ showLeftMenuContent: true }, function () {
                that.props.navHideButtonOnClick(e);
            });
        }
        else {
            this.setState({ showLeftMenuContent: false }, function () {
                that.props.navHideButtonOnClick(e);
            });
        }
    };

    topMenuNavigationBarRender = () => {
        if (this.props.state.selectedLeftMenuItem === undefined ||
            this.props.state.selectedLeftMenuItem === null) {
            return (
                <React.Fragment>
                    <div className={"leopard-topmenu-bar-right"}>
                        <div className="leopard-navbar-title-text"></div>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div className={"leopard-topmenu-bar-right"}>
                    <div className="leopard-navbar-title-text">
                        <span style={{ marginRight: "15px" }}>
                            {this.props.state.selectedLeftMenuItem.parentMenuItemName === "Dashboards" ?
                            "Workspaces" : this.props.state.selectedLeftMenuItem.parentMenuItemName}
                        </span>
                        <span>/</span>
                        <span style={{ marginLeft: "15px" }}>
                            {this.props.state.selectedLeftMenuItem.menuItemName}
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    render() {
        return (
            <React.Fragment>
                <div style={{ height: "50px" }}>
                    <img className="leopard-top-logo" src="/css/images/LeopardLogoSmall.png" alt="Leopard Systems" />
                    <div className={"leopard-welcome-text"}>
                        Welcome <span style={{ fontWeight: "bold" }}>{this.props.loggedInUserName}</span>
                    </div>
                    <div className={"leopard-loggedin-user-tag fas fa-user-circle"}></div>
                </div>
                <div id={"LeopardTopMenu"} className="leopard-top-menu-bar">
                    {
                        this.state.showLeftMenuContent === false ? "" :
                            <div className={"leopard-topmenu-bar-left"}>
                                {/* <TextBox className={"leopard-topmenu-search"}
                                    placeholder={"Search for menu items..."} /> */}
                            </div>
                    }
                    <div className={"leopard-topmenu-bar-hidebutton fas " +
                        (this.state.showLeftMenuContent ? "fa-caret-left" : "fa-caret-right")}
                        onClick={(e) => this.navHideButtonOnClick(e)}></div>
                    {this.topMenuNavigationBarRender()}
                </div>
                <div className="leopard-login-userinfo-panel" style={{ display: "none" }}>
                    <LeopardLoginUserInfoPanel />
                </div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return { state: state };
};

export default connect(RetrieveDataFromReducer)(LeopardTopMenuBar);