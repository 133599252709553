import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import {Popup} from 'devextreme-react/popup';
import LeopardPhotoGalleryPopup from '../components/LeopardPhotoGalleryPopup';

import DataGrid, {
    Paging, Pager, ColumnChooser, FilterRow, ColumnFixing, Sorting, RemoteOperations,
    HeaderFilter, LoadPanel, Editing, Position, Export, Form, Column, Selection
} from 'devextreme-react/data-grid';

import LeopardSecurity from '../security/LeopardSecurity';
import LDH from '../helpers/LeopardDataHelper';
import LRH from '../helpers/LeopardReactHelper';

import {
    InitCustomStore, UpdateGridViewDefinition
} from './LeopardActionCreators';

import {
    LeopardGridViewColumnBuilder, LeopardGridViewToolbar, LeopardGridViewEditFormBuilder
} from '../datashaping/LeopardGridViewBuilders';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';

class LeopardGridView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            groupingEnabled: false,
            currentRowDataBeingEdited: null,

            // ---- For "UseStateStore => True" Only ----
            customStore: null
            // ------------------------------------------
        };

        this.totalRecordCount = 0;
        this.optimizePagerForLargeDataset = true;
        this.defaultPageSize = 10;
        this.enableAutoRefresh = false;
        this.autoRefreshInterval = 30;
        this.customColumnOverallWidth = 200;
        this.showAutoRefreshSwitch = true;
        this.gridDefinition = {columnDefinition: []};
        this.isGridViewLayoutStateLoaded = false;
        this.customColumnConfiguration = null;
        this.retryConnectionCount = 0;
        this.hasCustomizeColumnsInitialized = false;
        this.combinedFilterHistory = [];
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
        this.isFirstRowSelectedOnInitLoad = false;

        this.uiObjectInstance = {
            gridViewInstance: null,
            popupInstance: null
        };
    }

    setGridViewInstance = (ref) => {
        let that = this;
        if (ref === null || ref.instance === null) return;
        this.uiObjectInstance.gridViewInstance = ref.instance;

        this.props.setGridViewInstance({
            instance: ref.instance,
            id: this.props.GridViewId,
            type: "datagrid",
            isDataView: true,
            optimizePagerForLargeDataset: this.optimizePagerForLargeDataset
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.GridViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstance.gridViewInstance.option("relationships", linkedList);
        }

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId: dashboardItemId,
                props: that.props,
                instance: ref.instance,
                callback: function (data) {
                    for (var i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let gridDefinition = this.props.gridDefinition;
                            let params = gridDefinition.oDataQueryForLinkedView;

                            if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
                                if (LDH.IsObjectNull(data.dataFromSource)) {
                                    this.instance.option("setBlankPostProcess", true);
                                    this.instance.refresh();
                                } else {
                                    params = LDH.ConvertArrayMacroToString(params, data.dataFromSource);
                                    this.instance.option("customQueryParams", params);
                                    this.instance.option("setBlankPostProcess", false);
                                    this.instance.option("selectFirstRow", true);
                                    this.instance.refresh();
                                }
                            }
                        }
                    }
                }
            });
        }
    };

    componentDidMount = () => {
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            this.initializeCustomStore(true);
        } else {
            this.initializeCustomStore(false);
        }
    };

    componentWillUnmount = () => {
        let instance = this.uiObjectInstance.gridViewInstance;
        if (instance !== undefined && instance !== null) {
            instance.dispose();
        }
        this.props.setGridViewInstance({
            instance: null,
            id: this.props.GridViewId,
            type: "datagrid",
            isDataView: true,
            optimizePagerForLargeDataset: this.optimizePagerForLargeDataset
        });
    };

    initializeCustomStore = (isDataView) => {
        let thisComp = this;
        let gridViewId = this.props.GridViewId;
        let gridDefinition = this.props.gridDefinition;
        let limitedColumns = [];
        let clientSideQuery = "";

        for (var u = 0; u < gridDefinition.columnDefinition.length; u++) {
            if (!LDH.IsValueEmpty(gridDefinition.columnDefinition[u].isEnabled) &&
                gridDefinition.columnDefinition[u].isEnabled === false) {
                limitedColumns.push(gridDefinition.columnDefinition[u].columnName);
            }
        }

        if (!LDH.IsValueEmpty(gridDefinition.clientSideQuery)) {
            clientSideQuery = gridDefinition.clientSideQuery;
        }

        let hasCustomQueryParams = false;
        if (!LDH.IsObjectNull(gridDefinition.oDataQueryForLinkedView) &&
            !LDH.IsValueEmpty(gridDefinition.oDataQueryForLinkedView)) {
            hasCustomQueryParams = true;
        }

        let url = this.props.GetDataFromUrl.replace("?tableName=", "");
        let fullColumns = this.props.columnFieldList.fullColumns;

        let store = LRH.InitCustomStoreForGridView(gridDefinition, gridViewId, url,
            clientSideQuery, limitedColumns, function (gridData, ds) {
                thisComp.totalRecordCount = ds.totalCount();
                if (!LDH.IsObjectNull(gridData) && !LDH.IsObjectNull(gridData.length)) {
                    thisComp.disableOrEnablePagerBasedOnRowCount(gridData.length, gridViewId);
                }
                $(".toolbar-warming-up-text", "#gridViewToobar_" + gridViewId).hide();
                thisComp.validateUserAuthenticationStatus();
                LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);

                let instance = thisComp.uiObjectInstance.gridViewInstance;
                let selectByDefault = LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault;

                if (!LDH.IsObjectNull(instance) && gridData.length > 0 &&
                    !LDH.IsObjectNull(instance.option("selectFirstRow")) &&
                    instance.option("selectFirstRow") && !isDataView) {
                    instance.selectRowsByIndexes(0);
                    instance.option("selectFirstRow", false);
                } else if (!isDataView && thisComp.props.dashboardLevel === 1 &&
                    thisComp.isFirstRowSelectedOnInitLoad === false) {
                    thisComp.isFirstRowSelectedOnInitLoad = true;
                    instance.selectRowsByIndexes(0);
                } else if (!isDataView && selectByDefault === true) {
                    thisComp.isFirstRowSelectedOnInitLoad = true;
                    instance.selectRowsByIndexes(0);
                }
            }, fullColumns, this, isDataView, hasCustomQueryParams);

        if (thisComp.props.dataInitializedOnControls === false) {
            thisComp.props.dataInitializedOnControlsUpdateRequest();
        }

        if (isDataView === false) {
            this.gridDefinition = thisComp.props.gridDefinition;
            this.setState({customStore: store});
        } else {
            this.props.InitCustomStore(store, gridViewId);
            this.props.UpdateGridViewDefinition(gridDefinition, gridViewId);

            if (LRH.IsControlCentreInTestMode() === false) {
                this.props.applyGridViewConfigurationOnDataView({
                    filterBuilderValue: gridDefinition.filterBuilderValue,
                    commandButtons: gridDefinition.commandButtons,
                    explicitFilterOption: gridDefinition.explicitFilterOption,
                    disabledFieldsForEditing: gridDefinition.disabledFieldsForEditing,
                    hiddenFieldsForEditing: gridDefinition.hiddenFieldsForEditing,
                    columnResizingMode: gridDefinition.columnResizingMode
                });
            }
        }
    };

    validateUserAuthenticationStatus = () => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
        }, function (error) {
            if (error === "not authenticated") {
                LeopardSecurity.UserLogout();
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            }
        });
    };

    onRowExpanding = (e) => {
        e.component.collapseAll(-1);
    };

    onToolbarPreparing = (e) => {
        let id = this.props.GridViewId.toString();
        let headerPanelId = "#" + id + " .dx-datagrid-header-panel";
        if (this.state.groupingEnabled === false) {
            $(headerPanelId).hide();
        } else {
            $(headerPanelId).show();
        }

        let allowEditing = false;
        if (this.props.commandButtons !== undefined && this.props.commandButtons !== null &&
            this.props.commandButtons.length > 0) {
            for (let k = 0; k < this.props.commandButtons.length; k++) {
                if (this.props.commandButtons[k] === "modify-row") {
                    allowEditing = true;
                }
                if (this.props.commandButtons[k] === "add-row") {
                    allowEditing = true;
                }
                if (this.props.commandButtons[k] === "delete-row") {
                    allowEditing = true;
                }
            }
        }

        let addRowId = "#" + id + "_toolbar-button-addrow";
        if (allowEditing === false) {
            $(addRowId).hide();
        } else {
            $(addRowId).show();
        }

        let toolbarItems = e.toolbarOptions.items;
        for (var i = 0; i < toolbarItems.length; i++) {
            if (toolbarItems[i].name === "addRowButton" ||
                toolbarItems[i].name === "exportButton") {
                toolbarItems[i].visible = false;
            }
        }
    };

    onInitialized = (e) => {
        e.component.columnOption("command:edit", "width", 100);
    };

    onDataErrorOccurred = (e) => {
        let that = this;
        let gridViewId = this.props.GridViewId;

        if (!LDH.IsObjectNull(e.error) && !LDH.IsObjectNull(e.error.httpStatus) &&
            e.error.httpStatus === 504 && this.retryConnectionCount < 10) {
            this.retryConnectionCount += 1;
            $(".toolbar-warming-up-text", $("#gridViewToobar_" + gridViewId)).show();

            setTimeout(function () {
                let $root = $("#" + gridViewId);
                let $error = $(".dx-error-row .dx-error-message", $root);
                $error.addClass("leopard-error-message-override").text("");
                that.uiObjectInstance.gridViewInstance.refresh();
            }, 100);
        } else {
            if (that.props.dataInitializedOnControls === false) {
                that.props.dataInitializedOnControlsUpdateRequest();
            }
            $(".toolbar-warming-up-text", $("#gridViewToobar_" + gridViewId)).hide();

            setTimeout(function () {
                let $root = $("#" + gridViewId);
                let $error = $(".dx-error-row .dx-error-message", $root);
                $error.removeClass("leopard-error-message-override");
            }, 100);
            this.retryConnectionCount = 0;
            LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
        }
    };

    registerGridViewColumnHeaderEvents = () => {
        let that = this;
        $(".dx-texteditor-input-container", "#" + this.props.GridViewId).each(function () {
            $("input.dx-texteditor-input", $(this)).each(function () {
                $(this).off("keypress").on("keypress", function (e) {
                    if (e.which === 13) {
                        let id = that.props.GridViewId;
                        if ($("#GridView_TopBar_ApplyFilter_" + id).hasClass("leopard-ui-disabled")) {
                            return;
                        }
                        $(".dx-datagrid-toolbar-button.dx-apply-button", "#" + id).trigger("click");
                    }
                })
            });
        });

        $(".dx-overlay-wrapper.dx-datagrid-filter-range-overlay .dx-texteditor-input-container").each(function () {
            $("input.dx-texteditor-input", $(this)).each(function () {
                $(this).off("keypress").on("keypress", function (e) {
                    if (e.which === 13) {
                        let id = that.props.GridViewId;
                        if ($("#GridView_TopBar_ApplyFilter_" + id).hasClass("leopard-ui-disabled")) {
                            return;
                        }
                        $(".dx-datagrid-toolbar-button.dx-apply-button", "#" + id).trigger("click");
                    }
                })
            });
        });
    };

    resetPagingAndRefresh = (isRefresh, gridViewId) => {
        let $pager = $(".leopard-page-number.selected", "#GridViewPager_" + gridViewId);

        let pageSize = this.uiObjectInstance.gridViewInstance.pageSize();
        if (!LDH.IsObjectNull($pager) && $pager.length > 0) {
            pageSize = parseInt($pager.attr("pageSize").trim());
        }

        this.uiObjectInstance.gridViewInstance.option("customPagingOperation", "");
        this.uiObjectInstance.gridViewInstance.option("customPagingIndex", 0);
        this.uiObjectInstance.gridViewInstance.option("customPagingSize", pageSize);
        if (isRefresh) this.uiObjectInstance.gridViewInstance.refresh();
    }

    onContentReady = (e) => {
        let that = this;
        let $grid = $("#" + this.props.GridViewId);

        // ------ Temporary hide the group panel for now until we work on it. ----------
        let headerPanelId = "#" + this.props.GridViewId + " .dx-datagrid-header-panel";
        $(headerPanelId).hide();
        // -----------------------------------------------------------------------------

        let pager = e.component.getView("pagerView").element().dxPager("instance");
        if (e.element.clientWidth <= 495) {
            pager.option("lightModeEnabled", true);
        } else {
            pager.option("lightModeEnabled", false);
        }

        let gridViewId = this.props.GridViewId;
        if (that.optimizePagerForLargeDataset === true) {
            $(".leopard-page-number", "#GridViewPager_" + gridViewId).each(function () {
                $(this).off("click").on("click", function () {
                    if ($("#GridViewPager_" + gridViewId).hasClass("leopard-ui-disabled")) {
                        return false;
                    }
                    let $id = $("#GridViewPager_" + gridViewId);
                    $(".leopard-page-number", $id).removeClass("selected");
                    $(this).addClass("selected");
                    that.resetPagingAndRefresh(true, gridViewId);
                    return false;
                });
            });
        }

        $(".leopard_gridview_column_template .dx-editor-with-menu", $grid).each(function () {
            $(this).off("mousedown").on("mousedown", function (e) {
                setTimeout(function () {
                    that.registerGridViewColumnHeaderEvents();
                }, 100);
            });
        });
        $(".leopard_gridview_column_template .dx-menu-item-wrapper", $grid).each(function () {
            $(this).off("mouseenter").on("mouseenter", function (e) {
                setTimeout(function () {
                    $(".dx-overlay-wrapper .dx-menu-item-content").each(function () {
                        $(this).off("click").on("click", function () {
                            setTimeout(function () {
                                that.registerGridViewColumnHeaderEvents();
                            }, 500);
                        })
                    });
                }, 500);
            });
        });
        that.registerGridViewColumnHeaderEvents();
    };

    // ------------------------------------------------------------------------------
    // For future development: drag and drop feature.
    // ------------------------------------------------------------------------------
    onContentReady_New = () => {
        let gridViewId = this.props.GridViewId;
        let allowDragging = this.props.allowDragging;
        let instance = this.uiObjectInstance.gridViewInstance;

        if (this.initDraggingOnGridViewTimeout !== null) {
            clearTimeout(this.initDraggingOnGridViewTimeout);
        }

        this.initDraggingOnGridViewTimeout = setTimeout(() => {
            $('.dx-data-row', $("#" + gridViewId)).each(function () {
                $(this).addClass("gridViewId_" + gridViewId);

                if (allowDragging !== undefined && allowDragging === true) {
                    $(this).addClass("draggable");
                } else {
                    $(this).removeClass("draggable");
                }
            });
            LRH.DestoryDraggingOnGridView(gridViewId);
            if (allowDragging !== undefined && allowDragging === true) {
                LRH.InitDraggingOnGridView(gridViewId, instance, allowDragging);
            }
            this.initDraggingOnGridViewTimeout = null;
        }, 100);
    };
    // ------------------------------------------------------------------------------

    groupingButtonOnClick = () => {
        let id = this.props.GridViewId;
        let headerPanelId = "#" + id.toString() + " .dx-datagrid-header-panel";
        if (this.state.groupingEnabled === false) {
            this.setState({groupingEnabled: true});
            $(headerPanelId).show();
        } else {
            this.setState({groupingEnabled: false});
            $(headerPanelId).hide();
        }
        this.uiObjectInstance.gridViewInstance.updateDimensions();
    };

    viewOptionsButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ViewOptions_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.uiObjectInstance.gridViewInstance.showColumnChooser();
    };

    addRowButtonOnClick = () => {
        let that = this;
        this.setState({currentRowDataBeingEdited: null}, function () {
            that.uiObjectInstance.gridViewInstance.addRow();
        });
    };

    exportButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Export_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        if (this.totalRecordCount > 20000) {
            LRH.ShowToast("We are unable to export your data. Make sure your data has less than 2000 rows.", "error", 5000);
            return;
        }
        this.uiObjectInstance.gridViewInstance.exportToExcel(false);
    };

    refreshButtonOnClick = (data) => {
        if ($("#GridView_TopBar_Refresh_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.uiObjectInstance.gridViewInstance.refresh();
    };

    applyFilterButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ApplyFilter_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        $("#" + data.gridViewId + " .dx-datagrid-toolbar-button.dx-apply-button").trigger("click");
    };

    clearFilterButtonOnClick = (data) => {
        if ($("#GridView_TopBar_ClearFilter_" + data.gridViewId).hasClass("leopard-ui-disabled")) {
            return;
        }
        this.resetPagingAndRefresh(false, data.gridViewId);
        this.uiObjectInstance.gridViewInstance.clearFilter();
        this.uiObjectInstance.gridViewInstance.clearSorting();
    };

    onEditingStart = (data) => {
        this.setState({currentRowDataBeingEdited: data});
    };

    buttonPrevOnClick = (data) => {
        let $root = $("#GridViewPager_" + data.gridViewId);
        if ($(".leopard-page-button-container.previous", $root)
                .hasClass("disabled") ||
            $root.hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.gridViewInstance;
        let pageIndex = instance.option("customPagingIndex");
        if (!LDH.IsValueEmpty(pageIndex)) {
            pageIndex = parseInt(pageIndex) - 1;
            if (pageIndex < 0) pageIndex = 0;
        } else {
            pageIndex = 0;
        }
        instance.option("customPagingIndex", pageIndex);
        instance.option("customPagingRefresh", true);
        instance.option("customPagingOperation", "prev");
        instance.refresh();
    };

    buttonNextOnClick = (data) => {
        let $root = $("#GridViewPager_" + data.gridViewId);
        if ($(".leopard-page-button-container.next", $root)
                .hasClass("disabled") ||
            $root.hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.gridViewInstance;
        let pageIndex = instance.option("customPagingIndex");
        if (!LDH.IsValueEmpty(pageIndex)) {
            pageIndex = parseInt(pageIndex) + 1;
            if (pageIndex < 0) pageIndex = 0;
        } else {
            pageIndex = 0;
        }
        instance.option("customPagingIndex", pageIndex);
        instance.option("customPagingOperation", "next");
        instance.refresh();
    };

    disableOrEnablePagerBasedOnRowCount = (rowCount, gridViewId) => {
        let $root = $("#GridViewPager_" + gridViewId);
        if ($root.length === 0) return;
        let currentPageInt = 1;
        let instance = this.uiObjectInstance.gridViewInstance;
        if (!LDH.IsObjectNull(instance) &&
            !LDH.IsValueEmpty(instance.option("customPagingIndex"))) {
            currentPageInt = parseInt(instance.option("customPagingIndex")) + 1;
        }

        let firstPageDisabled = false;
        if (currentPageInt <= 1) firstPageDisabled = true;

        if (rowCount === 0 && currentPageInt <= 1) {
            $(".leopard-page-button-container.previous", $root).addClass("disabled");
            $(".leopard-page-button-container.next", $root).addClass("disabled");
        } else if (rowCount === 0 && currentPageInt > 1) {
            $(".leopard-page-button-container.previous", $root).removeClass("disabled");
            $(".leopard-page-button-container.next", $root).addClass("disabled");
        } else {
            let pageSize = parseInt($(".leopard-page-number.selected", $root).attr("pagesize"));
            if (pageSize <= rowCount) {
                $(".leopard-page-button-container.next", $root).removeClass("disabled");
            } else {
                $(".leopard-page-button-container.next", $root).addClass("disabled");
            }
            if (firstPageDisabled) {
                $(".leopard-page-button-container.previous", $root).addClass("disabled");
            } else {
                $(".leopard-page-button-container.previous", $root).removeClass("disabled");
            }
        }
        $(".leopard-pagenumber-current", $root).text(currentPageInt);
    };

    onEditorPreparing = (data) => {
        let e = data.e;

        if (e.parentType === "filterRow" && e.dataType === "datetime") {
            let columnDefinitionList = data.gridDefinition.columnDefinition;
            let continueProceed = false;
            let columnDef = null;

            for (var i = 0; i < columnDefinitionList.length; i++) {
                if (columnDefinitionList[i].columnName === e.dataField &&
                    (LDH.IsObjectNull(columnDefinitionList[i].columnType) ||
                        columnDefinitionList[i].columnType === "datetime")) {
                    continueProceed = true;
                    columnDef = columnDefinitionList[i];
                }
            }
            if (continueProceed === false) return;

            let standardHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (n) {
                if (LDH.IsValueEmpty(n.value) === true ||
                    (!LDH.IsObjectNull(n.value.convertedToUtc) &&
                        n.value.convertedToUtc === true)) {
                    standardHandler(n);
                    return;
                }
                LeopardStaticUIConfig.Global_TempDateConvertion.push({
                    dataViewId: data.gridViewId,
                    convertFrom: LDH.getLocalISODateString(n.value).replace(".000Z", "Z"),
                    convertTo: n.value.toISOString().replace(".000Z", "Z")
                });
                standardHandler(n);
            }
        }

        if (e.parentType === "filterRow" && e.dataType === "string") {
            let columnDefinitionList = data.gridDefinition.columnDefinition;
            let continueProceed = false;
            let columnDef = null;

            for (var i = 0; i < columnDefinitionList.length; i++) {
                if (!LDH.IsObjectNull(columnDefinitionList[i].allowAutoComplete) &&
                    columnDefinitionList[i].allowAutoComplete === true &&
                    columnDefinitionList[i].columnName === e.dataField &&
                    (LDH.IsObjectNull(columnDefinitionList[i].columnType) ||
                        columnDefinitionList[i].columnType === "string")) {
                    continueProceed = true;
                    columnDef = columnDefinitionList[i];
                }
            }
            if (continueProceed === false) return;

            let autoCompleteOperation = "startswith";
            if (!LDH.IsObjectNull(columnDef.autoCompleteOperation) &&
                columnDef.autoCompleteOperation.length > 0) {
                autoCompleteOperation = columnDef.autoCompleteOperation;
            }
            let url = this.props.GetDataFromUrl.replace("?tableName=", "");
            let domainUrl = LDH.APIEndpointAdapter();

            e.editorName = "dxSelectBox";
            e.editorOptions.displayExpr = e.dataField;
            e.editorOptions.valueExpr = e.dataField;
            e.editorOptions.searchEnabled = true;
            e.editorOptions.searchMode = "contains";
            e.editorOptions.searchTimeout = 1000;
            e.editorOptions.minSearchLength = 1;
            e.editorOptions.showDropDownButton = false;
            e.editorOptions.showDataBeforeSearch = false;
            e.editorOptions.noDataText = "Enter value to search...";
            e.editorOptions.dataSource = LRH.InitCustomStoreForAutoComplete(
                domainUrl, url, data.gridViewId, autoCompleteOperation,
                columnDef.columnName);
        }
    }

    gridViewCustomSave = (data) => {
        let pageSize = this.uiObjectInstance.gridViewInstance.pageSize();
        let config = {DevExtremeLayout: data.e, CustomLayout: {pageSize: pageSize}};
        let configToSave = JSON.stringify(config);
        localStorage.setItem("GridViewLayoutState_" + data.gridViewId, configToSave);
    }

    gridViewCustomLoad = (data) => {
        let json = localStorage.getItem("GridViewLayoutState_" + data.gridViewId);
        if (LDH.IsValueEmpty(json) === false && !this.isGridViewLayoutStateLoaded) {
            let devExtremeLayout = JSON.parse(json).DevExtremeLayout;
            this.isGridViewLayoutStateLoaded = true;
            return devExtremeLayout;
        }
        this.isGridViewLayoutStateLoaded = true;
    }

    autoRefreshCountdownOnEnd = () => {
        this.refreshButtonOnClick({gridViewId: this.props.gridViewId});
    }

    commandColumnLinkOnClick = (data) => {
        let that = this;
        if ($("#" + data.id).length > 0 && $("#" + data.id).hasClass("disabled")) {
            return;
        }
        if (data.data.columnType === "photo-gallery") {
            LeopardStaticUIConfig.Global_PopupCustomColumnData = data;
            let instance = that.uiObjectInstance.popupInstance;
            instance.option("visible", true);
        }
    }

    addPopupInstance = (e) => {
        if (LDH.IsObjectNull(e)) return;
        this.uiObjectInstance.popupInstance = e.instance;
    }

    onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.command === "edit") {
            let $links = $(".dx-link", $(e.cellElement));
            let rowIndex = e.row.rowIndex.toString();

            for (var i = 0; i < e.column.buttons.length; i++) {
                let button = e.column.buttons[i];
                if (!LDH.IsObjectNull(button.columnType) &&
                    !LDH.IsObjectNull(button.countColumn) &&
                    !LDH.IsValueEmpty(button.countColumn) &&
                    button.columnType === "photo-gallery") {
                    let value = e.row.data[button.countColumn];
                    let hide = true;
                    if (LDH.IsValueEmpty(value) === false &&
                        (value.toString().toLowerCase() === "true" ||
                            value.toString().toLowerCase() !== "0")) {
                        hide = false;
                    }
                    if ($links.length > 0) {
                        for (var j = 0; j < $links.length; j++) {
                            if ($links[j].text.trim() === button.text) {
                                $links[j].id = button.id + "_" + rowIndex;

                                if (hide === true) {
                                    let className = $links[j].className;
                                    $links[j].style.opacity = "0.3";
                                    $links[j].className = className + " disabled";
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    customizeColumns = (e) => {
        let currentState = this.state;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            currentState = this.props.state.gridViewState.filter(gridView => {
                return gridView.gridViewId === this.props.GridViewId;
            });
            if (currentState !== undefined && currentState !== null && currentState.length > 0) {
                currentState = currentState[0];
            }
        } else {
            currentState = this;
        }

        if (LDH.IsObjectNull(currentState) || LDH.IsObjectNull(currentState.gridDefinition) ||
            LDH.IsObjectNull(currentState.gridDefinition.columnDefinition)) {
            return;
        }

        for (var i = 0; i < e.length; i++) {
            let columnDef = null;
            let dataField = e[i].dataField;
            columnDef = currentState.gridDefinition.columnDefinition.filter(c => {
                return c.columnName === dataField;
            });
            if (columnDef !== undefined && columnDef !== null && columnDef.length > 0) {
                columnDef = columnDef[0];
            }

            let columnType = "string";
            if (columnDef !== null && columnDef.columnType !== undefined &&
                columnDef.columnType !== null && columnDef.columnType !== "") {
                columnType = columnDef.columnType;
            }

            // Visible settings.
            if (this.hasCustomizeColumnsInitialized === false) {
                let isVisible = true;
                if (columnDef !== null && columnDef.isVisible !== undefined &&
                    columnDef.isVisible !== null && columnDef.isVisible !== "") {
                    isVisible = columnDef.isVisible;
                }
                e[i].visible = isVisible;
            }

            // Visible Index settings.
            if (this.hasCustomizeColumnsInitialized === false) {
                let visibleIndex = e[i].index;
                if (columnDef !== null && columnDef.visibleIndex !== undefined &&
                    columnDef.visibleIndex !== null && columnDef.visibleIndex !== "") {
                    visibleIndex = columnDef.visibleIndex;
                }
                e[i].visibleIndex = visibleIndex;
            }

            // Default Operation settings.
            if (LDH.IsValueEmpty(e[i].selectedFilterOperation)) {
                let defaultOperation = "";
                if (LDH.IsValueEmpty(columnType) || columnType === "string") {
                    defaultOperation = "=";
                } else if (columnType === "number") {
                    defaultOperation = "=";
                } else if (columnType === "date") {
                    defaultOperation = "between";
                } else if (columnType === "datetime") {
                    defaultOperation = "between";
                }
                if (columnDef !== null && columnDef.defaultOperation !== undefined &&
                    columnDef.defaultOperation !== null && columnDef.defaultOperation !== "") {
                    defaultOperation = columnDef.defaultOperation;
                }
                e[i].selectedFilterOperation = defaultOperation;
            }
        }
        this.hasCustomizeColumnsInitialized = true;
    }

    onSelectionChanged = (e) => {
        if (this.props.useStateStore === null || this.props.useStateStore === false ||
            LDH.IsObjectNull(this.relationshipsLinkedToDataView) ||
            this.relationshipsLinkedToDataView.length === 0) {
            return;
        }
        for (var i = 0; i < this.relationshipsLinkedToDataView.length; i++) {
            let pId = this.relationshipsLinkedToDataView[i].parentDataViewId;
            let cId = this.relationshipsLinkedToDataView[i].childDataViewId;
            let features = this.relationshipsLinkedToDataView[i].interactiveFeatures;
            let dataSourceId = this.relationshipsLinkedToDataView[i].dataSourceId;
            let rowData = LDH.DeepClone(e.selectedRowsData[0]);
            let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
            let childDashboardItemId = cId.split(":")[0];
            if (childDashboardItemId === this.props.GridViewId) continue;

            for (var v = 0; v < listeners.length; v++) {
                if (listeners[v].dashboardItemId === childDashboardItemId) {
                    if (listeners[v].instance.NAME === "dxDataGrid" ||
                        listeners[v].instance.NAME === "dxChart") {
                        listeners[v].instance.clearSelection();
                    }
                    listeners[v].callback({
                        dataFromSource: rowData,
                        parentDataViewId: pId,
                        childDataViewId: cId,
                        features: features,
                        dataSourceId: dataSourceId,
                        dataViewType: "datagrid"
                    });
                }
            }
        }
    }

    onRowClick = (e) => {
        LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = false;
        if (!LDH.IsObjectNull(LeopardStaticUIConfig.Global_DashboardAutoSelectInstance)) {
            LeopardStaticUIConfig.Global_DashboardAutoSelectInstance.option("value", false);
        }
    }

    render() {
        let gridViewState = this.props.state.gridViewState;
        let currentState = this.state;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            currentState = gridViewState.filter(gridView => {
                return gridView.gridViewId === this.props.GridViewId;
            });
            if (currentState !== undefined && currentState !== null && currentState.length > 0) {
                currentState = currentState[0];
            }
        } else {
            if (this.state.customStore === null) {
                return (<div>Retrieving data, please wait...</div>);
            }
        }

        if (currentState === undefined || currentState === null || currentState.length === 0) {
            return (<div>Retrieving data, please wait...</div>);
        }

        let showAddButton = false;
        let showDeleteButton = false;
        let showEditButton = false;

        if (this.props.commandButtons !== undefined && this.props.commandButtons !== null &&
            this.props.commandButtons.length > 0) {
            for (var k = 0; k < this.props.commandButtons.length; k++) {
                if (this.props.commandButtons[k] === "modify-row") {
                    showEditButton = true;
                }
                if (this.props.commandButtons[k] === "add-row") {
                    showAddButton = true;
                }
                if (this.props.commandButtons[k] === "delete-row") {
                    showDeleteButton = true;
                }
            }
        }

        let allowEditing = false;
        if (showEditButton || showAddButton || showDeleteButton) {
            allowEditing = true;
        }

        let gridDefinition = null;
        if (this.props.useStateStore === true) {
            gridDefinition = this.gridDefinition;
        } else {
            gridDefinition = currentState.gridDefinition;
        }

        this.enableAutoRefresh = this.props.enableAutoRefresh;
        if (LDH.IsObjectNull(this.enableAutoRefresh)) {
            this.enableAutoRefresh = gridDefinition.enableAutoRefresh;
        }
        if (LDH.IsValueEmpty(this.enableAutoRefresh)) {
            this.enableAutoRefresh = false;
        }

        this.autoRefreshInterval = this.props.autoRefreshInterval;
        if (LDH.IsObjectNull(this.autoRefreshInterval)) {
            this.autoRefreshInterval = gridDefinition.autoRefreshInterval;
        }
        if (LDH.IsValueEmpty(this.autoRefreshInterval)) {
            this.autoRefreshInterval = 30;
        }

        this.customColumnOverallWidth = this.props.customColumnOverallWidth;
        if (LDH.IsObjectNull(this.customColumnOverallWidth)) {
            this.customColumnOverallWidth = gridDefinition.customColumnOverallWidth;
        }
        if (LDH.IsValueEmpty(this.customColumnOverallWidth)) {
            this.customColumnOverallWidth = 200;
        }

        this.showAutoRefreshSwitch = this.props.showAutoRefreshSwitch;
        if (LDH.IsObjectNull(this.showAutoRefreshSwitch)) {
            this.showAutoRefreshSwitch = gridDefinition.showAutoRefreshSwitch;
        }
        if (LDH.IsValueEmpty(this.showAutoRefreshSwitch)) {
            this.showAutoRefreshSwitch = true;
        }

        return (
            <React.Fragment>
                <LeopardGridViewToolbar groupingEnabled={this.state.groupingEnabled}
                                        groupingButtonOnClick={(e) => this.groupingButtonOnClick(e)}
                                        gridViewId={this.props.GridViewId} minHeight={this.props.minHeightToolbar}
                                        addRowButtonOnClick={(e) => this.addRowButtonOnClick(e)}
                                        explicitFilterOption={this.props.explicitFilterOption}
                                        showAutoRefreshSwitch={this.showAutoRefreshSwitch}
                                        autoRefreshCountdownOnEnd={(e) => this.autoRefreshCountdownOnEnd(e)}
                                        autoRefreshOnValueChanged={(e) => this.autoRefreshOnValueChanged(e)}
                                        gridDefinition={gridDefinition} autoRefreshInterval={this.autoRefreshInterval}
                                        applyFilterButtonOnClick={(e) => this.applyFilterButtonOnClick(e)}
                                        clearFilterButtonOnClick={(e) => this.clearFilterButtonOnClick(e)}
                                        refreshButtonOnClick={() => this.refreshButtonOnClick({gridViewId: this.props.GridViewId})}
                                        viewOptionsButtonOnClick={() => this.viewOptionsButtonOnClick({gridViewId: this.props.GridViewId})}
                                        exportButtonOnClick={() => this.exportButtonOnClick({gridViewId: this.props.GridViewId})}
                                        showAddButton={showAddButton} enableAutoRefresh={this.enableAutoRefresh}/>

                <BuildLeopardGridView setGridViewInstance={this.setGridViewInstance} thisComp={this}
                                      currentState={currentState} gridDefinition={gridDefinition}
                                      onRowExpanding={(e) => this.onRowExpanding(e)}
                                      onInitialized={(e) => this.onInitialized(e)}
                                      onDataErrorOccurred={(e) => this.onDataErrorOccurred(e)}
                                      onToolbarPreparing={(e) => this.onToolbarPreparing(e)}
                                      gridViewId={this.props.GridViewId}
                                      viewOptionsText={this.props.viewOptionsText}
                                      groupingEnabled={false} buttonNextOnClick={(e) => this.buttonNextOnClick(e)}
                                      buttonPrevOnClick={(e) => this.buttonPrevOnClick(e)}
                                      allowEditing={allowEditing} onCellPrepared={(e) => this.onCellPrepared(e)}
                                      gridViewCustomSave={(e) => this.gridViewCustomSave(e)}
                                      gridViewCustomLoad={(e) => this.gridViewCustomLoad(e)}
                                      onEditorPreparing={(e) => this.onEditorPreparing(e)}
                                      explicitFilterOption={this.props.explicitFilterOption}
                                      columnResizingMode={this.props.columnResizingMode}
                                      filterBuilderValue={this.props.filterBuilderValue}
                                      onEditingStart={(e) => this.onEditingStart(e)}
                                      onRowClick={(e) => this.onRowClick(e)}
                                      onSelectionChanged={(e) => this.onSelectionChanged(e)}
                                      customizeColumns={(e) => this.customizeColumns(e)}
                                      limitedColumns={this.props.columnFieldList.limitedColumns}
                                      showAddButton={showAddButton} showDeleteButton={showDeleteButton}
                                      showEditButton={showEditButton} onContentReady={(e) => this.onContentReady(e)}
                                      currentRowDataBeingEdited={this.state.currentRowDataBeingEdited}
                                      disabledFieldsForEditing={this.props.disabledFieldsForEditing}
                                      hiddenFieldsForEditing={this.props.hiddenFieldsForEditing}/>
            </React.Fragment>
        );
    }
}

const BuildLeopardGridView =
    ({
         setGridViewInstance, currentState, onEditingStart, onContentReady, explicitFilterOption,
         onToolbarPreparing, onRowExpanding, gridViewId, groupingEnabled, buttonNextOnClick,
         buttonPrevOnClick, viewOptionsText, allowEditing, limitedColumns, onInitialized,
         columnResizingMode, filterBuilderValue, onDataErrorOccurred, showAddButton, showDeleteButton,
         showEditButton, disabledFieldsForEditing, hiddenFieldsForEditing, currentRowDataBeingEdited,
         thisComp, onEditorPreparing, gridDefinition, gridViewCustomSave, gridViewCustomLoad,
         customizeColumns, onCellPrepared, onSelectionChanged, onRowClick
     }) => {
        if (LDH.IsValueEmpty(explicitFilterOption) === true) {
            explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
        }

        thisComp.optimizePagerForLargeDataset = gridDefinition.optimizePagerForLargeDataset;
        if (LDH.IsValueEmpty(thisComp.optimizePagerForLargeDataset)) {
            thisComp.optimizePagerForLargeDataset = true;
        }

        thisComp.defaultPageSize = gridDefinition.defaultPageSize;
        if (LDH.IsValueEmpty(thisComp.defaultPageSize)) {
            thisComp.defaultPageSize = 10;
        }

        thisComp.customColumnConfiguration = gridDefinition.customColumnConfiguration;

        LDH.ParseDevExtremeFilterString(filterBuilderValue);
        let columnComponent = LeopardGridViewColumnBuilder(gridDefinition, limitedColumns, thisComp, currentState);

        if (!LDH.IsObjectNull(thisComp.customColumnConfiguration) &&
            !LDH.IsObjectNull(thisComp.customColumnConfiguration.customColumns) &&
            thisComp.customColumnConfiguration.customColumns.length > 0) {
            let buttons = ["edit", "delete"];
            for (let i = 0; i < thisComp.customColumnConfiguration.customColumns.length; i++) {
                let customColumn = thisComp.customColumnConfiguration.customColumns[i];
                let id = LDH.GenerateGuid();
                buttons.push({
                    text: customColumn.linkText, countColumn: customColumn.countColumnName,
                    onClick: function (e) {
                        thisComp.commandColumnLinkOnClick({
                            e: e, data: customColumn,
                            id: id + "_" + e.row.rowIndex.toString()
                        });
                    }, columnType: customColumn.columnType, id: id
                });
            }
            let width = thisComp.customColumnConfiguration.customColumnOverallWidth;
            if (LDH.IsObjectNull(columnComponent) === false) {
                columnComponent.push(<Column key={"CustomColumn"} width={width} type={"buttons"} buttons={buttons}/>);
            }
        }

        LeopardStaticUIConfig.Global_GridViewCurrentEditRowData = currentRowDataBeingEdited;
        let editFormComponent = LeopardGridViewEditFormBuilder(gridDefinition, limitedColumns,
            disabledFieldsForEditing, hiddenFieldsForEditing);

        return (
            <React.Fragment>
                <DataGrid cacheEnabled={LeopardStaticUIConfig.GridView_CacheEnabled}
                          dataSource={currentState.customStore} showBorders={LeopardStaticUIConfig.showBorders}
                          onEditingStart={(e) => onEditingStart(e)} onContentReady={(e) => onContentReady(e)}
                          allowColumnResizing={LeopardStaticUIConfig.GridView_AllowColumnResizing}
                          columnMinWidth={LeopardStaticUIConfig.GridView_ColumnMinWidth}
                          columnAutoWidth={LeopardStaticUIConfig.GridView_ColumnAutoWidth}
                          onCellPrepared={(e) => onCellPrepared(e)}
                          onSelectionChanged={(e) => onSelectionChanged(e)}
                          onRowClick={(e) => onRowClick(e)}
                          highlightChanges={LeopardStaticUIConfig.GridView_HighlightChanges}
                          onEditorPreparing={(e) => onEditorPreparing({
                              e: e, gridViewId: gridViewId, gridDefinition: gridDefinition
                          })}
                          customizeColumns={(e) => customizeColumns(e)}
                          repaintChangesOnly={LeopardStaticUIConfig.GridView_RepaintChangesOnly}
                          columnResizingMode={columnResizingMode} filterValue={filterBuilderValue}
                          allowColumnReordering={LeopardStaticUIConfig.GridView_AllowColumnReordering}
                          hoverStateEnabled={LeopardStaticUIConfig.GridView_HoverStateEnabled} id={gridViewId}
                          onRowExpanding={(e) => onRowExpanding(e)} ref={setGridViewInstance}
                          className="leopard-gridview-control" onDataErrorOccurred={(e) => onDataErrorOccurred(e)}
                          onInitialized={(e) => onInitialized(e)} onToolbarPreparing={(e) => onToolbarPreparing(e)}
                          rowAlternationEnabled={LeopardStaticUIConfig.GridView_RowAlternationEnabled}>
                    {
                        LDH.IsObjectNull(gridDefinition) ? "" : columnComponent
                    }
                    <ColumnChooser enabled={LeopardStaticUIConfig.ColumnChooser_Enabled} title={viewOptionsText}/>
                    {
                        allowEditing === false ? "" :
                            <Editing mode={'popup'} allowUpdating={allowEditing && showEditButton}
                                     allowDeleting={allowEditing && showDeleteButton}
                                     allowAdding={allowEditing && showAddButton} refreshMode={"repaint"}>
                                <Popup title={'Add/Edit Record'} showTitle={true} width={700} height={345}>
                                    <Position my={'middle'} at={'middle'} of={window}/>
                                </Popup>
                                <Form>{editFormComponent}</Form>
                            </Editing>
                    }
                    <Export enabled={true} fileName={viewOptionsText} allowExportSelectedData={true}/>
                    <Selection mode={"single"}></Selection>
                    <LoadPanel enabled={LeopardStaticUIConfig.LoadPanel_ShowIndicator}
                               showPane={LeopardStaticUIConfig.LoadPanel_ShowPane}/>
                    <FilterRow visible={LeopardStaticUIConfig.GridView_FilterRow} applyFilter={explicitFilterOption}/>
                    <Sorting mode={LeopardStaticUIConfig.Sorting_Mode}/>
                    <HeaderFilter visible={LeopardStaticUIConfig.HeaderFilter_Visible}/>
                    <ColumnFixing enabled={LeopardStaticUIConfig.ColumnFixing_Enabled}/>
                    <Paging defaultPageSize={thisComp.defaultPageSize}
                            enabled={thisComp.optimizePagerForLargeDataset === false}/>
                    <Pager visible={thisComp.optimizePagerForLargeDataset === false}
                           infoText={"{0} of {1} ({2})"}
                           showPageSizeSelector={thisComp.optimizePagerForLargeDataset === false}
                           allowedPageSizes={LeopardStaticUIConfig.Pager_allowedPageSizes}
                           showInfo={thisComp.optimizePagerForLargeDataset === false}
                           showNavigationButtons={thisComp.optimizePagerForLargeDataset === false}/>
                    {
                        thisComp.optimizePagerForLargeDataset ?
                            <RemoteOperations paging={false} filtering={true} grouping={true}
                                              groupPaging={true} sorting={true}></RemoteOperations> :
                            <RemoteOperations paging={true} filtering={true} grouping={true}
                                              groupPaging={true} sorting={true}></RemoteOperations>
                    }
                </DataGrid>
                {thisComp.optimizePagerForLargeDataset === false ? "" :
                    <div className={"leopard-page-container"} id={"GridViewPager_" + gridViewId}>
                        <div className={"leopard-page-number " + (thisComp.defaultPageSize === 10 ? "selected" : "")}
                             pagesize="10">10
                        </div>
                        <div className={"leopard-page-number " + (thisComp.defaultPageSize === 25 ? "selected" : "")}
                             pagesize="25">25
                        </div>
                        <div className={"leopard-page-number " + (thisComp.defaultPageSize === 50 ? "selected" : "")}
                             pagesize="50">50
                        </div>

                        <div className={"leopard-page-button-container next"}>
                            <i className={"fas fa-chevron-right"} style={{fontSize: "25px", float: "right"}}
                               onClick={(e) => buttonNextOnClick({e: e, gridViewId: gridViewId})}></i>
                            <a className="leopard-page-button" style={{marginRight: "5px"}} href={"#_"}
                               onClick={(e) => buttonNextOnClick({e: e, gridViewId: gridViewId})}>Next</a>
                        </div>
                        <div className={"leopard-page-button-container previous"}>
                            <i className={"fas fa-chevron-left"} style={{fontSize: "25px"}}
                               onClick={(e) => buttonPrevOnClick({e: e, gridViewId: gridViewId})}></i>
                            <a className="leopard-page-button" style={{marginRight: "10px"}} href={"#_"}
                               onClick={(e) => buttonPrevOnClick({e: e, gridViewId: gridViewId})}>Previous</a>
                        </div>
                        <div className={"leopard-page-infotext"}>
                            Page <span className={"leopard-pagenumber-current"}></span>
                        </div>
                    </div>
                }
                <LeopardPhotoGalleryPopup
                    popupInstance={thisComp.addPopupInstance} popupWidth={"1024px"} popupHeight={"650px"}
                    popupTitle={"Photos"} popupOnHide={thisComp.popupOnHide}
                    popupOnShowing={thisComp.popupOnShowing} popupOnShown={thisComp.popupOnShown}
                />
            </React.Fragment>
        );
    };

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

const SendDataToReducer = (dispatch) => {
    return {
        InitCustomStore: (store, id) => {
            dispatch(InitCustomStore(store, id));
        },
        UpdateGridViewDefinition: (data, id) => {
            dispatch(UpdateGridViewDefinition(data, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardGridView);