import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import LeopardSecurity from './security/LeopardSecurity';
import LRH from './helpers/LeopardReactHelper';
import {
    SetUserPermissions, SetUserCredential,
    SetAuthenticatedUser, SetUserProfile
} from './foundation/LeopardActionCreators';

import LeopardTopMenuBar from './components/LeopardTopMenuBar';
import LeopardMasterLeftMenu from './components/LeopardMasterLeftMenu';
import LeopardStaticUIConfig from './foundation/LeopardStaticUIConfig';
import LeopardMasterContentPanel from './components/LeopardMasterContentPanel';
import LeopardAjaxHelper from './helpers/LeopardAjaxHelper';
import LDH from './helpers/LeopardDataHelper';
import config from 'devextreme-react/core/config';

config({useLegacyTemplateEngine: true});

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInUserName: "",
            loggedInUserRoles: "",
            authState: null,
            masterPageOnLoadComplete: false
        };

        this.windowResizeRegister = null;
        this.gridViewInstance = null;
        this.chartInstance = null;
        this.mapInstance = null;
        this.photoInstance = null;
        this.showLeftMenuContent = true;
    }

    updateWindowDimensionsOnResizing = (optimizePagerForLargeDataset) => {
        let that = this;

        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        let height = window.innerHeight - 153;
        let width = window.innerWidth;
        width = width - (that.showLeftMenuContent ? 305 : 0);

        if (this.gridViewInstance !== null) {
            let heightForGridView = window.innerHeight;
            heightForGridView = heightForGridView - 153;

            let adjustment = 0;
            if ((LDH.IsValueEmpty(optimizePagerForLargeDataset) === false &&
                optimizePagerForLargeDataset === true) ||
                $(".leopard-page-container").length > 0) {
                adjustment = -33;
            }

            if (showGridViewAdminToolbar === true) {
                this.gridViewInstance.option("height", heightForGridView + adjustment - 3);
            } else {
                this.gridViewInstance.option("height", heightForGridView + adjustment + 27);
            }
        }

        if (this.chartInstance !== null) {
            let heightForChart = window.innerHeight - 128;
            if (showGridViewAdminToolbar === true) {
                this.chartInstance.option("size", {height: heightForChart - 30});
            } else {
                this.chartInstance.option("size", {height: heightForChart});
            }
        }

        if (this.mapInstance !== null) {
            let heightForMap = window.innerHeight - 95;
            this.mapInstance.option("height", heightForMap);
        }

        let winHeight = height + 68;
        $(".leopard-right-panel-container").css("width", width);
        $(".leopard-left-panel-container").css("height", winHeight);
        $(".leopard-leftmenu-option-panel").css("height", winHeight);
        $(".leopard-option-panel-content").css("height", winHeight - 115);
        $(".leopard-screen-cover").css("height", winHeight);
        $(".leopard-right-panel-container").css("height", winHeight);

        $(".leopard-table-with-fixedcolumn table").css("width", width - 50);
        $(".leopard-table-with-fixedcolumn thead").css("width", width - 50);
        $(".leopard-table-with-fixedcolumn tbody").css("width", width - 50);

        if ($("#LeopardReportDesigner").length > 0) {
            if ($("#LeopardReportDesigner").css("z-index") !== "1000000") {
                $("#LeopardReportDesigner").css("height", winHeight - 10);
            }
        }
    };

    setGridViewInstance = (e) => {
        if (e.isDataView === true) {
            this.gridViewInstance = e.instance;
            this.updateWindowDimensionsOnResizing(e.optimizePagerForLargeDataset);
        }
    };

    setChartInstance = (e) => {
        if (e.isDataView === true) {
            this.chartInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    setPhotoInstance = (e) => {
        if (e.isDataView === true) {
            this.photoInstance = e.instance;
            this.updateWindowDimensionsOnResizing(false);
        }
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensionsOnResizing);
        this.windowResizeRegister = null;
        this.gridViewInstance = null;
        this.showLeftMenuContent = true;
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        let that = this;
        if (this.props.updateUserProfile === true) {
            this.props.updateUserProfileComplete(function () {
                that.initializeAuthenticatedUserAndProfile();
            });
        }
    };

    componentDidMount() {
        let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
        let devextremeStyle =
            '<link rel="stylesheet" type="text/css" href="/css/devextreme/dx.light.compact.css?v=' + version + '" />' +
            '<link rel="stylesheet" type="text/css" href="/css/devextreme/dx.generic.leopard-theme.css?v=' + version + '" />';
        $("head").append(devextremeStyle);

        let customStyle =
            '<link id="leopard-default-style" rel="stylesheet" type="text/css" href="/css/custom.css?v=' + version + '" />';

        $("#leopard-default-style").remove();
        $("head").append(customStyle);

        let defaultStyle =
            '<link rel="stylesheet" type="text/css" href="/css/default.css?v=' + version + '" />';
        $("head").append(defaultStyle);

        $("head").append('<script src="/js/stimulsoft/stimulsoft.reports.js?v=' + version + '"></script>');
        $("head").append('<script src="/js/stimulsoft/stimulsoft.viewer.js?v=' + version + '"></script>');
        $("head").append('<script src="/js/stimulsoft/stimulsoft.designer.js?v=' + version + '"></script>');
        $("head").append('<script src="/js/webconfig.js?v=' + version + '"></script>');
        window.addEventListener("resize", this.updateWindowDimensionsOnResizing);
        window.Stimulsoft.Base.StiLicense.key = LeopardStaticUIConfig.ReportLicenseKey;

        $("body").on("contextmenu", function () {
            return false;
        });

        $(document).off("click").on("click", function () {
            if ($(".leopard-login-userinfo-panel").is(":visible")) {
                $(".leopard-login-userinfo-panel").hide();
            }
        });

        let storagekeys = LDH.GetAllLocalStorageItemKeys();
        for (var i = 0; i < storagekeys.length; i++) {
            if (storagekeys[i].indexOf("GridViewLayoutState_") > -1) {
                localStorage.removeItem(storagekeys[i]);
            }
        }

        let that = this;
        this.setState({masterPageOnLoadComplete: true}, function () {
            that.updateWindowDimensionsOnResizing();
        });
        LeopardAjaxHelper.AddSecurityHeadersToAjaxRequestForOdata();
    }

    initializeAuthenticatedUserAndProfile = () => {
        let that = this;

        if (that.props.authState === "signedIn") {
            if (LRH.IsControlCentreInTestMode()) {
                that.setState({
                    loggedInUserName: "UI Test",
                    authState: that.props.authState,
                    loggedInUserRoles: "CC_Admin",
                });

                LeopardAjaxHelper.GetUserProfile("admin_userid", function (profile) {
                    that.props.SetUserProfile({data: profile});
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve the user profile.", "error", 5000);
                    }
                });

                that.props.SetUserPermissions({
                    ShowGridViewAdminToolbar: true
                });
            } else {
                LeopardSecurity.GetCurrentAuthenticatedUser(function (user) {
                    let roles = user.attributes["custom:role"];
                    that.props.SetAuthenticatedUser({data: user});
                    that.setState({
                        loggedInUserName: user.username,
                        authState: that.props.authState,
                        loggedInUserRoles: roles
                    });

                    if (roles === "CC_Admin") {
                        that.props.SetUserPermissions({
                            ShowGridViewAdminToolbar: true,
                            AllowReportDesigner: true
                        });
                    } else {
                        that.props.SetUserPermissions({
                            ShowGridViewAdminToolbar: false,
                            AllowReportDesigner: false
                        });
                    }

                    let userId = user.attributes.sub;
                    LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                        that.props.SetUserProfile({data: profile});
                        window.userProfile = profile;
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve the user profile.", "error", 5000);
                        }
                    });
                }, function () {
                    that.setState({authState: that.props.authState});
                });

                LeopardSecurity.GetCurrentUserCredentials(function (credential) {
                    LRH.ConsoleLog(this, "user_credential", credential);
                    window.userCredential = credential;
                });

                setInterval(function () {
                    LeopardSecurity.GetCurrentUserCredentials(function (credential) {
                        LRH.ConsoleLog(this, "user_credential", credential);
                        window.userCredential = credential;
                    });
                }, 60000);
            }
        } else {
            that.setState({authState: that.props.authState});
        }
    };

    navHideButtonOnClick = () => {
        let that = this;

        if (that.showLeftMenuContent === false) {
            that.showLeftMenuContent = true;
            $(".leopard-left-panel-container").show(0, function () {
                that.updateWindowDimensionsOnResizing();
                LRH.TriggerWindowResizeEvent();

                setTimeout(function () {
                    that.resizeDashboardItems();
                }, 10);
            });
        } else {
            that.showLeftMenuContent = false;
            $(".leopard-left-panel-container").hide(0, function () {
                that.updateWindowDimensionsOnResizing();
                LRH.TriggerWindowResizeEvent();

                setTimeout(function () {
                    that.resizeDashboardItems();
                }, 10);
            });
        }
    };

    resizeDashboardItems = () => {
        let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
        setTimeout(function(){
            for (var i = 0; i < listeners.length; i++) {
                if (LDH.IsObjectNull(listeners[i].instance)) {
                    continue;
                }
                if (listeners[i].instance.NAME === "dxTileView"){
                    listeners[i].instance.repaint();
                }
                else{
                    let size = listeners[i].instance.option("size");
                    listeners[i].instance.option("size", size);
                }
            }
        }, 10);
    };

    render() {
        if (this.state.masterPageOnLoadComplete === true) {
            return (
                <React.Fragment>
                    <div className="App">
                        <LeopardTopMenuBar loggedInUserName={this.state.loggedInUserName}
                                           navHideButtonOnClick={this.navHideButtonOnClick}/>

                        <div className="leopard-master-leftmenu-container">
                            <LeopardMasterLeftMenu
                                updateWindowDimensionsRequired={(e) => this.updateWindowDimensionsOnResizing(e)}/>

                            <div className={"leopard-right-panel-container"}>
                                <LeopardMasterContentPanel setGridViewInstance={(e) => this.setGridViewInstance(e)}
                                                           setChartInstance={(e) => this.setChartInstance(e)}
                                                           setPhotoInstance={(e) => this.setPhotoInstance(e)}
                                                           windowHeight={0}
                                                           setMapInstance={(e) => this.setMapInstance(e)}
                                                           updateWindowDimensionsRequired={(e) => this.updateWindowDimensionsOnResizing(e)}/>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

const SendDataToReducer = (dispatch) => {
    return {
        SetUserPermissions: (data) => {
            dispatch(SetUserPermissions(data));
        },
        SetUserCredential: (data) => {
            dispatch(SetUserCredential(data));
        },
        SetAuthenticatedUser: (data) => {
            dispatch(SetAuthenticatedUser(data));
        },
        SetUserProfile: (data) => {
            dispatch(SetUserProfile(data));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(App);
