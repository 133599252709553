import React, {Component} from 'react';
import {connect} from 'react-redux';

class LeopardMenuDataViewList extends Component {
    render() {
        let that = this;
        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        let result = this.props.menuItems.map(function (item, i) {
            let selectedClass = that.props.selectedId === item.menuItemId ? "selected" : "";

            return (
                <React.Fragment key={"LeopardMenuDataViewList_" + i}>
                    <div id={"menuitem_dataview_" + item.dataViewType + "_" + item.menuItemId}
                         className={"leopard-leftmenu-item " + selectedClass}>
                        <div className={"leopard-leftmenu-item-text"} title={item.menuItemName}
                             onClick={() => that.props.menuItemOnClick({
                                 item: item, selected: selectedClass
                             })} custom_attr_url={item.dataSourceUrl}>
                            {item.menuItemName}
                        </div>
                        {
                            showGridViewAdminToolbar === false ? "" :
                                <div className={"leopard-leftmenu-item-options fas fa-ellipsis-h"}
                                     onClick={
                                         () => that.props.menuItemSettingsButtonOnClick({
                                             item: item, option: "edit_dataview",
                                             dataViewCategory: that.props.dataViewCategory
                                         })
                                     }
                                />
                        }
                    </div>
                </React.Fragment>
            );
        });
        return (<React.Fragment>{result}</React.Fragment>);
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

export default connect(RetrieveDataFromReducer, null)(LeopardMenuDataViewList);