import React, {Component} from 'react';
import LRH from '../helpers/LeopardReactHelper';
import {connect} from "react-redux";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LDH from "../helpers/LeopardDataHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";

class LeopardReportConfiguration extends Component {
    constructor(props) {
        super(props);

        this.reportViewerInstance = null;
        this.reportDesignerInstance = null;
        this.reportLayout = null;
    }

    createReportDesigner() {
        let that = this;
        //window.StiOptions.Services._databases = [];
        //window.StiOptions.Services._databases.add(new window.Stimulsoft.Report.Dictionary.StiODataDatabase());

        var options = new window.Stimulsoft.Designer.StiDesignerOptions();
        options.appearance.showTooltipsHelp = LeopardStaticUIConfig.ReportViewerAppearanceShowTooltipsHelp;
        options.appearance.fullScreenMode = true;
        options.toolbar.showAboutButton = LeopardStaticUIConfig.ReportViewerToolbarShowAboutButton;
        options.toolbar.showFileMenuAbout = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuAbout;
        options.toolbar.showFileMenuHelp = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuHelp;
        options.toolbar.showFileMenuInfo = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuInfo;

        this.reportDesignerInstance = new window.Stimulsoft.Designer.StiDesigner(
            options, "LeopardReportDesigner", false);

        this.reportDesignerInstance.onExit = function (e) {
            this.visible = false;
            that.reportViewerInstance.visible = true;

            let userProfile = that.props.state.userProfile;
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            that.createReportViewer();
            var report = new window.Stimulsoft.Report.StiReport();
            report.load(that.reportLayout);
            report.setVariable("Input_UserGroupId", organizationId);

            that.reportViewerInstance.report = report;
        }

        this.reportDesignerInstance.onSaveReport = function (args) {
            let jsonReport = args.report.saveToJsonString();
            let dataViewId = that.props.dataViewId;
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let reportDef = that.props.reportDefinition;

            reportDef.reportLayout = JSON.parse(jsonReport);
            that.reportLayout = jsonReport;

            LeopardAjaxHelper.UpdateDataViewDefinition(userId, organizationId, dataViewId, reportDef, function () {
                LRH.ShowToast("Your report has been successfully updated.", "success", 5000);
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to update your report. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        }

        this.reportDesignerInstance.renderHtml("reportDesignerContent");
        LRH.TriggerWindowResizeEvent();
    }

    createReportViewer() {
        let that = this;
        let userProfile = this.props.state.userProfile;
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

        var options = new window.Stimulsoft.Viewer.StiViewerOptions();
        options.appearance.interfaceType = window.Stimulsoft.Viewer.StiInterfaceType.Mouse;
        options.appearance.showTooltipsHelp = LeopardStaticUIConfig.ReportDesignerAppearanceShowTooltipsHelp;

        if (this.props.state.permissions.AllowReportDesigner) {
            options.toolbar.showDesignButton = LeopardStaticUIConfig.ReportDesignerToolbarShowDesignButton;
        }
        options.toolbar.showAboutButton = LeopardStaticUIConfig.ReportDesignerToolbarShowAboutButton;
        options.toolbar.showOpenButton = LeopardStaticUIConfig.ReportDesignerToolbarShowOpenButton;

        this.reportViewerInstance = new window.Stimulsoft.Viewer.StiViewer(
            options, "LeopardReportViewer", false);

        this.reportViewerInstance.onDesignReport = function (e) {
            that.createReportDesigner();
            var report = new window.Stimulsoft.Report.StiReport();
            report.load(that.reportLayout);
            report.setVariable("Input_UserGroupId", organizationId);

            that.reportDesignerInstance.report = report;
            that.reportDesignerInstance.visible = true;
            this.visible = false;
        };
        this.reportViewerInstance.renderHtml("reportViewerContent");
    }

    componentDidMount() {
        this.createReportViewer();
        var report = new window.Stimulsoft.Report.StiReport();
        if (!LDH.IsObjectNull(this.props.reportDefinition) &&
            !LDH.IsObjectNull(this.props.reportDefinition.reportLayout) &&
            !LDH.IsObjectNull(this.props.reportDefinition.reportLayout.ReportName)) {
            this.reportLayout = this.props.reportDefinition.reportLayout;
            report.load(this.reportLayout);

            let userProfile = this.props.state.userProfile;
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            report.setVariable("Input_UserGroupId", organizationId);
        }
        this.reportViewerInstance.report = report;
    }

    render() {
        return (
            <React.Fragment>
                <div id={"reportViewerContent"}></div>
                <div id={"reportDesignerContent"}></div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

export default connect(RetrieveDataFromReducer)(LeopardReportConfiguration);