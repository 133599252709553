import React from 'react';
import $ from 'jquery';
import convert from 'xml-js';
import moment from 'moment';
import 'devextreme/data/odata/store';
import ODataStore from 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import {confirm} from 'devextreme/ui/dialog';
import {API} from "aws-amplify";
import LDH from './LeopardDataHelper';
import LRH from './LeopardReactHelper';
import notify from 'devextreme/ui/notify';
import LeopardTestHelper from './LeopardTestHelper';
import LeopardSecurity from '../security/LeopardSecurity';
import LeopardAjaxHelper from "./LeopardAjaxHelper";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardReactHelper extends React.Component {
    static ConsoleLog = (comp, name, args) => {
        if (localStorage.getItem("ConsoleLog") === "lite") {
            console.log(name, args);
        } else if (localStorage.getItem("ConsoleLog") === "detail") {
            console.log(name, args, "##### Component #####", comp);
        }
    };

    static ShowToast = (text, type, displayTime) => {
        notify(text, type, displayTime);
    };

    static ShowDialog = (text, title, callbackOK, callbackCancel) => {
        confirm(text, title).then((dialogResult) => {
            if (dialogResult === true) {
                callbackOK();
            }
            if (callbackCancel !== undefined && callbackCancel !== null) {
                callbackCancel();
            }
        });
    };

    static ShowOrHideMenuLoadingProgress = (show) => {
        if (show) {
            setTimeout(() => {
                $(".leopard-screen-menucover").show();
            }, 100);
        } else {
            setTimeout(() => {
                $(".leopard-screen-menucover").hide();
            }, 100);
        }
    };

    static ShowUnhandledExceptionForMasterContent = () => {
        $(".leopard-right-panel-container .leopard-loading-icon").hide();
    };

    static ShowOrHideApplicationBackgroundCover = (show) => {
        if (show) {
            setTimeout(() => {
                $(".leopard-screen-cover").show();
            }, 100);
        } else {
            setTimeout(() => {
                $(".leopard-screen-cover").hide();
            }, 100);
        }
    };

    static IsControlCentreInTestMode = () => {
        return LDH.IsValueTrue(localStorage.getItem("IsTestMode"));
    };

    static BindValidationRulesToUIObject = (data, uiObjectInstance) => {
        let validationResult = true;
        let defaultBlankValue = data.defaultBlankValue;
        let instance = uiObjectInstance[data.input];

        if (instance === undefined || instance === null) {
            return validationResult;
        }
        if (instance.option("visible") === false) {
            return validationResult;
        }

        for (var i = 0; i < data.rules.length; i++) {
            if (data.input === "validationInput") {
                validationResult = true;
                break;
            }

            if (data.rules[i].rule === "required") {
                data.e.rule.message = "This field cannot be blank";
                if (LDH.IsValueEmpty(defaultBlankValue) === false) {
                    if (data.e.value === defaultBlankValue) {
                        validationResult = false;
                        break;
                    }
                } else {
                    if (LDH.IsValueEmpty(data.e.value) === true) {
                        validationResult = false;
                        break;
                    }
                }
            }
            if (data.rules[i].rule === "safeinput") {
                data.e.rule.message = "This value is not safe";
                if (LeopardSecurity.IsInputValueDangerous(data.e.value)) {
                    validationResult = false;
                    break;
                }
            }
        }
        return validationResult;
    };

    static SendAjaxForRetriveXmlFromReportDB(url, httpType, successFunc, errorFunc) {
        url = LDH.APIEndpointAdapter() + url;
        return $.ajax({
            type: httpType, url: url,
            error: function (error) {
                if (errorFunc !== undefined && errorFunc !== null) {
                    return errorFunc(error);
                }
                return null;
            }
        }).then(function (data) {
            let outerXml = data.documentElement.outerHTML;
            if (LDH.IsObjectNull(outerXml) && LDH.IsValueEmpty(outerXml)) {
                // This is a workaround for IE9+ support.
                outerXml = new XMLSerializer().serializeToString(data.documentElement);
            }
            let result = convert.xml2json(outerXml, {compact: false, spaces: 4});
            let resultJSON = JSON.parse(result);
            if (successFunc !== undefined && successFunc !== null) {
                return successFunc(resultJSON);
            }
            return resultJSON;
        });
    }

    static SendAjaxRequestForCharts(url, httpType, linqQuery, successFunc, errorFunc) {
        return API.get("aws-api-gateway", url, {
            headers: LeopardAjaxHelper.GenericRequestHeaders()
        }).then(data => {
            if (LDH.IsTimeoutReceivedFromAPIGateway(data) === true) {
                if (errorFunc !== undefined && errorFunc !== null) {
                    errorFunc(data, "retry");
                }
                return [];
            }
            if (LDH.IsValueEmpty(linqQuery) === false && !LDH.IsObjectNull(data) &&
                !LDH.IsObjectNull(data.value)) {
                LRH.ExecuteClientScript(data, linqQuery);
            }
            if (LDH.IsObjectNull(data.value) === false) {
                if (successFunc !== undefined && successFunc !== null) {
                    successFunc(data.value);
                }
                return data.value;
            } else {
                if (errorFunc !== undefined && errorFunc !== null) {
                    errorFunc(data, "");
                }
                return [];
            }
        }).catch(error => {
            if (LDH.IsTimeoutReceivedFromAPIGateway(error) === true) {
                if (errorFunc !== undefined && errorFunc !== null) {
                    errorFunc(error, "retry");
                }
            } else {
                if (errorFunc !== undefined && errorFunc !== null) {
                    errorFunc(error, "");
                }
            }
            return [];
        });
    }

    static InitCustomStoreForChartView(url, httpType, dataViewId, serverSideQuery, linqQuery, successCallback,
                                       errorCallback, thisComp, isDataView, hasCustomQueryParams) {
        return new DataSource({
            paginate: false,
            store: new CustomStore({
                loadMode: 'raw',
                load: () => {
                    // --------------------- For Test Mode Only --------------------------
                    if (LRH.IsControlCentreInTestMode() === true) {
                        return $.ajax({type: httpType, url: ""}).then(function () {
                            let data = LeopardTestHelper.GetTestDataTableForChart();
                            successCallback(data);
                            return data;
                        });
                    }
                    // ------------------------------------------------------------------

                    let instance = thisComp.uiObjectInstance.chartInstance;
                    if (LDH.IsObjectNull(instance) === true) {
                        successCallback([]);
                        return [];
                    }

                    LDH.SetPostProcessResultForDataViewInstance(instance, isDataView,
                        hasCustomQueryParams, dataViewId);

                    let option = instance.option("setBlankPostProcess");
                    if (!LDH.IsObjectNull(option) && option === true) {
                        successCallback([]);
                        return [];
                    }

                    $("#gridViewToobar_" + dataViewId).show();
                    $("#Chart_TopBar_Refresh_" + dataViewId).addClass("leopard-ui-disabled");
                    url = window.ODataAPIGatewayUrl + serverSideQuery;
                    url = LDH.AddMacroToString(url);

                    let customQueryParams = "";
                    if (!LDH.IsObjectNull(instance.option("customQueryParams")) &&
                        !LDH.IsValueEmpty(instance.option("customQueryParams"))) {
                        customQueryParams = instance.option("customQueryParams");
                    }

                    if (!LDH.IsObjectNull(customQueryParams) && !LDH.IsValueEmpty(customQueryParams)) {
                        url = LDH.ReplaceAll(url, "{linked-params}", customQueryParams);
                    } else {
                        url = LDH.ReplaceAll(url, "{linked-params}", "");
                    }

                    return LRH.SendAjaxRequestForCharts(url, httpType, linqQuery, function (data) {
                        $("#gridViewToobar_" + dataViewId).hide();
                        $("#Chart_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                        successCallback(data);
                        return data;
                    }, function (error, errorText) {
                        if (LDH.IsObjectNull(errorText) || errorText !== "retry") {
                            LRH.ShowToast("Unable to retrieve the data for the charts.", "error", 5000);
                            $("#gridViewToobar_" + dataViewId).hide();
                            $("#Chart_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                        }
                        if (errorCallback !== undefined && errorCallback !== null) {
                            errorCallback(error, errorText);
                        }
                        return [];
                    });
                }
            })
        });
    }

    static InitCustomStoreForAutoComplete(domain, url, gridViewId, autoCompleteOperation, columnName) {
        return {
            store: {
                type: "odata",
                version: 4,
                url: domain + window.ODataAPIGatewayUrl + "/" + url +
                    "?%24apply=groupby((" + columnName + ", UserGroupId),aggregate(" + columnName +
                    " with countdistinct as total))&authentication=true",
                beforeSend: (xhr) => {
                    if (LDH.IsObjectNull(xhr.params["$filter"]) ||
                        LDH.IsValueEmpty(xhr.params["$filter"])) {
                        xhr.params["$filter"] = "tolower(UserGroupId) eq '{user-group-id}'";
                    } else {
                        xhr.params["$filter"] += " and tolower(UserGroupId) eq '{user-group-id}'";
                    }
                    xhr.params["$filter"] = LDH.AddMacroToString(xhr.params["$filter"]);

                    xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"],
                        "(text)", "(" + columnName + ")");

                    if (autoCompleteOperation === "startswith") {
                        xhr.params["$filter"] = LDH.ReplaceAll(
                            xhr.params["$filter"], "contains(", "startswith(");
                    }
                }
            },
            postProcess: (data) => {
                let list = [];
                for (var i = 0; i < data.length; i++) {
                    let value = data[i][columnName];
                    list.push({[columnName]: value});
                }
                return list;
            }
        }
    }

    static InitCustomStoreForGridView(gridDefinition, gridViewId, url, linqQuery, limitedDataColumns,
                                      callbackFunc, fullColumns, thisComp, isDataView,
                                      hasCustomQueryParams) {
        // --------------------- For Test Mode Only --------------------------
        if (LRH.IsControlCentreInTestMode() === true) {
            return $.ajax({type: "GET", url: ""}).then(function () {
                let data = LeopardTestHelper.GetTestDataTable();
                let gridData = data.items;
                callbackFunc(gridData);
                return gridData;
            });
        }
        // ------------------------------------------------------------------

        let domainUrl = LDH.APIEndpointAdapter();
        let optimizePagerForLargeDataset = gridDefinition.optimizePagerForLargeDataset;
        if (LDH.IsValueEmpty(optimizePagerForLargeDataset)) {
            optimizePagerForLargeDataset = true;
        }

        let ds = new DataSource({
            store: new ODataStore({
                version: 4,
                url: domainUrl + window.ODataAPIGatewayUrl + "/" + url + "&authentication=true",
                beforeSend: (xhr) => {
                    LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, false);
                    let instance = thisComp.uiObjectInstance.gridViewInstance;

                    LDH.SetPostProcessResultForDataViewInstance(instance, isDataView,
                        hasCustomQueryParams, gridViewId);

                    let customQueryParams = "";
                    if (!LDH.IsObjectNull(instance.option("customQueryParams")) &&
                        !LDH.IsValueEmpty(instance.option("customQueryParams"))) {
                        customQueryParams = instance.option("customQueryParams");
                    }

                    if (!LDH.IsObjectNull(customQueryParams) && !LDH.IsValueEmpty(customQueryParams)) {
                        let filter = xhr.params["$filter"];
                        if (LDH.IsObjectNull(filter) || LDH.IsValueEmpty(filter)) {
                            filter = "";
                        } else {
                            filter = " and " + filter;
                        }
                        xhr.params["$filter"] = customQueryParams + filter;
                    }

                    if (LDH.IsObjectNull(xhr.params["$filter"]) ||
                        LDH.IsValueEmpty(xhr.params["$filter"])) {
                        xhr.params["$filter"] = "tolower(UserGroupId) eq '{user-group-id}'";
                    } else {
                        xhr.params["$filter"] += " and tolower(UserGroupId) eq '{user-group-id}'";
                    }
                    xhr.params["$filter"] = LDH.AddMacroToString(xhr.params["$filter"]);

                    for (let v = 0; v < LeopardStaticUIConfig.Global_TempDateConvertion.length; v++) {
                        let tempDate = LeopardStaticUIConfig.Global_TempDateConvertion[v];
                        if (!LDH.IsObjectNull(tempDate) && !LDH.IsValueEmpty(xhr.params["$filter"]) &&
                            tempDate.dataViewId === gridViewId) {
                            xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"],
                                tempDate.convertFrom, tempDate.convertTo);
                        }
                    }

                    if (optimizePagerForLargeDataset === true) {
                        xhr.params["$count"] = false;

                        let filterString = xhr.params["$filter"];
                        let filterHistory = thisComp.combinedFilterHistory;
                        let historyLength = filterHistory.length - 2;
                        if (filterHistory.length > 5000) {
                            if (filterHistory[historyLength] !== filterString) {
                                filterHistory = [];
                                filterHistory.push("-");
                            } else {
                                filterHistory = [];
                            }
                        }
                        filterHistory.push(filterString);

                        if (!LDH.IsObjectNull(filterHistory) && historyLength > 1 &&
                            filterHistory[historyLength] !== filterString) {
                            instance.option("customPagingOperation", "");
                            instance.option("customPagingIndex", 0);
                        }

                        if (LDH.IsObjectNull(instance) === false) {
                            let operation = instance.option("customPagingOperation");
                            let pageIndex = instance.option("customPagingIndex");
                            let pageSize = instance.option("customPagingSize");

                            if (LDH.IsValueEmpty(pageSize) === true) {
                                pageSize = thisComp.props.gridDefinition.defaultPageSize;
                            }
                            if (!LDH.IsValueEmpty(operation) && operation === "next") {
                                instance.option("customPagingRefresh", false);
                                if (pageIndex + 1 < 0) pageIndex = 0;
                                xhr.params["$skip"] = pageIndex * 20;
                                instance.option("customPagingIndex", pageIndex);
                            } else if (!LDH.IsValueEmpty(operation) && operation === "prev") {
                                if (pageIndex - 1 < 0) pageIndex = 0;
                                xhr.params["$skip"] = pageIndex * 20;
                                instance.option("customPagingIndex", pageIndex);
                            } else {
                                xhr.params["$skip"] = 0;
                                instance.option("customPagingIndex", 0);
                            }
                            xhr.params["$top"] = pageSize;
                        }
                    }
                    $("#" + gridViewId + " .dx-datagrid-toolbar-button.dx-apply-button").hide();

                    let option = instance.option("setBlankPostProcess");
                    if (!LDH.IsObjectNull(option) && option === true) {
                        xhr.params["$top"] = 0;
                        xhr.params["$skip"] = 0;
                    }
                }
            }),
            requireTotalCount: true,
            select: LDH.GetIncludedColumns(limitedDataColumns, fullColumns),
            postProcess: (data) => {
                if (LDH.IsValueEmpty(linqQuery) === false) {
                    LRH.ExecuteClientScript(data, linqQuery);
                }
                if (!LDH.IsObjectNull(data) && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        for (var key in data[i]) {
                            if (data[i].hasOwnProperty(key) && !LDH.IsObjectNull(data[i][key]) &&
                                typeof data[i][key].getMonth === 'function') {
                                let value = LDH.convertUTCDateToLocalDate(data[i][key]);
                                data[i][key] = value;
                            }
                        }
                    }
                }
                setTimeout(function () {
                    callbackFunc(data, ds);
                }, 10);
                return data;
            },
            onLoadingChanged: (isLoading) => {
                if (isLoading === false) {
                    LRH.EnableOrDisableGridViewToolbarButtons(gridViewId, true);
                }
            }
        });
        return ds;
    }

    static InitCustomStoreForGridViewFilter(domain, url, columnName) {
        return {
            store: {
                type: "odata",
                version: 4,
                url: domain + window.ODataAPIGatewayUrl + "/" + url +
                    "?%24apply=groupby((" + columnName + ", UserGroupId),aggregate(" +
                    columnName + " with countdistinct as total))&authentication=true",
                beforeSend: (xhr) => {
                    if (LDH.IsObjectNull(xhr.params["$filter"]) ||
                        LDH.IsValueEmpty(xhr.params["$filter"])) {
                        xhr.params["$filter"] = "tolower(UserGroupId) eq '{user-group-id}'";
                    } else {
                        xhr.params["$filter"] += " and tolower(UserGroupId) eq '{user-group-id}'";
                    }
                    xhr.params["$filter"] = LDH.AddMacroToString(xhr.params["$filter"]);
                    xhr.params["$filter"] = LDH.ReplaceAll(xhr.params["$filter"], "(text)", "(" + columnName + ")");
                }
            },
            postProcess: (data) => {
                let list = [];
                for (var i = 0; i < data.length; i++) {
                    let value = data[i][columnName];
                    list.push({text: value, value: value});
                }
                return list;
            }
        }
    }

    static EnableOrDisableGridViewToolbarButtons = (gridViewId, enabled) => {
        if (enabled === true) {
            $("#gridViewToobar_" + gridViewId).hide();
            $("#GridView_TopBar_Refresh_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_Export_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ViewOptions_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_AddRecord_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ApplyFilter_" + gridViewId).removeClass("leopard-ui-disabled");
            $("#GridView_TopBar_ClearFilter_" + gridViewId).removeClass("leopard-ui-disabled");
            $(".leopard-pagination-blocker", $("#" + gridViewId)).hide();
            $("#GridViewPager_" + gridViewId).removeClass("leopard-ui-disabled");
        } else {
            $("#gridViewToobar_" + gridViewId).show();
            $("#GridView_TopBar_Refresh_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_Export_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ViewOptions_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_AddRecord_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ApplyFilter_" + gridViewId).addClass("leopard-ui-disabled");
            $("#GridView_TopBar_ClearFilter_" + gridViewId).addClass("leopard-ui-disabled");
            $(".leopard-pagination-blocker", $("#" + gridViewId)).show();
            $("#GridViewPager_" + gridViewId).addClass("leopard-ui-disabled");
        }
    };

    static TriggerWindowResizeEvent() {
        if (typeof (Event) === 'function') {
            window.dispatchEvent(new Event('resize'));
        } else {
            var evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }
    }

    static DownloadStringToFile(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    static DestoryDraggingOnGridView(gridViewId) {
        $('.ui-draggable.gridViewId_' + gridViewId, $("#" + gridViewId + " .dx-datagrid-content"))
            .not(".dx-pointer-events-target").each(function () {
            // Object needs to be destroyed to prevent memory leak.
            //$(this).draggable("destroy");
        });
    }

    static ExecuteClientScript(data, linqQuery) {
        window.data = data;
        window.moment = moment;
        window.__callback = function () {
            LRH.ShowToast("Your client-side query could not be processed. Raw data is being used.", "warning", 5000);
        };
        let query = LDH.GetClientSideQuery(linqQuery);
        $.globalEval(query);
        window.data = null;
        window.moment = null;
    }

    static ApplyCustomStyle(styleDefinition, version) {
        if (styleDefinition !== undefined && styleDefinition !== null &&
            styleDefinition.length > 0) {
            var defaultStyle = '<link id="leopard-default-style" rel="stylesheet" type="text/css" href="/css/custom.css?v=' + version + '" />';
            var customStyle = '<style id="leopard-custom-style" type="text/css">' + styleDefinition + '</style>';

            $("#leopard-default-style").remove();
            $("head").append(defaultStyle);

            $("#leopard-custom-style").remove();
            $("head").append(customStyle);
        } else {
            $("#leopard-custom-style").remove();
        }
    }

    static InitDraggingOnGridView(gridViewId, gridViewInstance, allowDragging) {
        if (allowDragging === undefined || allowDragging === false) {
            return;
        }
        $('.draggable.gridViewId_' + gridViewId, $("#" + gridViewId + " .dx-datagrid-content"))
            .not(".dx-pointer-events-target").draggable({
            helper: 'clone',
            start: function (ev, ui) {
                var $drag = $(ui.helper);
                var $gridview = $("#" + gridViewId + " .dx-datagrid-content")
                    .not(".dx-pointer-events-target");
                var $hover = $(".dx-state-hover.gridViewId_" + gridViewId, $gridview);

                if ($drag.hasClass("dragging-object") === false) {
                    $drag.addClass("dragging-object");
                }

                var $rows = null;
                var count = 0;
                if (ev.altKey === false && ev.ctrlKey === false && ev.metaKey === false) {
                    if ($hover.length > 0 && !$hover.hasClass("dx-selection")) {
                        gridViewInstance.clearSelection();
                        $("#" + gridViewId + "_DragAndDropSelectionCount").text(1);
                    } else {
                        $rows = $(".dx-selection.gridViewId_" + gridViewId, $gridview);
                        count = $rows.not(".dragging-object").length;
                        $("#" + gridViewId + "_DragAndDropSelectionCount").text(count);
                    }
                } else {
                    if ($hover.length > 0 && !$hover.hasClass("dx-selection")) {
                        $rows = $(".dx-selection.gridViewId_" + gridViewId, $gridview);
                        count = $rows.length;
                        if ($rows.hasClass(".dragging-object") === false) {
                            count = $rows.length + 1;
                        }
                        $("#" + gridViewId + "_DragAndDropSelectionCount").text(count);
                    } else {
                        $rows = $(".dx-selection.gridViewId_" + gridViewId, $gridview);
                        count = $rows.not(".dragging-object").length;
                        $("#" + gridViewId + "_DragAndDropSelectionCount").text(count);
                    }
                }

                if ($drag.hasClass("dx-selection") === false) {
                    $drag.addClass("dx-selection");
                }
                var width = $("#" + gridViewId).width();
                var columnCount = $("td", $drag).not(".dx-command-adaptive").length;
                $drag.width(width).addClass("leopard-bgcolor-orange");
                $("td", $drag).not(".dx-command-adaptive").width(width / columnCount);
                $("#" + gridViewId + "_DragAndDropSelectionCount").hide();
            },
            stop: function () {
                var $gridview = $("#" + gridViewId + " .dx-datagrid-content")
                    .not(".dx-datagrid-content-fixed");
                var $row = $(".dragging-object", $gridview);

                if ($row.hasClass("dragging-object")) {
                    $row.removeClass("dragging-object");
                }
                $("#" + gridViewId + "_DragAndDropSelectionCount").hide();
            },
            drag: function (ev) {
                if (!$("#" + gridViewId + "_DragAndDropSelectionCount").is(":visible")) {
                    $("#" + gridViewId + "_DragAndDropSelectionCount").show();
                }
                $("#" + gridViewId + "_DragAndDropSelectionCount").css({
                    top: ev.pageY, left: ev.pageX
                });
            }
        });
    }
}

export default LeopardReactHelper;