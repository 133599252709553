import 'babel-polyfill/dist/polyfill';
import 'whatwg-fetch/fetch';
import 'linqjs';
import 'devextreme/data/odata/store';
import 'devextreme/dist/css/dx.common.css';
import './css/fontawesome/css/all.min.css';
import './css/stimulsoft/stimulsoft.viewer.office2013.whiteorange.css';
import './css/stimulsoft/stimulsoft.designer.office2013.whiteorange.css';

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import LeopardReducer from './foundation/LeopardReducer';
import { Auth, Analytics } from 'aws-amplify';
import LeopardAuthenticator from './LeopardAuthenticator';
import LeopardCognitoConfig from "./foundation/LeopardCognitoConfig";
const store = createStore(LeopardReducer);

Analytics.configure({ disabled: true });
Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

ReactDOM.render(
    <div style={{ overflow: "hidden" }}>
        <CookiesProvider>
            <LeopardAuthenticator store={store} />
        </CookiesProvider>
    </div>
    , document.getElementById('root'));
