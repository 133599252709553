import React, { Component } from 'react';
import { connect } from 'react-redux';
import LeopardChartEngine from '../foundation/LeopardChartEngine';
import LeopardChartDesigner from '../datashaping/LeopardChartDesigner';
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";

class LeopardChartConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = { isExpandDesigner: false }
    }

    chartDesignerButtonOnClick = () => {
        if (this.state.isExpandDesigner) {
            this.setState({ isExpandDesigner: false }, function () {
                this.props.updateWindowDimensionsRequired();
            });
        }
        else {
            this.setState({ isExpandDesigner: true }, function () {
                this.props.updateWindowDimensionsRequired();
            });
        }
    };

    render() {
        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
            showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
        }

        return (
            <React.Fragment>
                {
                    (showGridViewAdminToolbar && !this.state.isExpandDesigner) === false ? "" :
                        <div style={{height: "30px"}}>
                            <span className={"leopard-no-whitespace"} style={{ padding: "0 10px 0 15px" }}>
                                  <LeopardTooltipWithLink elementId={"Chart_Admin_ConfigureChart_" + this.props.dataViewId}
                                                          labelText={"Configure Chart"} width={250} title={"Configure Chart"}
                                                          additionalClass={"leopard-gridview-admin-toolbar"}
                                                          onClick={(e) => this.chartDesignerButtonOnClick({e: e})}
                                                          text={"This feature allows an admin user to configure the chart settings."}/>
                            </span>
                        </div>
                }
                {
                    this.state.isExpandDesigner === false ? "" :
                        <div className="leopard-chart-designer" custom_attr_gridviewid={this.props.dataViewId}>
                            <LeopardChartDesigner dataViewId={this.props.dataViewId} definition={this.props.definition}
                                chartDesignerButtonOnClick={(e) => this.chartDesignerButtonOnClick(e)}
                                chartType={this.props.chartType}
                                chartDefinitionUpdateForPreview={this.props.chartDefinitionUpdateForPreview} />
                        </div>
                }
                <LeopardChartEngine dataSourceUrl={this.props.dataSourceUrl} palette={this.props.palette}
                    definition={this.props.definition} dataInitializedOnControls={this.props.dataInitializedOnControls}
                    dataInitializedOnControlsUpdateRequest={this.props.dataInitializedOnControlsUpdateRequest}
                    setChartInstance={this.props.setChartInstance} useStateStore={false}
                    chartType={this.props.chartType} dataViewId={this.props.dataViewId}
                    updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                />
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return { state: state };
};

export default connect(RetrieveDataFromReducer, null)(LeopardChartConfiguration);