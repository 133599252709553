import React, {Component} from 'react';
import LeopardPhotoGalleryEngine from "./LeopardPhotoGalleryEngine";

class LeopardChildPhotoGallery extends Component {
    render() {
        return (
            <React.Fragment>
                <LeopardPhotoGalleryEngine definition={this.props.definition} dashboardLevel={this.props.dashboardLevel}
                                    useStateStore={this.props.useStateStore} relationships={this.props.relationships}
                                    updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                                    setPhotoInstance={this.props.setPhotoInstance} dataViewId={this.props.dataViewId}/>
            </React.Fragment>
        );
    }
}

export default LeopardChildPhotoGallery;