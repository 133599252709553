import React from 'react';
import LDH from './LeopardDataHelper';
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardDataHelper extends React.Component {
    static GetColumnFromJSON(json) {
        if (!LeopardDataHelper.IsObjectNull(json) && json.length > 0) {
            var columns = [];
            for (var key in json[0]) {
                if (json[0].hasOwnProperty(key)) {
                    columns.push({columnName: key});
                }
            }
            return columns;
        }
        return [];
    }

    static IsObjectNull(obj) {
        return obj === undefined || obj === null;
    }

    static IsValueEmpty(value) {
        return value === undefined || value === null ||
            value === "" || value.toString().trim() === "";
    }

    static ReplaceAll = function (str, search, replacement) {
        return str.split(search).join(replacement);
    }

    static GetAllLocalStorageItemKeys() {
        let archive = [];
        let keys = Object.keys(localStorage);
        for (var i = 0; i < keys.length; i++) {
            archive.push(keys[i]);
        }
        return archive;
    }

    static DeleteLocalStorageById(id) {
        localStorage.removeItem(id);
    }

    static IsValidIPAddress(ipAddress) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress)) {
            return true;
        }
        return false;
    }

    static convertUTCDateToLocalDate(date) {
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        date.setHours(hours - offset);
        return date;
    }

    static getLocalISODateString(localDate) {
        var tzoffset = localDate.getTimezoneOffset() * 60000;
        var localISOTime = (new Date(localDate - tzoffset)).toISOString().slice(0, -1);
        return localISOTime + "Z";
    }

    static ConvertJsonDataNullOrEmptyValues(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        var clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (var key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (clonedJsonData[key] === undefined) {
                    clonedJsonData[key] = "[UNDEFINED]";
                } else if (clonedJsonData[key] === null) {
                    clonedJsonData[key] = "[NULL]";
                } else if (clonedJsonData[key] === "") {
                    clonedJsonData[key] = "[EMPTY]";
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.ConvertJsonDataNullOrEmptyValues(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static UnconvertJsonDataNullOrEmptyValues(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        var clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (var key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (clonedJsonData[key] === "[UNDEFINED]") {
                    clonedJsonData[key] = undefined;
                } else if (clonedJsonData[key] === "[NULL]") {
                    clonedJsonData[key] = null;
                } else if (clonedJsonData[key] === "[EMPTY]") {
                    clonedJsonData[key] = "";
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.UnconvertJsonDataNullOrEmptyValues(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static IsValueTrue(value) {
        if (value === undefined || value === null || value === "") {
            return false;
        }
        return value.toString().toLowerCase() === "true";
    }

    static DeepClone(obj) {
        if (this.IsObjectNull(obj)) return null;
        return JSON.parse(JSON.stringify(obj));
    }

    static GenerateGuid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }

    static GenerateGuidWithDashes() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()).toUpperCase();
    }

    static IsIsoDateFormat(str) {
        try {
            if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
                return false;
            }
            var d = new Date(str);
            return d.toISOString() === str;
        } catch {
            return false;
        }
    }

    static TraverseJSONObject(jsonObj) {
        if (jsonObj !== null && typeof jsonObj == "object") {
            Object.entries(jsonObj).forEach(([key, value]) => {
                let v = LDH.TraverseJSONObject(value);
                jsonObj[key] = v;
            });
            return jsonObj
        } else {
            try {
                var date = new Date(jsonObj);
                if (!LDH.IsValueEmpty(date) &&
                    LDH.IsIsoDateFormat(jsonObj)) {
                    jsonObj = date;
                }
            } catch {
                return jsonObj
            }
        }
        return jsonObj
    }

    static ParseDevExtremeFilterString(filterValue) {
        if (this.IsObjectNull(filterValue)) return null;
        let clonedValue = LDH.DeepClone(filterValue);
        try {
            return LDH.TraverseJSONObject(filterValue);
        } catch {
            return clonedValue;
        }
    }

    static AddLeadingZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    static GetClientSideQuery(query) {
        var id = LDH.GenerateGuid();
        return "var val_" + id + "=data;try{" + query + "val_" + id + "=null}" +
            "catch{data = val_" + id + ";window.__callback();}";
    }

    static IsTimeoutReceivedFromAPIGateway(data) {
        if (!LDH.IsObjectNull(data) && typeof data === "object" && !LDH.IsObjectNull(data.errorMessage) &&
            data.errorMessage.indexOf("ETIMEDOUT") !== -1) {
            return true;
        }
        if (!LDH.IsObjectNull(data) && typeof data === "object" && !LDH.IsObjectNull(data.message) &&
            data.message.indexOf("timed out") !== -1) {
            return true;
        }
        if (!LDH.IsObjectNull(data) && typeof data === "object" &&
            !LDH.IsObjectNull(data.response) && typeof data.response === "object" &&
            !LDH.IsObjectNull(data.response.data) && typeof data.response.data === "object" &&
            !LDH.IsObjectNull(data.response.data.message) &&
            data.response.data.message.indexOf("timed out") !== -1) {
            return true;
        }
        return false;
    }

    static GetTodayUtc() {
        var today = new Date();
        return (new Date(today.getFullYear(), today.getMonth(), today.getDate())).toISOString();
    }

    static GetIncludedColumns(limitedDataColumns, fullColumns) {
        let filterColumns = [];
        if (!LDH.IsObjectNull(limitedDataColumns) && limitedDataColumns.length > 0) {
            for (var i = 0; i < limitedDataColumns.length; i++) {
                let found = false;
                for (var j = 0; j < fullColumns.length; j++) {
                    if (fullColumns[j] === limitedDataColumns[i]) {
                        found = true;
                        break;
                    }
                }
                if (found === false) {
                    filterColumns.push(limitedDataColumns[i]);
                }
            }
        }
        return filterColumns;
    }

    static AddHoursToDateUtc(isoDate, hours) {
        var result = new Date(isoDate);
        result.setHours(result.getHours() + hours);
        return result.toISOString();
    }

    static GenerateRandomNumber(minValue, maxValue) {
        return Math.floor((Math.random() * maxValue) + minValue);
    }

    static AddMacroToString(dataString) {
        let today = LDH.GetTodayUtc();
        let yesterday = LDH.AddHoursToDateUtc(today, -24);
        let tomorrow = LDH.AddHoursToDateUtc(today, 24);

        let next3days = LDH.AddHoursToDateUtc(today, 24 * 3);
        let next7days = LDH.AddHoursToDateUtc(today, 24 * 7);
        let next14days = LDH.AddHoursToDateUtc(today, 24 * 14);
        let next30days = LDH.AddHoursToDateUtc(today, 24 * 30);

        let last3days = LDH.AddHoursToDateUtc(today, -24 * 3);
        let last7days = LDH.AddHoursToDateUtc(today, -24 * 7);
        let last14days = LDH.AddHoursToDateUtc(today, -24 * 14);
        let last30days = LDH.AddHoursToDateUtc(today, -24 * 30);

        dataString = LDH.ReplaceAll(dataString, "{today}", today);
        dataString = LDH.ReplaceAll(dataString, "{yesterday}", yesterday);
        dataString = LDH.ReplaceAll(dataString, "{tomorrow}", tomorrow);

        dataString = LDH.ReplaceAll(dataString, "{next3days}", next3days);
        dataString = LDH.ReplaceAll(dataString, "{next7days}", next7days);
        dataString = LDH.ReplaceAll(dataString, "{next7days}", next7days);
        dataString = LDH.ReplaceAll(dataString, "{next14days}", next14days);
        dataString = LDH.ReplaceAll(dataString, "{next30days}", next30days);

        dataString = LDH.ReplaceAll(dataString, "{last3days}", last3days);
        dataString = LDH.ReplaceAll(dataString, "{last7days}", last7days);
        dataString = LDH.ReplaceAll(dataString, "{last14days}", last14days);
        dataString = LDH.ReplaceAll(dataString, "{last30days}", last30days);

        let orgId = window.userProfile.Parent.toLowerCase();
        dataString = LDH.ReplaceAll(dataString, "{user-group-id}", orgId);

        return dataString;
    }

    static ConvertArrayMacroToString(dataString, arrayData) {
        let columns = Object.keys(arrayData);
        for (var i = 0; i < columns.length; i++) {
            let name = columns[i];
            dataString = LDH.ReplaceAll(dataString,
                "{" + name + "}", arrayData[name]);
        }
        return dataString;
    }

    static GetOrganizationIdFromUserProfile(userProfile) {
        if (userProfile === undefined || userProfile === null ||
            userProfile.data === undefined || userProfile.data === null) {
            return null;
        }
        return userProfile.data.Parent;
    }

    static GetUserIdFromUserProfile(userProfile) {
        if (userProfile === undefined || userProfile === null ||
            userProfile.data === undefined || userProfile.data === null) {
            return null;
        }
        return userProfile.data.ID;
    }

    static GetRelationshipsByDashboardItemId = (relationships, dashboardItemId) => {
        let resultList = [];
        for (var i = 0; i < relationships.length; i++) {
            if (relationships[i].parentDataViewId.split(":")[0] === dashboardItemId) {
                resultList.push(relationships[i]);
            }
            if (relationships[i].childDataViewId.split(":")[0] === dashboardItemId) {
                resultList.push(relationships[i]);
            }
        }
        return resultList;
    }

    static ValidateDashboardRelationships = (relationships, requestPId, requestCId, requestRId) => {
        let parentRIds = [];
        if (LDH.IsValueEmpty(requestPId) || LDH.IsValueEmpty(requestCId)) return true;
        if (requestPId === requestCId) return false;

        for (var w = 0; w < relationships.length; w++) {
            if (relationships[w].relationshipId === requestRId) {
                relationships[w].parentDataViewId = requestPId;
                relationships[w].childDataViewId = requestCId;
            }
        }

        for (var i = 0; i < relationships.length; i++) {
            if (relationships[i].childDataViewId === requestCId &&
                relationships[i].relationshipId !== requestRId) {
                return false;
            }

            if (relationships[i].childDataViewId === requestCId &&
                relationships[i].parentDataViewId === requestPId &&
                requestRId !== relationships[i].relationshipId) {
                return false;
            }

            for (var j = 0; j < relationships.length; j++) {
                if (relationships[i].childDataViewId === relationships[j].parentDataViewId &&
                    !LDH.IsValueEmpty(relationships[j].parentDataViewId)) {

                    let foundParent = false;
                    for (var m = 0; m < parentRIds.length; m++) {
                        if (parentRIds[m] === relationships[i].relationshipId) {
                            foundParent = true;
                        }
                    }
                    if (foundParent === false) {
                        parentRIds.push(relationships[i].relationshipId);
                    }

                    let result = this.FindDashboardRelationshipItem(relationships, requestPId,
                        requestCId, requestRId, relationships[j].relationshipId,
                        parentRIds);

                    if (result === "not-found") {
                        parentRIds = [];
                    } else {
                        return result;
                    }
                }
            }
        }
        return true;
    }

    static FindDashboardRelationshipItem = (relationships, requestPId, requestCId,
                                            requestRId, currentRId, parentRIds) => {
        let currentChildDataViewId = "";
        if (currentRId === requestRId) {
            currentChildDataViewId = requestCId;
        } else {
            for (var i = 0; i < relationships.length; i++) {
                if (relationships[i].relationshipId === currentRId) {
                    currentChildDataViewId = relationships[i].childDataViewId;
                }
            }
        }

        for (var k = 0; k < parentRIds.length; k++) {
            for (var n = 0; n < relationships.length; n++) {
                if (relationships[n].relationshipId === parentRIds[k] &&
                    relationships[n].parentDataViewId === currentChildDataViewId) {
                    return false;
                }
            }
        }

        for (var w = 0; w < relationships.length; w++) {
            if (relationships[w].childDataViewId === currentChildDataViewId &&
                relationships[w].relationshipId !== currentRId) {
                return false;
            }
        }

        for (var j = 0; j < relationships.length; j++) {
            if (currentChildDataViewId === relationships[j].parentDataViewId &&
                !LDH.IsValueEmpty(relationships[j].parentDataViewId)) {
                parentRIds.push(currentRId);

                return this.FindDashboardRelationshipItem(relationships, requestPId,
                    requestCId, requestRId, relationships[j].relationshipId,
                    parentRIds);
            }
        }
        return "not-found";
    }

    static SetRelationshipColorBar = (relationships) => {
        if (LDH.IsObjectNull(relationships)) return [];
        let colorBars = LeopardStaticUIConfig.LeopardDashboardColorBars;
        let colorIndex = 0;
        let coloredParentDataViewIds = [];

        for (var n = 0; n < relationships.length; n++) {
            if (LDH.IsValueEmpty(relationships[n].parentDataViewId) ||
                LDH.IsValueEmpty(relationships[n].childDataViewId)) {
                continue;
            }
            let foundColor = null;
            let foundLevel = null;
            let pId = relationships[n].parentDataViewId;

            for (var k = 0; k < coloredParentDataViewIds.length; k++) {
                if (coloredParentDataViewIds[k].pId === pId) {
                    foundColor = coloredParentDataViewIds[k].color;
                    foundLevel = coloredParentDataViewIds[k].level;
                    break;
                }
            }
            if (foundColor !== null) {
                relationships[n].color = foundColor;
                relationships[n].level = foundLevel;
            }

            if ((LDH.IsObjectNull(relationships[n].color) ||
                LDH.IsValueEmpty(relationships[n].color)) &&
                LDH.IsValueEmpty(foundColor)) {
                if (colorIndex > colorBars.length) {
                    colorIndex = 0;
                }

                let color = colorBars[colorIndex];
                let level = 1;

                for (var j = 0; j < relationships.length; j++) {
                    let cId = relationships[n].childDataViewId;
                    if (relationships[j].parentDataViewId === cId &&
                        !LDH.IsObjectNull(relationships[j].color) &&
                        !LDH.IsValueEmpty(relationships[j].color)) {
                        color = relationships[j].color;
                        break;
                    }
                }
                relationships[n].color = color;
                relationships[n].level = level;
                coloredParentDataViewIds.push({
                    pId: pId, color: color, level: level
                });
                colorIndex++;
            }

            let rsId = relationships[n].relationshipId;
            LDH.SetRelationshipColorForChildren(rsId, relationships);
        }
        return relationships;
    }

    static SetRelationshipColorForChildren = (currentRelationshipId, relationships) => {
        for (var i = 0; i < relationships.length; i++) {
            if (currentRelationshipId !== relationships[i].relationshipId) {
                continue;
            }
            let childDataViewId = relationships[i].childDataViewId;

            for (var j = 0; j < relationships.length; j++) {
                if (relationships[j].parentDataViewId === childDataViewId) {
                    relationships[j].color = relationships[i].color;

                    let level = LDH.IsObjectNull(relationships[i].level) ||
                    LDH.IsValueEmpty(relationships[i].level) ? 1 :
                        relationships[i].level + 1;

                    relationships[j].level = level;
                    let rsId = relationships[j].relationshipId;
                    LDH.SetRelationshipColorForChildren(rsId, relationships);
                }
            }
        }
    }

    static SetPostProcessResultForDataViewInstance = (instance, isDataView, hasCustomQueryParams,
                                                      dashboardItemId) => {
        let relationships = instance.option("relationships");
        if (!isDataView && (LDH.IsObjectNull(instance.option("customQueryParams")) ||
            LDH.IsValueEmpty(instance.option("customQueryParams"))) &&
            hasCustomQueryParams) {
            if (!LDH.IsObjectNull(relationships) && relationships.length > 0) {
                let foundParent = false;
                for (var v = 0; v < relationships.length; v++) {
                    if (relationships[v].childDataViewId.split(":")[0] === dashboardItemId &&
                        relationships[v].parentDataViewId.split(":")[0] !== dashboardItemId) {
                        foundParent = true;
                    }
                }
                if (foundParent) instance.option("setBlankPostProcess", true);
            } else {
                instance.option("setBlankPostProcess", false);
            }
        }
    }

    static APIEndpointAdapter = () => {
        if (window.location.hostname !== "localhost" &&
            LDH.IsValidIPAddress(window.location.hostname) === false) {
            return "https://api." + window.location.hostname;
        }
        return "https://api.st2.leopardcube.com.au";
    };
}

export default LeopardDataHelper;