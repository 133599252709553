import React, {Component} from 'react';
import {connect} from 'react-redux';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import {InitChartData, InitCustomStoreForChart} from './LeopardActionCreators';
import Chart, {
    ValueAxis, Label, Legend, Series, Export, Point, CommonSeriesSettings,
    ZoomAndPan, ArgumentAxis, Tooltip, ScrollBar, ConstantLine, Animation
} from 'devextreme-react/chart';
import PieChart, {Connector, SmallValuesGrouping} from 'devextreme-react/pie-chart';
import LeopardDesignerCountdownSwitch from "../datashaping/LeopardDesignerCountdownSwitch";
import LeopardTooltipWithLink from "../datashaping/LeopardTooltipWithLink";
import $ from "jquery";
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";

class LeopardChartEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // ---- For "UseStateStore => True" Only ----
            customStore: null,
            chartData: null
            // ------------------------------------------
        };

        this.uiObjectInstance = {
            chartInstance: null
        };
        this.retryConnectionCount = 0;
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
        this.isFirstRowSelectedOnInitLoad = false;

        // -------------------- NON-GENERIC CODE START --------------------
        this.barchartConfiguration = {
            palette: this.props.palette,
            paletteIndex: 0
        };
        // -------------------- NON-GENERIC CODE END ----------------------
    }

    setChartInstance = (ref) => {
        let that = this;
        if (ref === null || ref.instance === null) return;

        let requireRefresh = false;
        if (LDH.IsObjectNull(this.uiObjectInstance.chartInstance)) {
            requireRefresh = true;
        }
        this.uiObjectInstance.chartInstance = ref.instance;

        this.props.setChartInstance({
            instance: ref.instance,
            id: this.props.dataViewId,
            type: "chart",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstance.chartInstance.option("relationships", linkedList);
        }

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId: dashboardItemId,
                props: that.props,
                instance: ref.instance,
                callback: function (data) {
                    for (var i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            let chartDefinition = this.props.definition.chartDefinition;
                            let params = chartDefinition.oDataQueryForLinkedView;
                            if (!LDH.IsObjectNull(params) && !LDH.IsValueEmpty(params)) {
                                if (LDH.IsObjectNull(data.dataFromSource)) {
                                    this.instance.option("setBlankPostProcess", true);
                                    that.refreshChartByInstance(this.instance);
                                } else {
                                    params = LDH.ConvertArrayMacroToString(params, data.dataFromSource);
                                    this.instance.option("customQueryParams", params);
                                    this.instance.option("setBlankPostProcess", false);
                                    this.instance.option("selectFirstRow", true);
                                    that.refreshChartByInstance(this.instance);
                                }
                            }
                        }
                    }
                }
            });
        }

        if (requireRefresh === true) {
            this.refreshChartByInstance(ref.instance);
        }
    };

    componentWillUnmount = () => {
        let instance = this.uiObjectInstance.chartInstance;
        if (instance !== undefined && instance !== null) {
            instance.dispose();
        }
        this.props.setChartInstance({
            instance: null,
            id: this.props.dataViewId,
            type: "chart",
            isDataView: true
        });
    };

    componentDidMount = () => {
        this.relationships = this.props.relationships;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            this.initializeCustomStore(true);
        } else {
            this.initializeCustomStore(false);
        }
    };

    initializeCustomStore = (isDataView) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let url = this.props.dataSourceUrl;
        let serverSideQuery = this.props.definition.chartDefinition.customQueryAttributes;
        let linqQuery = this.props.definition.chartDefinition.clientSideQueryEditor;
        let chartDefinition = this.props.definition.chartDefinition;

        let hasCustomQueryParams = false;
        if (!LDH.IsObjectNull(chartDefinition.oDataQueryForLinkedView) &&
            !LDH.IsValueEmpty(chartDefinition.oDataQueryForLinkedView)) {
            hasCustomQueryParams = true;
        }

        let store = LRH.InitCustomStoreForChartView(url, "GET", dataViewId, serverSideQuery,
            linqQuery, function (data) {
                that.retryConnectionCount = 0;
                if (LDH.IsObjectNull(data) === false) {
                    if (isDataView === true) {
                        that.props.InitChartData(data, dataViewId);
                    } else {
                        that.setState({chartData: data});
                    }
                    that.props.updateWindowDimensionsRequired();
                    let instance = that.uiObjectInstance.chartInstance;
                    let selectByDefault = LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault;
                    let isSelectFirstRow = false;

                    if (!isDataView && !LDH.IsObjectNull(instance) && data.length > 0 &&
                        !LDH.IsObjectNull(instance.option("selectFirstRow")) &&
                        instance.option("selectFirstRow")) {
                        isSelectFirstRow = true;
                    } else if (!isDataView && that.props.dashboardLevel === 1 &&
                        that.isFirstRowSelectedOnInitLoad === false &&
                        LDH.IsObjectNull(instance) === false) {
                        isSelectFirstRow = true;
                    } else if (!isDataView && selectByDefault === true &&
                        LDH.IsObjectNull(instance) === false) {
                        isSelectFirstRow = true;
                    }

                    if (isSelectFirstRow === true) {
                        setTimeout(function () {
                            that.isFirstRowSelectedOnInitLoad = true;
                            let series = instance.getSeriesByPos(0);
                            if (LDH.IsObjectNull(series) === false) {
                                let all = series.getAllPoints();
                                if (all.length > 0) {
                                    let last = all[all.length - 1];
                                    series.selectPoint(last);
                                }
                            }
                            instance.option("selectFirstRow", false);
                        }, 100);
                    }
                }
            }, function (error, errorText) {
                $(".dataview_" + dataViewId).show();
                $(".leopard-right-panel-container .leopard-loading-icon").hide();
                that.props.updateWindowDimensionsRequired();

                if (that.retryConnectionCount < 10) {
                    setTimeout(function () {
                        if (!LDH.IsObjectNull(errorText) && errorText === "retry") {
                            let instance = that.uiObjectInstance.chartInstance;
                            if (!LDH.IsObjectNull(instance)) {
                                let $parent = $("#gridViewToobar_" + dataViewId);
                                $(".toolbar-warming-up-text", $parent).show();
                                that.retryConnectionCount += 1;
                                that.refreshChartByInstance(instance);
                            }
                        }
                    }, 100);
                } else {
                    LRH.ShowToast("Unable to retrieve the data for the charts.", "error", 5000);
                    $("#gridViewToobar_" + dataViewId).hide();
                    $("#Chart_TopBar_Refresh_" + dataViewId).removeClass("leopard-ui-disabled");
                }
            }, this, isDataView, hasCustomQueryParams);

        if (isDataView === true) {
            this.props.InitCustomStoreForChart(store, dataViewId);
        } else {
            this.setState({customStore: store});
        }
    };

    // -------------------- NON-GENERIC CODE START --------------------

    static barChartConfiguration_customizeText(e, templateValue) {
        if (LDH.IsValueEmpty(templateValue)) return e.valueText;
        return templateValue.replace("#value#", e.valueText);
    }

    static doughnutChartConfiguration_customizeLabel(point) {
        return `${point.argumentText}`;
    }

    // -------------------- NON-GENERIC CODE END ---------------------

    renderCommonSeriesSettingsForBarChart = (chartDefinition) => {
        let genericCommonSeriesSettingsForBarChartProps = {
            argumentField: chartDefinition.seriesArgumentField,
            valueField: chartDefinition.seriesValueField,
            type: chartDefinition.seriesTypeOfSeries,
            cornerRadius: chartDefinition.seriesStyleCornerRadius,
            barWidth: chartDefinition.seriesStyleBarWidth,
            barPadding: chartDefinition.seriesStyleBarPadding,
            ignoreEmptyPoints: chartDefinition.seriesIgnoreEmptyPoint
        };
        if (chartDefinition.seriesTypeOfSeries !== "bar") return ("");
        return (<CommonSeriesSettings {...genericCommonSeriesSettingsForBarChartProps} />);
    };

    renderSeriesHighAverageForBarChart = (chartDefinition) => {
        let genericConstantLineHighAverageProps = {
            width: 2,
            value: chartDefinition.valueAxisHighAverage,
            color: '#ff7c7c',
            dashStyle: 'dash'
        };
        if (LDH.IsValueEmpty(chartDefinition.valueAxisHighAverage)) {
            return ("");
        }
        return (
            <ConstantLine {...genericConstantLineHighAverageProps}>
                <Label text={chartDefinition.valueAxisHighAverageText}/>
            </ConstantLine>
        )
    };

    renderSeriesLowAverageForBarChart = (chartDefinition) => {
        let genericConstantLineLowAverageProps = {
            width: 2,
            value: chartDefinition.valueAxisLowAverage,
            color: '#8c8cff',
            dashStyle: 'dash'
        };
        if (LDH.IsValueEmpty(chartDefinition.valueAxisLowAverage)) {
            return ("");
        }
        return (
            <ConstantLine {...genericConstantLineLowAverageProps}>
                <Label text={chartDefinition.valueAxisLowAverageText}/>
            </ConstantLine>
        )
    };

    onDrawn = () => {
        if (this.props.dataInitializedOnControls !== undefined &&
            this.props.dataInitializedOnControls === false) {
            this.props.dataInitializedOnControlsUpdateRequest();
        }
    };

    onLegendClick = (e) => {
        var series = e.target;
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }

    refreshChartByInstance = (instance) => {
        let visualBounds = instance.getVisibleArgumentBounds();
        let min = visualBounds.minVisible;
        let max = visualBounds.maxVisible;
        instance.option("dataSource").reload();

        if (!LDH.IsObjectNull(min) && !LDH.IsObjectNull(max)) {
            instance.zoomArgument(min, max);
        }
    }

    refreshOnClick = (e) => {
        let id = this.props.dataViewId;
        if ($("#Chart_TopBar_Refresh_" + id).hasClass("leopard-ui-disabled")) {
            return;
        }
        let instance = this.uiObjectInstance.chartInstance;
        if (!LDH.IsObjectNull(instance)) {
            this.refreshChartByInstance(instance);
        }
    }

    autoRefreshCountdownOnEnd = () => {
        this.refreshOnClick();
    }

    chartToolbar = (chartDefinition) => {
        let that = this;
        return (
            <React.Fragment>
                <div className={"leopard-gridview-toolbar"} style={{minHeight: "30px"}}>
                    {
                        chartDefinition.enableAutoRefresh === false ? "" :
                            <span id={"autoRefresh_" + that.props.dataViewId}
                                  className={"leopard-autorefresh-button_wrapper"}
                                  style={{display: chartDefinition.showAutoRefreshSwitch ? "block" : "none"}}>
                                     <div id={"autoRefreshCountdownControl_" + that.props.dataViewId}>
                                        <LeopardDesignerCountdownSwitch
                                            autoRefreshCountdownOnEnd={that.autoRefreshCountdownOnEnd}
                                            tooltip={"The chart will be refreshed automatically when timer counts down to 0."}
                                            autoRefreshInterval={chartDefinition.autoRefreshInterval}
                                            fieldValue={chartDefinition.enableAutoRefresh}
                                            gridViewId={that.props.dataViewId}/>
                                     </div>
                                </span>
                    }
                    <span id={"gridViewToobar_" + that.props.dataViewId} className="leopard-gridview-dataloading">
                        <i className="fas fa-spinner fa-pulse" style={{
                            color: "rgb(255, 128, 0)", fontSize: "18px"
                        }}></i>
                        <span className={"toolbar-warming-up-text"}>Warming up backend process...</span>
                    </span>
                    <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                elementId={"Chart_TopBar_Refresh_" + that.props.dataViewId}
                                labelText={"Refresh"} width={250} title={"Refresh"}
                                onClick={(e) => this.refreshOnClick({
                                    e: e,
                                    gridViewId: that.props.dataViewId
                                })}
                                text={"The Refresh button allows you to refresh data and repaint the chart."}/>
                        </span>
                </div>
            </React.Fragment>
        )
    }

    onPointClick = (e) => {
        e.target.select();
        LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = false;
        if (!LDH.IsObjectNull(LeopardStaticUIConfig.Global_DashboardAutoSelectInstance)) {
            LeopardStaticUIConfig.Global_DashboardAutoSelectInstance.option("value", false);
        }
    }

    onSeriesClick = (e) => {
        LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = false;
        if (!LDH.IsObjectNull(LeopardStaticUIConfig.Global_DashboardAutoSelectInstance)) {
            LeopardStaticUIConfig.Global_DashboardAutoSelectInstance.option("value", false);
        }
    }

    onPointSelectionChanged = (e) => {
        if (this.props.useStateStore === null || this.props.useStateStore === false ||
            LDH.IsObjectNull(this.relationshipsLinkedToDataView) ||
            this.relationshipsLinkedToDataView.length === 0) {
            return;
        }
        for (var i = 0; i < this.relationshipsLinkedToDataView.length; i++) {
            let pId = this.relationshipsLinkedToDataView[i].parentDataViewId;
            let cId = this.relationshipsLinkedToDataView[i].childDataViewId;
            let features = this.relationshipsLinkedToDataView[i].interactiveFeatures;
            let dataSourceId = this.relationshipsLinkedToDataView[i].dataSourceId;
            let rowData = LDH.DeepClone(e.target.data);
            let listeners = LeopardStaticUIConfig.Global_DashboardDataViewListeners;
            let childDashboardItemId = cId.split(":")[0];
            if (childDashboardItemId === this.props.dataViewId) continue;

            for (var v = 0; v < listeners.length; v++) {
                if (listeners[v].dashboardItemId === childDashboardItemId) {
                    listeners[v].instance.clearSelection();
                    listeners[v].callback({
                        dataFromSource: rowData,
                        parentDataViewId: pId,
                        childDataViewId: cId,
                        features: features,
                        dataSourceId: dataSourceId,
                        dataViewType: "chart"
                    });
                }
            }
        }
    }

    render() {
        let that = this;
        let chartState = this.props.state.chartState;
        let currentState = this.state;
        if (this.props.useStateStore === null || this.props.useStateStore === false) {
            currentState = chartState.filter(c => {
                return c.dataViewId === this.props.dataViewId;
            });
            if (currentState !== undefined && currentState !== null && currentState.length > 0) {
                currentState = currentState[0];
            }
        }

        if (currentState === undefined || currentState === null || currentState.length === 0 ||
            currentState.customStore === undefined || currentState.customStore === null ||
            currentState.customStore.length === null) {
            return (<div className="leopard-wait-panel">Retrieving data, please wait...</div>);
        }
        let chartDefinition = this.props.definition.chartDefinition;
        let genericChartProps = {
            ref: this.setChartInstance,
            dataSource: currentState.customStore,
            id: this.props.dataViewId,
            className: "leopard-chart-container",
            resolveLabelOverlapping: chartDefinition.resolveLabelOverlapping,
            redrawOnResize: chartDefinition.chartRedrawOnResize,
            negativesAsZeroes: chartDefinition.chartNegativeValuesAsZeroes,
            pointSelectionMode: chartDefinition.chartPointSelectionMode,
            paletteExtensionMode: chartDefinition.chartPaletteExtMode,
            barGroupWidth: chartDefinition.chartBarGroupWitdh,
            barGroupPadding: chartDefinition.chartBarGroupPadding,
            containerBackgroundColor: chartDefinition.chartContainerBgColor,
            maxBubbleSize: chartDefinition.chartMaxBubbleSize,
            minBubbleSize: chartDefinition.chartMinBubbleSize,
            palette: chartDefinition.chartPalette
        };

        let genericZoomAndPanProps = {
            allowMouseWheel: chartDefinition.chartAllowMouseWheel,
            allowTouchGestures: chartDefinition.chartAllowTouchGuestures,
            dragToZoom: chartDefinition.chartDragToZoom
        };

        let genericValueAxisProps = {
            valueType: chartDefinition.valueAxisValueType,
            allowDecimals: chartDefinition.valueAxisAllowDecimals,
            autoBreaksEnabled: chartDefinition.valueAxisEnableAutoBreak,
            maxAutoBreakCount: chartDefinition.valueAxisMaxAutoBreakCount,
            maxValueMargin: chartDefinition.valueAxisMaxValueMargin,
            customizeText: chartDefinition.valueAxisCustomizeText,
            endOnTick: chartDefinition.valueAxisEndOnTick,
            inverted: chartDefinition.valueAxisInvertAxis,
            showZero: chartDefinition.valueAxisShowZero,
            type: chartDefinition.valueAxisType,
            position: chartDefinition.valueAxisPosition
        };

        let genericArgumentAxisProps = {
            valueType: chartDefinition.argAxisValueType,
            allowDecimals: chartDefinition.argAxisAllowDecimals,
            autoBreaksEnabled: chartDefinition.argAxisEnableAutoBreak,
            maxAutoBreakCount: chartDefinition.argAxisMaxAutoBreakCount,
            maxValueMargin: chartDefinition.argAxisMaxValueMargin,
            customizeText: chartDefinition.argAxisCustomizeText,
            endOnTick: chartDefinition.argAxisEndOnTick,
            inverted: chartDefinition.argAxisInvertAxis,
            showZero: chartDefinition.argAxisShowZero,
            type: chartDefinition.argAxisType,
            position: chartDefinition.argAxisPosition
        };

        let genericTooltipProps = {
            argumentFormat: chartDefinition.tooltipArgumentFormat,
            arrowLength: chartDefinition.tooltipArrowLength,
            color: chartDefinition.tooltipColor,
            enabled: chartDefinition.tooltipEnabled,
            location: chartDefinition.tooltipLocation,
            opacity: chartDefinition.tooltipOpacity,
            paddingLeftRight: chartDefinition.tooltipPaddingLeftRight,
            paddingTopBottom: chartDefinition.tooltipPaddingTopBottom
        };

        let genericExportProps = {
            backgroundColor: chartDefinition.chartBgColorForExport,
            margin: chartDefinition.chartMarginForExport,
            enabled: chartDefinition.chartEnableExport,
        };

        let genericScrollbarProps = {
            visible: chartDefinition.chartShowScrollbar,
            position: chartDefinition.chartScrollbarPosition,
            offset: chartDefinition.chartScrollbarOffset
        };

        let genericSeriesBasicProps = {
            argumentField: chartDefinition.seriesArgumentField,
            type: chartDefinition.seriesTypeOfSeries,
            hoverMode: chartDefinition.seriesHoverMode,
            selectionMode: chartDefinition.seriesSelectionMode,
            ignoreEmptyPoints: chartDefinition.seriesIgnoreEmptyPoint,
            axis: chartDefinition.seriesAxisBinding,
            barWidth: chartDefinition.seriesStyleBarWidth,
            barPadding: chartDefinition.seriesStyleBarPadding,
            cornerRadius: chartDefinition.seriesStyleCornerRadius,
            dashStyle: chartDefinition.seriesStyleDashStyle,
            innerColor: chartDefinition.seriesStyleInnerColor,
            selectionStyle: {color: "#ec2e7a"}
        };

        let genericSeriesLabelProps = {
            visible: chartDefinition.labelVisibility,
            alignment: chartDefinition.labelAlignment,
            argumentFormat: chartDefinition.labelArgumentFormat,
            backgroundColor: chartDefinition.labelBgColor,
            format: chartDefinition.labelLabelFormat,
            horizontalOffset: chartDefinition.labelHorizontalOffset,
            verticalOffset: chartDefinition.labelVerticalOffset,
            position: chartDefinition.labelPosition,
            rotationAngle: chartDefinition.labelRotationAngle,
            showForZeroValues: chartDefinition.labelShowForZero
        };

        let genericSeriesPointProps = {
            visible: chartDefinition.pointVisibility,
            color: chartDefinition.pointColor,
            hoverMode: chartDefinition.pointHoverMode,
            selectionMode: chartDefinition.pointSelectionMode,
            size: chartDefinition.pointSizeOfPoint,
            symbol: chartDefinition.pointCustomSymbol
        };

        let genericLegendBasicProps = {
            visible: chartDefinition.legendVisibility,
            columnCount: chartDefinition.legendColumnCount,
            rowCount: chartDefinition.legendRowCount,
            hoverMode: chartDefinition.legendHoverMode,
            markerSize: chartDefinition.legendMarkerSize,
            orientation: chartDefinition.legendOrientation,
            itemTextPosition: chartDefinition.legendItemTextPosition,
            position: chartDefinition.legendPosition,
            itemsAlignment: chartDefinition.legendItemsAlignment,
            horizontalAlignment: chartDefinition.legendHorizontalAlignment,
            verticalAlignment: chartDefinition.legendVerticalAlignment,
            backgroundColor: chartDefinition.legendBgColor,
            columnItemSpacing: chartDefinition.legendColumnItemSpacing,
            rowItemSpacing: chartDefinition.legendRowItemSpacing,
            paddingLeftRight: chartDefinition.legendPaddingLeftRight,
            paddingTopBottom: chartDefinition.legendPaddingTopBottom,
            margin: chartDefinition.legendMargin
        };

        let genericSmallValuesGroupingProps = {
            topCount: chartDefinition.seriesSmallValueGroupingTopCount,
            mode: chartDefinition.seriesSmallValueGroupingMode
        };

        // -------------------- NON-GENERIC CODE START --------------------
        if (this.props.chartType === "bar-chart" || this.props.chartType === "line-chart" ||
            this.props.chartType === "spline-chart" || this.props.chartType === "area-chart" ||
            this.props.chartType === "scatter-chart") {
            return (
                <React.Fragment>
                    {
                        that.chartToolbar(chartDefinition)
                    }
                    <Chart {...genericChartProps} onDrawn={(e) => this.onDrawn(e)} title={{
                        text: chartDefinition.chartTitle,
                        font: {size: chartDefinition.chartTitleFontSize}
                    }}
                           crosshair={{
                               enabled: chartDefinition.chartShowCrosshair,
                               color: chartDefinition.chartCrosshairColor,
                               width: chartDefinition.chartCrosshairWidth,
                               dashStyle: chartDefinition.chartCrosshairStyle,
                               label: {
                                   visible: true,
                                   backgroundColor: "#949494",
                                   font: {
                                       color: "#fff",
                                       size: 12,
                                   }
                               }
                           }}
                           onLegendClick={(e) => this.onLegendClick(e)}
                           onPointClick={(e) => this.onPointClick(e)}
                           onSeriesClick={(e) => this.onSeriesClick(e)}
                           onPointSelectionChanged={(e) => this.onPointSelectionChanged(e)}>
                        {this.renderCommonSeriesSettingsForBarChart(chartDefinition)}
                        <ZoomAndPan {...genericZoomAndPanProps} argumentAxis={"both"}/>
                        <Animation enabled={false} duration={1000}></Animation>
                        <ValueAxis {...genericValueAxisProps} visualRangeUpdateMode={"keep"}>
                            <Label customizeText={(e) => LeopardChartEngine.barChartConfiguration_customizeText(e,
                                genericValueAxisProps.customizeText)}/>
                            {this.renderSeriesHighAverageForBarChart(chartDefinition)}
                            {this.renderSeriesLowAverageForBarChart(chartDefinition)}
                        </ValueAxis>
                        <ArgumentAxis {...genericArgumentAxisProps} visualRangeUpdateMode={"keep"} defaultVisualRange={
                            LDH.IsObjectNull(chartDefinition.defaultVisualRange) || chartDefinition.defaultVisualRange <= 0
                                ? null : {length: chartDefinition.defaultVisualRange}}>
                            <Label overlappingBehavior={chartDefinition.argAxisOverlappingBehavior}
                                   customizeText={(e) => LeopardChartEngine.barChartConfiguration_customizeText(e,
                                       genericArgumentAxisProps.customizeText)} wordWrap={"none"}/>
                        </ArgumentAxis>
                        <Tooltip {...genericTooltipProps} />
                        <ScrollBar {...genericScrollbarProps} />
                        {
                            chartDefinition.numberOfSeriesOnChart.map(function (item, index) {
                                let valueFieldSplit = chartDefinition.seriesValueField.split("|");
                                let argumentFieldSplit = chartDefinition.seriesArgumentField.split("|");

                                return (
                                    <Series {...genericSeriesBasicProps} key={item.id} name={item.name}
                                            valueField={LDH.IsObjectNull(valueFieldSplit[index]) ? ""
                                                : valueFieldSplit[index].toString().trim()}
                                            argumentField={LDH.IsObjectNull(argumentFieldSplit[index]) ? ""
                                                : argumentFieldSplit[index].toString().trim()}>
                                        <Label {...genericSeriesLabelProps} />
                                        <Point {...genericSeriesPointProps} />
                                    </Series>
                                )
                            })
                        }
                        <Legend {...genericLegendBasicProps} />
                        <Export {...genericExportProps} />
                    </Chart>
                </React.Fragment>
            );
        }

        if (this.props.chartType === "doughnut-chart") {
            return (
                <React.Fragment>
                    {
                        that.chartToolbar(chartDefinition)
                    }
                    <PieChart {...genericChartProps} onDrawn={(e) => this.onDrawn(e)}
                              onLegendClick={(e) => this.onLegendClick(e)}
                              onPointClick={(e) => this.onPointClick(e)}
                              onSeriesClick={(e) => this.onSeriesClick(e)}
                              onPointSelectionChanged={(e) => this.onPointSelectionChanged(e)}
                              type={'doughnut'}>
                        <Animation enabled={false} duration={1000}></Animation>
                        <ZoomAndPan {...genericZoomAndPanProps} />
                        <Tooltip {...genericTooltipProps} />
                        <ScrollBar {...genericScrollbarProps} />
                        <Series {...genericSeriesBasicProps}>
                            <Label {...genericSeriesLabelProps}
                                   overlappingBehavior={chartDefinition.argAxisOverlappingBehavior}
                                   wordWrap={"none"}>
                                <Connector visible={true} width={1}/>
                            </Label>
                            <Point {...genericSeriesPointProps} />
                            <SmallValuesGrouping {...genericSmallValuesGroupingProps} />
                        </Series>
                        <Legend {...genericLegendBasicProps} />
                        <Export {...genericExportProps} />
                    </PieChart>
                </React.Fragment>
            );
        }

        // -------------------- NON-GENERIC CODE END ---------------------

        return null;
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

const SendDataToReducer = (dispatch) => {
    return {
        InitChartData: (data, id) => {
            dispatch(InitChartData(data, id));
        },
        InitCustomStoreForChart: (store, id) => {
            dispatch(InitCustomStoreForChart(store, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardChartEngine);