import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, SelectBox, NumberBox, ColorBox, TextArea} from 'devextreme-react';
import List from 'devextreme-react/list';
import {TextBox, Button as TextBoxButton} from 'devextreme-react/text-box';
import Box, {Item} from 'devextreme-react/box';
import TabPanel from 'devextreme-react/tab-panel';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import {Validator, RequiredRule} from 'devextreme-react/validator';
import $ from "jquery";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardChartDesigner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewDataButtonText: "View Data",
            selectedIndex: 0,
            chartDefinition: {}
        };

        this.uiObjectInstance = [];
        this.numberOfSeriesForChartText = "";

        this.chartPalette = [{
            id: "Bright",
            name: "Bright"
        }, {
            id: "Harmony Light",
            name: "Harmony Light"
        }, {
            id: "Ocean",
            name: "Ocean"
        }, {
            id: "Pastel",
            name: "Pastel"
        }, {
            id: "Soft",
            name: "Soft"
        }, {
            id: "Soft Pastel",
            name: "Soft Pastel"
        }, {
            id: "Vintage",
            name: "Vintage"
        }, {
            id: "Violet",
            name: "Violet"
        }, {
            id: "Carmine",
            name: "Carmine"
        }, {
            id: "Dark Moon",
            name: "Dark Moon"
        }, {
            id: "Dark Violet",
            name: "Dark Violet"
        }, {
            id: "Green Mist",
            name: "Green Mist"
        }, {
            id: "Soft Blue",
            name: "Soft Blue"
        }, {
            id: "Material",
            name: "Material"
        }, {
            id: "Office",
            name: "Office"
        }];

        this.tabPanelDataSource = [{
            tabId: 0,
            tabTitle: 'Chart'
        }, {
            tabId: 1,
            tabTitle: "Series"
        }, {
            tabId: 2,
            tabTitle: "Legends"
        }, {
            tabId: 3,
            tabTitle: "Data"
        }];

        this.typeOfSeries = [{
            id: "area",
            name: "Area"
        }, {
            id: "bar",
            name: "Bar"
        }, {
            id: "fullstackedarea",
            name: "Full stacked area"
        }, {
            id: "fullstackedbar",
            name: "Full stacked bar"
        }, {
            id: "fullstackedline",
            name: "Full stacked line"
        }, {
            id: "fullstackedspline",
            name: "Full stacked spline"
        }, {
            id: "fullstackedsplinearea",
            name: "Full stacked spline area"
        }, {
            id: "line",
            name: "Line"
        }, {
            id: "scatter",
            name: "Scatter"
        }, {
            id: "spline",
            name: "Spline"
        }, {
            id: "splinearea",
            name: "Spline area"
        }, {
            id: "stackedarea",
            name: "Stacked area"
        }, {
            id: "stackedbar",
            name: "Stacked bar"
        }, {
            id: "stackedline",
            name: "Stacked line"
        }, {
            id: "stackedspline",
            name: "Stacked spline"
        }, {
            id: "stackedsplinearea",
            name: "Stacked spline area"
        }, {
            id: "steparea",
            name: "Step area"
        }, {
            id: "stepline",
            name: "Step line"
        }];

        this.smallValuesGroupingMode = [{
            id: "none",
            name: "None"
        }, {
            id: "smallValueThreshold",
            name: "Small value threshold"
        }, {
            id: "topN",
            name: "Top N"
        }];

        this.seriesHoverMode = [{
            id: "none",
            name: "None"
        }, {
            id: "onlyPoint",
            name: "Only Point"
        }, {
            id: "allSeriesPoints",
            name: "All Series Points"
        }, {
            id: "allArgumentPoints",
            name: "All Argument Points"
        }];

        this.legendHoverMode = [{
            id: "none",
            name: "None"
        }, {
            id: "excludePoints",
            name: "Exclude Points"
        }, {
            id: "includePoints",
            name: "Include Points"
        }];

        this.selectionYesNo = [{
            id: false,
            name: "No"
        }, {
            id: true,
            name: "Yes"
        }];

        this.lineStyle = [{
            id: "solid",
            name: "Solid line",
        }, {
            id: "dash",
            name: "Dash line",
        }, {
            id: "dot",
            name: "Dotted line",
        }, {
            id: "longDash",
            name: "Long dash line",
        }]

        this.valueAxisType = [{
            id: undefined,
            name: "None"
        }, {
            id: "continuous",
            name: "continuous"
        }, {
            id: "discrete",
            name: "Discrete"
        }, {
            id: "logarithmic",
            name: "Logarithmic"
        }];

        this.tooltipLocation = [{
            id: "center",
            name: "center"
        }, {
            id: "edge",
            name: "edge"
        }];

        this.selectionShowHide = [{
            id: false,
            name: "Hide"
        }, {
            id: true,
            name: "Show"
        }];

        this.selectionDateRange = [{
            id: "7days",
            name: "Last 7 days"
        }, {
            id: "14days",
            name: "Last 14 days"
        }, {
            id: "30days",
            name: "Last 30 days"
        }, {
            id: "lastMonth",
            name: "Last Month"
        }, {
            id: "currentMonth",
            name: "Current Month"
        }];

        this.horizontalAlignmentOption = [{
            id: "left",
            name: "Left"
        }, {
            id: "center",
            name: "Center"
        }, {
            id: "right",
            name: "Right"
        }];

        this.verticalAlignmentOption = [{
            id: "top",
            name: "Top"
        }, {
            id: "bottom",
            name: "Bottom"
        }];

        this.resolveLabelOverlappingOption = [{
            id: "hide",
            name: "Hide"
        }, {
            id: "none",
            name: "None"
        }, {
            id: "stack",
            name: "Stack"
        }];

        this.itemTextPosition = [{
            id: "bottom",
            name: "Bottom"
        }, {
            id: "left",
            name: "Left"
        }, {
            id: "top",
            name: "Top"
        }, {
            id: "right",
            name: "Right"
        }];

        this.overlappingBehavior = [{
            id: "stagger",
            name: "Stagger"
        }, {
            id: "rotate",
            name: "Rotate"
        }, {
            id: "hide",
            name: "Hide"
        }, {
            id: "none",
            name: "None"
        }];

        this.valueAxisValueType = [{
            id: "datetime",
            name: "Date Time"
        }, {
            id: "numeric",
            name: "Numeric"
        }, {
            id: "string",
            name: "String"
        }];

        this.orientation = [{
            id: "horizontal",
            name: "Horizontal"
        }, {
            id: "vertical",
            name: "Vertical"
        }];

        this.legendPosition = [{
            id: "inside",
            name: "Inside"
        }, {
            id: "outside",
            name: "Outside"
        }];

        this.multiOrSingleMode = [{
            id: "single",
            name: "Single"
        }, {
            id: "multiple",
            name: "Multiple"
        }];

        this.paletteExtensionModeOption = [{
            id: "alternate",
            name: "alternate"
        }, {
            id: "blend",
            name: "blend"
        }, {
            id: "extrapolate",
            name: "extrapolate"
        }];

        this.dashStyleOption = [{
            id: "solid",
            name: "Solid"
        }, {
            id: "dash",
            name: "Dash"
        }, {
            id: "dot",
            name: "Dot"
        }, {
            id: "longDash",
            name: "Long Dash"
        }];

        this.customSymbol = [{
            id: "circle",
            name: "Circle"
        }, {
            id: "cross",
            name: "Cross"
        }, {
            id: "polygon",
            name: "Polygon"
        }, {
            id: "square",
            name: "Square"
        }, {
            id: "triangleDown",
            name: "Triangle Down"
        }, {
            id: "triangleUp",
            name: "Triangle Up"
        }];
    }

    componentDidMount = () => {
        let definition = this.props.definition.chartDefinition;
        this.setState({chartDefinition: definition});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;
    };

    disablePreviewButton = () => {
        this.uiObjectInstance["buttonPreview"].option("disabled", true);
    }

    previewButtonOnClick = (callback) => {
        let definition = this.state.chartDefinition;
        this.props.chartDefinitionUpdateForPreview({
            definition: definition
        });
        if (callback !== undefined && callback !== null) {
            callback();
        }
    };

    saveDataButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;
        let definition = that.state.chartDefinition;
        let dataViewId = that.props.dataViewId;
        $(".loading-progress-configurechart").css("visibility", "visible");

        setTimeout(function () {
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            LeopardAjaxHelper.UpdateChartDefintion(userId, organizationId,
                dataViewId, definition, function () {
                    LRH.ShowToast("Your chart configuration has been successfully saved.", "success", 5000);
                    $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
                    $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                    }
                    $(".loading-progress-configurechart").css("visibility", "hidden");
                });
        }, 100);
    };

    cancelButtonOnClick = (e) => {
        this.props.chartDesignerButtonOnClick(e);
    };

    tabItemTitleRender = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    updateChartDefinition = (data) => {
        let inputValue = data.e.value;
        let chartDef = this.state.chartDefinition;
        let clonedChartDef = LDH.DeepClone(chartDef);
        clonedChartDef[data.prop] = inputValue;

        if (data.tabInfo.settingsName === "enableAutoRefresh") {
            if (data.e.value === true) {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
            } else {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
            }
        }

        if (data.supportPreview === false) {
            this.disablePreviewButton();
        }

        this.setState({chartDefinition: clonedChartDef}, function () {
            chartDef = this.state.chartDefinition;
        });
    };

    getDefaultValue = (name, defaultValue) => {
        if (this.state.chartDefinition[name] === undefined) {
            return defaultValue;
        }
        return this.state.chartDefinition[name];
    };

    showOrHideSettings = (settingsName) => {
        let show = false;
        let chartType = this.props.chartType;

        if (settingsName === "chartTitle") show = true;
        if (settingsName === "chartEnableExport") show = true;
        if (settingsName === "seriesValueField") show = true;
        if (settingsName === "seriesArgumentField") show = true;
        if (settingsName === "chartResolveLabelOverlapping") show = true;
        if (settingsName === "chartAllowTouchGuestures") show = true;
        if (settingsName === "valueAxisValueType") show = true;
        if (settingsName === "valueAxisInvertAxis") show = true;
        if (settingsName === "valueAxisPosition") show = true;
        if (settingsName === "argAxisValueType") show = true;
        if (settingsName === "argAxisInvertAxis") show = true;
        if (settingsName === "argAxisPosition") show = true;
        if (settingsName === "tooltipEnabled") show = true;
        if (settingsName === "seriesIgnoreEmptyPoint") show = true;
        if (settingsName === "numberOfSeriesOnChart") show = true;
        if (settingsName === "legendVisibility") show = true;
        if (settingsName === "legendOrientation") show = true;
        if (settingsName === "legendItemTextPosition") show = true;
        if (settingsName === "legendPosition") show = true;
        if (settingsName === "legendItemsAlignment") show = true;
        if (settingsName === "legendHorizontalAlignment") show = true;
        if (settingsName === "legendVerticalAlignment") show = true;
        if (settingsName === "tooltipArrowLength") show = true;
        if (settingsName === "tooltipOpacity") show = true;
        if (settingsName === "tooltipPaddingLeftRight") show = true;
        if (settingsName === "tooltipPaddingTopBottom") show = true;
        if (settingsName === "chartMarginForExport") show = true;
        if (settingsName === "legendColumnCount") show = true;
        if (settingsName === "legendRowCount") show = true;
        if (settingsName === "legendColumnItemSpacing") show = true;
        if (settingsName === "legendRowItemSpacing") show = true;
        if (settingsName === "legendPaddingLeftRight") show = true;
        if (settingsName === "legendPaddingTopBottom") show = true;
        if (settingsName === "legendMargin") show = true;
        if (settingsName === "tooltipColor") show = true;
        if (settingsName === "chartBgColorForExport") show = true;
        if (settingsName === "legendBgColor") show = true;
        if (settingsName === "labelVisibility") show = true;
        if (settingsName === "labelRotationAngle") show = true;
        if (settingsName === "labelPosition") show = true;
        if (settingsName === "labelBgColor") show = true;
        if (settingsName === "chartPalette") show = true;
        if (settingsName === "dateRangeValue") show = true;
        if (settingsName === "dateRangeField") show = true;
        if (settingsName === "customQueryAttributes") show = true;
        if (settingsName === "oDataQueryForLinkedView") show = true;
        if (settingsName === "valueAxisShowZero") show = true;
        if (settingsName === "argAxisShowZero") show = true;
        if (settingsName === "argAxisOverlappingBehavior") show = true;
        if (settingsName === "chartShowScrollbar") show = true;
        if (settingsName === "chartScrollbarPosition") show = true;
        if (settingsName === "chartTitleFontSize") show = true;
        if (settingsName === "enableAutoRefresh") show = true;
        if (settingsName === "autoRefreshInterval") show = true;
        if (settingsName === "showAutoRefreshSwitch") show = true;
        if (settingsName === "clientSideQueryEditor") show = true;

        if (chartType === "bar-chart" || chartType === "line-chart" || chartType === "spline-chart" ||
            chartType === "area-chart" || chartType === "scatter-chart") {
            if (settingsName === "seriesTypeOfSeries") show = true;
            if (settingsName === "seriesStyleDashStyle") show = true;
            if (settingsName === "chartBarGroupWitdh") show = true;
            if (settingsName === "chartBarGroupPadding") show = true;
            if (settingsName === "pointSizeOfPoint") show = true;
            if (settingsName === "pointCustomSymbol") show = true;
            if (settingsName === "pointVisibility") show = true;
            if (settingsName === "seriesStyleBarWidth") show = true;
            if (settingsName === "seriesStyleBarPadding") show = true;
            if (settingsName === "seriesStyleCornerRadius") show = true;
            if (settingsName === "pointColor") show = true;
            if (settingsName === "argAxisCustomizeText") show = true;
            if (settingsName === "valueAxisCustomizeText") show = true;
            if (settingsName === "chartNegativeValuesAsZeroes") show = true;
            if (settingsName === "labelShowForZero") show = true;
            if (settingsName === "labelAlignment") show = true;
            if (settingsName === "valueAxisHighAverage") show = true;
            if (settingsName === "valueAxisLowAverage") show = true;
            if (settingsName === "valueAxisHighAverageText") show = true;
            if (settingsName === "valueAxisLowAverageText") show = true;
            if (settingsName === "chartShowCrosshair") show = true;
            if (settingsName === "chartCrosshairWidth") show = true;
            if (settingsName === "chartCrosshairColor") show = true;
            if (settingsName === "chartCrosshairStyle") show = true;
            if (settingsName === "defaultVisualRange") show = true;
        }

        if (chartType === "doughnut-chart") {
            if (settingsName === "seriesSmallValueGroupingMode") show = true;
            if (settingsName === "seriesSmallValueGroupingTopCount") show = true;
        }
        return show;
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    numberOfSeriesForChartTextValueOnChanged = (e) => {
        this.numberOfSeriesForChartText = e.value;
    }

    renderSettingsTextBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <TextBox maxLength={50}
                                         defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                         ref={(e) => this.setInputFieldInstance({e: e, input: data.settingsName})}
                                         onValueChanged={(e) => this.updateChartDefinition({
                                             e: e, tabInfo: data, prop: data.settingsName,
                                             supportPreview: supportPreview
                                         })} showClearButton={true} placeholder={data.placeholder}>
                                    <Validator>
                                        <RequiredRule type="custom"
                                                      validationCallback={(e) => this.customValidationRuleCallback({
                                                          e: e, input: data.settingsName, rules: [{rule: "safeinput"}]
                                                      })}/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    renderSettingsTextAreaWithButtonField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <TextArea defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                          ref={(e) => this.setInputFieldInstance({e: e, input: data.settingsName})}
                                          id={"Textbox_" + data.settingsName} height={"140px"}
                                          onValueChanged={(e) => this.updateChartDefinition({
                                              e: e, tabInfo: data, prop: data.settingsName,
                                              supportPreview: supportPreview
                                          })} placeholder={data.placeholder}>
                                    {
                                        data.validator === false ? "" :
                                            <Validator>
                                                <RequiredRule type="custom"
                                                              validationCallback={(e) => this.customValidationRuleCallback({
                                                                  e: e,
                                                                  input: data.settingsName,
                                                                  rules: [{rule: "safeinput"}]
                                                              })}/>
                                            </Validator>
                                    }
                                    {
                                        data.hasMiniButton === false ? "" :
                                            <TextBoxButton name={data.settingsName + "_MiniButton"} location={'after'}
                                                           options={{
                                                               icon: data.buttonIcon,
                                                               type: 'default',
                                                               onClick: () => {
                                                                   data.onClick();
                                                               }
                                                           }}
                                            />
                                    }
                                </TextArea>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    renderSettingsSelectBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <SelectBox dataSource={data.dataSource} displayExpr={'name'} valueExpr={'id'}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateChartDefinition({
                                               e: e, tabInfo: data, prop: data.settingsName,
                                               supportPreview: supportPreview
                                           })}/>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    numberOfSeriesOnChartTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    renderSettingsListField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div style={{marginBottom: "5px"}}>
                                <TextBox maxLength={50} showClearButton={true} placeholder={data.placeholder}
                                         ref={(e) => this.setInputFieldInstance({
                                             e: e,
                                             input: data.settingsName + "_AddInput"
                                         })}
                                         onValueChanged={(e) => this.numberOfSeriesForChartTextValueOnChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom"
                                                      validationCallback={(e) => this.customValidationRuleCallback({
                                                          e: e, input: data.settingsName, rules: [{rule: "safeinput"}]
                                                      })}/>
                                    </Validator>
                                    <TextBoxButton name={data.settingsName + "_MiniButton"}
                                                   location={'after'}
                                                   options={{
                                                       icon: "fas fa-plus",
                                                       type: 'default',
                                                       onClick: () => {
                                                           if (LDH.IsValueEmpty(this.numberOfSeriesForChartText)) {
                                                               return;
                                                           }
                                                           let dataSource = this.getDefaultValue(data.dataSource, []);
                                                           dataSource.push({
                                                               id: "series_" + (dataSource.length + 1),
                                                               name: this.numberOfSeriesForChartText
                                                           });
                                                           this.uiObjectInstance[data.settingsName + "_AddInput"].option("value", "");
                                                           this.uiObjectInstance[data.settingsName].reload();
                                                       }
                                                   }}
                                    />
                                </TextBox>
                            </div>
                            <div>
                                <List allowItemDeleting={true} activeStateEnabled={false}
                                      noDataText={"There is no series on the chart"}
                                      ref={(e) => this.setInputFieldInstance({e: e, input: data.settingsName})}
                                      hoverStateEnabled={false} focusStateEnabled={false}
                                      dataSource={this.getDefaultValue(data.dataSource, [])}
                                      itemRender={this.numberOfSeriesOnChartTemplate}/>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    renderSettingsNumberBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <NumberBox min={data.minValue} max={data.maxValue} showSpinButtons={true}
                                           showClearButton={true} format={"#"}
                                           defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                           onValueChanged={(e) => this.updateChartDefinition({
                                               e: e, tabInfo: data, prop: data.settingsName,
                                               supportPreview: supportPreview
                                           })}/>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    renderSettingsColorBoxField = (data) => {
        let supportPreview = (data.supportPreview === undefined || data.supportPreview);
        let additionalClass = LDH.IsValueEmpty(data.additionalClass) ? "" : data.additionalClass;
        return (
            <React.Fragment>
                {
                    this.showOrHideSettings(data.settingsName) === false ? "" :
                        <div className={"leopard-settings-panel-contentgroup " + additionalClass}>
                            <div className="leopard-settings-panel-content-label">
                                <span>{data.displayName}</span>
                                <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"} style={{
                                    display: supportPreview ? "none" : "inline"
                                }}></i>
                            </div>
                            <div>
                                <ColorBox defaultValue={this.getDefaultValue(data.settingsName, data.defaultValue)}
                                          onValueChanged={(e) => this.updateChartDefinition({
                                              e: e, tabInfo: data, prop: data.settingsName,
                                              supportPreview: supportPreview
                                          })}/>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        if (data.tabId === 0) {
            let showXAxisValue = this.props.chartType !== "doughnut-chart";
            let showYAxisValue = this.props.chartType !== "doughnut-chart";

            let definition = this.state.chartDefinition;
            setTimeout(function () {
                if (!LDH.IsObjectNull(definition.enableAutoRefresh) && definition.enableAutoRefresh === true) {
                    $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
                } else {
                    $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
                }
            }, 100)

            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Basic</div>
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "chartTitle",
                                            defaultValue: undefined,
                                            displayName: "Chart title:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "chartTitleFontSize",
                                            defaultValue: 20,
                                            displayName: "Font size for chart title:",
                                            minValue: 8,
                                            maxValue: 40
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartPalette",
                                            defaultValue: this.chartPalette[5].id,
                                            displayName: "Palette:",
                                            dataSource: this.chartPalette
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartEnableExport",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Enable export feature:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartPointSelectionMode",
                                            defaultValue: this.multiOrSingleMode[0].id,
                                            displayName: "Point selection mode:",
                                            dataSource: this.multiOrSingleMode
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartSeriesSelectionMode",
                                            defaultValue: this.multiOrSingleMode[0].id,
                                            displayName: "Series selection mode:",
                                            dataSource: this.multiOrSingleMode
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartRedrawOnResize",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Redraw on resize:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartResolveLabelOverlapping",
                                            defaultValue: this.resolveLabelOverlappingOption[1].id,
                                            displayName: "Resolve label Overlapping:",
                                            dataSource: this.resolveLabelOverlappingOption
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartNegativeValuesAsZeroes",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Show negatives as zeroes:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartAllowMouseWheel",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Allow mouse wheel:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartAllowTouchGuestures",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Allow touch guestures:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartDragToZoom",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Drag to zoom:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "defaultVisualRange",
                                            defaultValue: 20,
                                            displayName: "Number of visual points:",
                                            minValue: 0,
                                            maxValue: 99999,
                                            supportPreview: false
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Crosshair</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartShowCrosshair",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Show crosshair:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "chartCrosshairWidth",
                                            defaultValue: 2,
                                            displayName: "Crosshair width:",
                                            minValue: 1,
                                            maxValue: 10
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "chartCrosshairColor",
                                            defaultValue: "#FE8000",
                                            displayName: "Crosshair color:"
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartCrosshairStyle",
                                            defaultValue: this.lineStyle[0].id,
                                            displayName: "Crosshair style:",
                                            dataSource: this.lineStyle
                                        })
                                    }
                                </div>
                                <div className="leopard-settings-panel-cell" style={{marginTop: "1px"}}>
                                    <div className="leopard-settings-panel-title">
                                        <span>Real-time update</span>
                                    </div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "enableAutoRefresh",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Enable auto-refresh:",
                                            dataSource: this.selectionYesNo,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "autoRefreshInterval",
                                            defaultValue: 30,
                                            displayName: "Auto-refresh interval (seconds):",
                                            minValue: 5,
                                            maxValue: 9999,
                                            additionalClass: "auto-refresh-feature",
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "showAutoRefreshSwitch",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Show auto-refresh switch:",
                                            dataSource: this.selectionYesNo,
                                            additionalClass: "auto-refresh-feature",
                                            supportPreview: false
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300} visible={showXAxisValue}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">X-Axis Value</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "argAxisValueType",
                                            defaultValue: this.valueAxisValueType[2].id,
                                            displayName: "Value type:",
                                            dataSource: this.valueAxisValueType
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "argAxisCustomizeText",
                                            defaultValue: undefined,
                                            displayName: "Customize text:",
                                            placeholder: "#value# must be included"
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "argAxisAllowDecimals",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Allow decimals:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "argAxisEnableAutoBreak",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Enable auto-break:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "argAxisMaxAutoBreakCount",
                                            defaultValue: 4,
                                            displayName: "Maximum auto-break count:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "argAxisMaxValueMargin",
                                            defaultValue: undefined,
                                            displayName: "Maximum value margin:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "argAxisEndOnTick",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "End on tick:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "argAxisInvertAxis",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Invert axis:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "argAxisShowZero",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Show zero:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "argAxisType",
                                            defaultValue: this.valueAxisType[0].id,
                                            displayName: "Axis type:",
                                            dataSource: this.valueAxisType
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "argAxisPosition",
                                            defaultValue: this.itemTextPosition[0].id,
                                            displayName: "Position:",
                                            dataSource: this.itemTextPosition
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "argAxisOverlappingBehavior",
                                            defaultValue: this.overlappingBehavior[0].id,
                                            displayName: "Overlapping mode:",
                                            dataSource: this.overlappingBehavior
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300} visible={showYAxisValue}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Y-Axis Value</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "valueAxisValueType",
                                            defaultValue: this.valueAxisValueType[2].id,
                                            displayName: "Value type:",
                                            dataSource: this.valueAxisValueType
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "valueAxisCustomizeText",
                                            defaultValue: undefined,
                                            displayName: "Customize text:",
                                            placeholder: "#value# must be included"
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "valueAxisAllowDecimals",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Allow decimals:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "valueAxisEnableAutoBreak",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Enable auto-break:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "valueAxisMaxAutoBreakCount",
                                            defaultValue: 4,
                                            displayName: "Maximum auto-break count:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "valueAxisMaxValueMargin",
                                            defaultValue: undefined,
                                            displayName: "Maximum value margin:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "valueAxisEndOnTick",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "End on tick:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "valueAxisInvertAxis",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Invert axis:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "valueAxisShowZero",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Show zero:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "valueAxisType",
                                            defaultValue: this.valueAxisType[0].id,
                                            displayName: "Axis type:",
                                            dataSource: this.valueAxisType
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "valueAxisPosition",
                                            defaultValue: this.itemTextPosition[1].id,
                                            displayName: "Position:",
                                            dataSource: this.itemTextPosition
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "valueAxisHighAverage",
                                            defaultValue: undefined,
                                            displayName: "High average:",
                                            minValue: -999999,
                                            maxValue: 999999,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "valueAxisHighAverageText",
                                            defaultValue: "High Average",
                                            displayName: "High average custom text:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "valueAxisLowAverage",
                                            defaultValue: undefined,
                                            displayName: "Low average:",
                                            minValue: -999999,
                                            maxValue: 999999,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "valueAxisLowAverageText",
                                            defaultValue: "Low Average",
                                            displayName: "Low average custom text:",
                                            placeholder: undefined
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Tooltip</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "tooltipEnabled",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Enable tooltips:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "tooltipArgumentFormat",
                                            defaultValue: undefined,
                                            displayName: "Argument format:",
                                            placeholder: "e.g. decimal, currency, string, longDate..."
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "tooltipArrowLength",
                                            defaultValue: 10,
                                            displayName: "Arrow length:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "tooltipColor",
                                            defaultValue: "#FFFFFF",
                                            displayName: "Tooltip color:"
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "tooltipLocation",
                                            defaultValue: this.tooltipLocation[0].id,
                                            displayName: "Location:",
                                            dataSource: this.tooltipLocation
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "tooltipOpacity",
                                            defaultValue: undefined,
                                            displayName: "Opacity:",
                                            minValue: 0,
                                            maxValue: 1
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "tooltipPaddingLeftRight",
                                            defaultValue: 10,
                                            displayName: "Padding left right:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "tooltipPaddingTopBottom",
                                            defaultValue: 10,
                                            displayName: "Padding top bottom:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Style</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartPaletteExtMode",
                                            defaultValue: this.paletteExtensionModeOption[1].id,
                                            displayName: "Palette extension mode:",
                                            dataSource: this.paletteExtensionModeOption
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "chartBarGroupWitdh",
                                            defaultValue: null,
                                            displayName: "Bar group width:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "chartBarGroupPadding",
                                            defaultValue: 0.3,
                                            displayName: "Bar group padding:",
                                            minValue: 0,
                                            maxValue: 1
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "chartContainerBgColor",
                                            defaultValue: "#FFFFFF",
                                            displayName: "Container background color:"
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "chartMaxBubbleSize",
                                            defaultValue: 0.2,
                                            displayName: "Maximum bubble size:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "chartMinBubbleSize",
                                            defaultValue: 12,
                                            displayName: "Minimum bubble size:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "chartBgColorForExport",
                                            defaultValue: "#FFFFFF",
                                            displayName: "Background color for export:"
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "chartMarginForExport",
                                            defaultValue: 10,
                                            displayName: "Margin for export:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartShowScrollbar",
                                            defaultValue: this.selectionYesNo[0].id,
                                            displayName: "Show scrollbar:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "chartScrollbarPosition",
                                            defaultValue: this.itemTextPosition[0].id,
                                            displayName: "Scrollbar position:",
                                            dataSource: this.itemTextPosition
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "chartScrollbarOffset",
                                            defaultValue: 5,
                                            displayName: "Scrollbar offset:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                        <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting does not support preview.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 1) {
            let showSeriesPoint = this.props.chartType !== "doughnut-chart";
            let showSeriesStyle = this.props.chartType !== "doughnut-chart";

            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Basic</div>
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "seriesArgumentField",
                                            defaultValue: undefined,
                                            displayName: "Specify a column for x-axis value:",
                                            placeholder: "Specify a data column"
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "seriesValueField",
                                            defaultValue: undefined,
                                            displayName: "Specify a column for y-axis value:",
                                            placeholder: "Specify a data column"
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "seriesTypeOfSeries",
                                            defaultValue: this.typeOfSeries[1].id,
                                            displayName: "Type of series:",
                                            dataSource: this.typeOfSeries,
                                            supportPreview: false
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "seriesHoverMode",
                                            defaultValue: this.seriesHoverMode[1].id,
                                            displayName: "Hover mode:",
                                            dataSource: this.seriesHoverMode
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "seriesSelectionMode",
                                            defaultValue: this.seriesHoverMode[1].id,
                                            displayName: "Selection mode:",
                                            dataSource: this.seriesHoverMode
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "seriesIgnoreEmptyPoint",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Ignore empty points:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                    {
                                        this.renderSettingsListField({
                                            settingsName: "numberOfSeriesOnChart",
                                            displayName: "Chart series:",
                                            additionalClass: "numberOfSeriesOnChart",
                                            dataSource: "numberOfSeriesOnChart"
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "seriesAxisBinding",
                                            defaultValue: undefined,
                                            displayName: "Axis binding:",
                                            placeholder: undefined
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "seriesSmallValueGroupingMode",
                                            defaultValue: this.smallValuesGroupingMode[2].id,
                                            displayName: "Grouping mode:",
                                            dataSource: this.smallValuesGroupingMode
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "seriesSmallValueGroupingTopCount",
                                            defaultValue: 8,
                                            displayName: "Grouping top count:",
                                            minValue: 0,
                                            maxValue: 9999
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Label</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "labelVisibility",
                                            defaultValue: this.selectionShowHide[1].id,
                                            displayName: "Label visibility:",
                                            dataSource: this.selectionShowHide
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "labelAlignment",
                                            defaultValue: this.horizontalAlignmentOption[0].id,
                                            displayName: "Alignment:",
                                            dataSource: this.horizontalAlignmentOption
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "labelArgumentFormat",
                                            defaultValue: undefined,
                                            displayName: "Argument format:",
                                            placeholder: "e.g. decimal, currency, string, longDate..."
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "labelBgColor",
                                            defaultValue: undefined,
                                            displayName: "Background color:"
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "labelLabelFormat",
                                            defaultValue: undefined,
                                            displayName: "Label format:",
                                            placeholder: "e.g. decimal, currency, string, longDate..."
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "labelHorizontalOffset",
                                            defaultValue: 0,
                                            displayName: "Horizontal offset:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "labelVerticalOffset",
                                            defaultValue: 0,
                                            displayName: "Vertical offset:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "labelPosition",
                                            defaultValue: this.legendPosition[1].id,
                                            displayName: "Label position:",
                                            dataSource: this.legendPosition
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "labelRotationAngle",
                                            defaultValue: 0,
                                            displayName: "Rotation angle:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "labelShowForZero",
                                            defaultValue: this.selectionYesNo[1].id,
                                            displayName: "Show for zero values:",
                                            dataSource: this.selectionYesNo
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300} visible={showSeriesPoint}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Point</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "pointVisibility",
                                            defaultValue: this.selectionShowHide[1].id,
                                            displayName: "Point visibility:",
                                            dataSource: this.selectionShowHide
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "pointColor",
                                            defaultValue: undefined,
                                            displayName: "Color:"
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "pointHoverMode",
                                            defaultValue: this.seriesHoverMode[1].id,
                                            displayName: "Hover mode:",
                                            dataSource: this.seriesHoverMode
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "pointSelectionMode",
                                            defaultValue: this.seriesHoverMode[1].id,
                                            displayName: "Selection mode:",
                                            dataSource: this.seriesHoverMode
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "pointSizeOfPoint",
                                            defaultValue: 12,
                                            displayName: "Size of point:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "pointCustomSymbol",
                                            defaultValue: this.customSymbol[0].id,
                                            displayName: "Custom symbol:",
                                            dataSource: this.customSymbol
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300} visible={showSeriesStyle}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Style</div>
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "seriesStyleBarWidth",
                                            defaultValue: undefined,
                                            displayName: "Bar width:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "seriesStyleBarPadding",
                                            defaultValue: undefined,
                                            displayName: "Bar padding:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "seriesStyleCornerRadius",
                                            defaultValue: 0,
                                            displayName: "Corner radius:",
                                            minValue: 0,
                                            maxValue: 100
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "seriesStyleDashStyle",
                                            defaultValue: this.dashStyleOption[0].id,
                                            displayName: "Dash style:",
                                            dataSource: this.dashStyleOption
                                        })
                                    }
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "seriesStyleInnerColor",
                                            defaultValue: undefined,
                                            displayName: "Inner color:"
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                        <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting does not support preview.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 2) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Basic</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "legendVisibility",
                                            defaultValue: this.selectionShowHide[1].id,
                                            displayName: "Visibility:",
                                            dataSource: this.selectionShowHide
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "legendColumnCount",
                                            defaultValue: undefined,
                                            displayName: "Column count:",
                                            minValue: 0,
                                            maxValue: 20
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "legendRowCount",
                                            defaultValue: undefined,
                                            displayName: "Row count:",
                                            minValue: 0,
                                            maxValue: 20
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "legendHoverMode",
                                            defaultValue: this.legendHoverMode[2].id,
                                            displayName: "Hover mode:",
                                            dataSource: this.legendHoverMode
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "legendMarkerSize",
                                            defaultValue: 20,
                                            displayName: "Marker size:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Layout & Position</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "legendOrientation",
                                            defaultValue: this.orientation[0].id,
                                            displayName: "Orientation:",
                                            dataSource: this.orientation
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "legendItemTextPosition",
                                            defaultValue: this.itemTextPosition[3].id,
                                            displayName: "Item text position:",
                                            dataSource: this.itemTextPosition
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "legendPosition",
                                            defaultValue: this.legendPosition[1].id,
                                            displayName: "Position:",
                                            dataSource: this.legendPosition
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "legendItemsAlignment",
                                            defaultValue: this.horizontalAlignmentOption[0].id,
                                            displayName: "Items alignment:",
                                            dataSource: this.horizontalAlignmentOption
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "legendHorizontalAlignment",
                                            defaultValue: this.horizontalAlignmentOption[0].id,
                                            displayName: "Horizontal alignment:",
                                            dataSource: this.horizontalAlignmentOption
                                        })
                                    }
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "legendVerticalAlignment",
                                            defaultValue: this.verticalAlignmentOption[1].id,
                                            displayName: "Vertical alignment:",
                                            dataSource: this.verticalAlignmentOption
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Style</div>
                                    {
                                        this.renderSettingsColorBoxField({
                                            settingsName: "legendBgColor",
                                            defaultValue: undefined,
                                            displayName: "Background color:"
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "legendColumnItemSpacing",
                                            defaultValue: 20,
                                            displayName: "Column item spacing:",
                                            minValue: 0,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "legendRowItemSpacing",
                                            defaultValue: 8,
                                            displayName: "Row item spacing:",
                                            minValue: 0,
                                            maxValue: 9999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "legendPaddingLeftRight",
                                            defaultValue: 10,
                                            displayName: "Padding left right:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "legendPaddingTopBottom",
                                            defaultValue: 10,
                                            displayName: "Padding top bottom:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                    {
                                        this.renderSettingsNumberBoxField({
                                            settingsName: "legendMargin",
                                            defaultValue: 10,
                                            displayName: "Margin:",
                                            minValue: 0,
                                            maxValue: 999
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                    </div>
                </React.Fragment>
            );
        }

        if (data.tabId === 3) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300} visible={false}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Date Range</div>
                                    {
                                        this.renderSettingsSelectBoxField({
                                            settingsName: "dateRangeValue",
                                            defaultValue: this.selectionDateRange[0].id,
                                            displayName: "Select a date range:",
                                            dataSource: this.selectionDateRange
                                        })
                                    }
                                    {
                                        this.renderSettingsTextBoxField({
                                            settingsName: "dateRangeField",
                                            defaultValue: undefined,
                                            displayName: "Specify a column for date range lookup:",
                                            placeholder: "Field name"
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={600}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Server-Side Query Editor</span>
                                    </div>
                                    {
                                        this.renderSettingsTextAreaWithButtonField({
                                            settingsName: "customQueryAttributes",
                                            defaultValue: undefined,
                                            displayName: "OData query string:",
                                            supportPreview: false,
                                            hasMiniButton: true,
                                            buttonIcon: "fas fa-question",
                                            onClick: function () {
                                                window.open(LeopardStaticUIConfig.ODataDocUrl);
                                            }
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>OData for Linked View</span>
                                    </div>
                                    {
                                        this.renderSettingsTextAreaWithButtonField({
                                            settingsName: "oDataQueryForLinkedView",
                                            defaultValue: undefined,
                                            displayName: "Paramters for linked view:",
                                            supportPreview: false,
                                            hasMiniButton: false
                                        })
                                    }
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Client-Side Query Editor</span>
                                    </div>
                                    {
                                        this.renderSettingsTextAreaWithButtonField({
                                            settingsName: "clientSideQueryEditor",
                                            defaultValue: undefined,
                                            supportPreview: false,
                                            validator: false,
                                            placeholder: "Use the variable \"data\" as a starting point.",
                                            displayName: "Javascript code:",
                                            hasMiniButton: false
                                        })
                                    }
                                </div>
                            </Item>
                        </Box>
                        <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting does not support preview.
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return null;
    };

    onSelectionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            this.setState({
                selectedIndex: args.value
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.saveDataButtonOnClick}>
                    <div style={{width: "100%", overflowX: "auto"}}>
                        <div className={"leopard-editgridview-title"}>Configure Chart</div>
                        <div className={"leopard-gray-panel-container"} style={{marginTop: "5px"}}>
                            <TabPanel dataSource={this.tabPanelDataSource} onOptionChanged={this.onSelectionChanged}
                                      loop={true} itemTitleRender={this.tabItemTitleRender}
                                      itemComponent={this.tabItemContent} animationEnabled={true} swipeEnabled={false}
                            />
                        </div>
                    </div>
                    <div style={{padding: "10px 0px 10px 10px", minWidth: "900px", clear: "both"}}>
                        <span style={{padding: "0 10px 0 0"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Preview"}
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "buttonPreview"})}
                                    onClick={() => this.previewButtonOnClick(function () {
                                    })}/>
                        </span>
                        <span style={{padding: "0 10px 0 0"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Save"}
                                    useSubmitBehavior={true}></Button>
                        </span>
                        <span style={{padding: "0 0 0 5px"}}>
                            <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Close"}
                                    onClick={(e) => this.cancelButtonOnClick({e: e})}/>
                        </span>
                        <span className={"loading-progress-configurechart"} style={{
                            marginRight: "15px",
                            marginLeft: "30px",
                            position: "relative",
                            top: "5px",
                            visibility: "hidden"
                        }}><i className="fas fa-spinner fa-pulse" style={{color: "#FF8000", fontSize: "25px"}}></i>
                        </span>
                    </div>
                </form>
                <br/>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

export default connect(RetrieveDataFromReducer)(LeopardChartDesigner);