import React, {Component} from 'react';
import Box, {Item} from 'devextreme-react/box';
import {NumberBox, SelectBox, TextBox} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LRH from "../helpers/LeopardReactHelper";

class LeopardCommandLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customColumnOverallWidth: 200,
            customColumns: null
        }

        this.columnTypeSource = [{
            value: "photo-gallery",
            text: "Photo Gallery"
        }];
    }

    componentDidMount() {
        if (LDH.IsObjectNull(this.props.customColumnConfiguration)) {
            this.setState({customColumns: []});
        } else {
            let customColumns = this.props.customColumnConfiguration.customColumns;
            let width = this.props.customColumnConfiguration.customColumnOverallWidth;
            this.setState({customColumns: customColumns, customColumnOverallWidth: width});
        }
    }

    customColumnOverallWidthOnChange = (e) => {
        let that = this;
        this.setState({customColumnOverallWidth: e.value}, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
        this.props.disablePreviewButton();
    };

    updateValueForCustomColumn = (data) => {
        let that = this;
        let clonedColumns = LDH.DeepClone(this.state.customColumns);
        for (let i = 0; i < clonedColumns.length; i++) {
            if (clonedColumns[i].id === data.id) {
                clonedColumns[i][data.propertyName] = data.e.value;
            }
        }
        this.props.disablePreviewButton();
        this.setState({customColumns: clonedColumns}, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    }

    customColumnDeleteOnClick = (data) => {
        let that = this;
        let clonedColumns = LDH.DeepClone(this.state.customColumns);
        let filteredColumns = [];
        for (let i = 0; i < clonedColumns.length; i++) {
            if (clonedColumns[i].id !== data.id) {
                filteredColumns.push(clonedColumns[i]);
            }
        }
        this.props.disablePreviewButton();
        this.setState({customColumns: filteredColumns}, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    };

    generalAddColumnOnClick = () => {
        let that = this;
        if (this.state.customColumns.length > 7) {
            LRH.ShowToast("You can have a maximum of 8 links for a data view.", "error", 5000);
            return;
        }

        this.props.disablePreviewButton();
        this.setState({
            customColumns: [...this.state.customColumns, {
                id: LDH.GenerateGuid(),
                columnType: this.columnTypeSource[0].value,
                linkText: "",
                dataSourceId: ""
            }]
        }, function () {
            this.props.columnColumnDataOnChanged(that.state);
        });
    };

    render() {
        let that = this;
        if (this.state.customColumns === null) return null;

        let result = this.state.customColumns.map(function (item, i) {
            return (
                <Item key={i} baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>{LDH.IsValueEmpty(that.state.customColumns[i].linkText)
                                ? "Command Link " + (i + 1)
                                : that.state.customColumns[i].linkText}
                            </span>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button remove fas fa-minus"
                               onClick={(e) => that.customColumnDeleteOnClick({
                                   e: e, id: that.state.customColumns[i].id
                               })}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon
                                    elementId={"customColumnType_" + that.state.customColumns[i].id + "_help"}
                                    title={"Type of link"}
                                    text={"Specify a type of link for the data view."}/>
                                <span>Type of link:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={that.columnTypeSource} displayExpr={'text'}
                                           valueExpr={'value'}
                                           defaultValue={that.state.customColumns[i].columnType}
                                           onValueChanged={(e) => that.updateValueForCustomColumn({
                                               e: e, id: that.state.customColumns[i].id, propertyName: "columnType"
                                           })}/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnLinkText_" + i + "_help"}
                                                        title={"Link text"}
                                                        text={"Specify a display text for the link."}/>
                                <span>Link text:</span>
                            </div>
                            <div>
                                <TextBox defaultValue={that.state.customColumns[i].linkText} maxLength={20}
                                         onValueChanged={(e) => that.updateValueForCustomColumn({
                                             e: e, id: that.state.customColumns[i].id, propertyName: "linkText"
                                         })}/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnDataSourceId_" + i + "_help"}
                                                        title={"Data source ID"}
                                                        text={"Select a data source ID for this custom column."}/>
                                <span>Data source ID:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={that.props.columnFieldListForDropdown} displayExpr={'text'}
                                           valueExpr={'value'}
                                           defaultValue={that.state.customColumns[i].dataSourceId}
                                           onValueChanged={(e) => that.updateValueForCustomColumn({
                                               e: e, id: that.state.customColumns[i].id,
                                               propertyName: "dataSourceId"
                                           })}/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnCountColumn_" + i + "_help"}
                                                        title={"Visible/Count field"}
                                                        text={"Select a visible/count field for this custom column. If it's a Count field, " +
                                                        "the link will be visible if the value is greater than 0. If it's a Visible field, " +
                                                        "the link will be visible based on the 'true' or 'false' value."}/>
                                <span>Visible/Count field:</span>
                            </div>
                            <div>
                                <SelectBox dataSource={that.props.columnFieldListForDropdown} displayExpr={'text'}
                                           valueExpr={'value'}
                                           defaultValue={that.state.customColumns[i].countColumnName}
                                           onValueChanged={(e) => that.updateValueForCustomColumn({
                                               e: e, id: that.state.customColumns[i].id,
                                               propertyName: "countColumnName"
                                           })}/>
                            </div>
                        </div>
                    </div>
                </Item>
            );
        });

        return (
            <Box direction={'row'} width={'100%'}>
                <Item baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>General</span>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button fas fa-plus"
                               onClick={(e) => this.generalAddColumnOnClick(e)}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"customColumnOverallWidth_help"}
                                                        title={"Column width"}
                                                        text={"Specify the overall width for custom columns."}/>
                                <span>Column width:</span>
                            </div>
                            <div>
                                <NumberBox defaultValue={this.state.customColumnOverallWidth} showSpinButtons={true}
                                           min={10} max={9999} format={"#"}
                                           onValueChanged={(e) => this.customColumnOverallWidthOnChange(e)}/>
                            </div>
                        </div>
                    </div>
                </Item>
                {result}
            </Box>
        )
    }
}

export default LeopardCommandLinks;