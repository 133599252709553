import React, {Component} from 'react';
import {connect} from 'react-redux';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardStaticUIConfig from "./LeopardStaticUIConfig";
import {TileView} from "devextreme-react";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";

class LeopardPhotoGalleryEngine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customStore: null,
            photoData: []
        };

        this.uiObjectInstance = {
            photoInstance: null
        };
        this.relationships = [];
        this.relationshipsLinkedToDataView = [];
    }

    setPhotoInstance = (ref) => {
        let that = this;
        if (ref === null || ref.instance === null) return;
        this.uiObjectInstance.photoInstance = ref.instance;

        this.props.setPhotoInstance({
            instance: ref.instance,
            id: this.props.dataViewId,
            type: "photo",
            isDataView: true
        });

        that.relationships = that.props.relationships;
        let dashboardItemId = that.props.dataViewId;

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships) &&
            that.relationshipsLinkedToDataView.length === 0) {
            let linkedList = LDH.GetRelationshipsByDashboardItemId(that.relationships,
                dashboardItemId);
            that.relationshipsLinkedToDataView = linkedList;
            that.uiObjectInstance.photoInstance.option("relationships", linkedList);
        }

        if (!LDH.IsObjectNull(dashboardItemId) && !LDH.IsValueEmpty(dashboardItemId) &&
            !LDH.IsObjectNull(that.relationships)) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners.push({
                dashboardItemId: dashboardItemId,
                props: that.props,
                instance: ref.instance,
                callback: function (data) {
                    let photoDefinition = that.props.definition.photoDefinition;
                    let id = photoDefinition.parentDataSourceId;
                    for (var i = 0; i < data.features.length; i++) {
                        if (data.features[i] === "rowlink") {
                            if (LDH.IsObjectNull(data.dataFromSource) === false) {
                                that.getDataFromDataSource(data.dataFromSource[id]);
                            }
                            else{
                                that.getDataFromDataSource(null);
                            }
                        }
                    }
                }
            });
        }
    };

    componentWillUnmount = () => {
        let instance = this.uiObjectInstance.photoInstance;
        if (instance !== undefined && instance !== null) {
            instance.dispose();
        }
        this.props.setPhotoInstance({
            instance: null,
            id: this.props.dataViewId,
            type: "photo",
            isDataView: true
        });
    };

    componentDidMount = () => {
        this.relationships = this.props.relationships;
    };

    getDataFromDataSource = (itemId) => {
        let that = this;
        if (LDH.IsObjectNull(itemId) || LDH.IsValueEmpty(itemId)){
            that.setState({photoData: []});
            return;
        }
        LeopardAjaxHelper.GetAttachmentsByItemId(itemId, function (result) {
            let items = [];
            if (result !== null && result.message !== null && result.message.length > 0) {
                for (let i = 0; i < result.message.length; i++) {
                    if (result.message[i].DisplayType === "TN" &&
                        result.message[i].ContentType === "image/jpeg") {
                        let originalImageUrl = "";

                        for (let j = 0; j < result.message.length; j++) {
                            if (result.message[j].FileName === result.message[i].FileName &&
                                result.message[j].DisplayType === "IMG" &&
                                result.message[j].ContentType === "image/jpeg") {
                                originalImageUrl = result.message[j].Url;
                                break;
                            }
                        }

                        items.push({
                            imageUrl: result.message[i].Url,
                            id: LDH.GenerateGuid(),
                            itemId: itemId,
                            originalImageUrl: originalImageUrl
                        });
                    }
                }
            }
            that.setState({photoData: items});
        }, function () {
            LRH.ShowToast("Failed to retrieve photos from the server.", "error", 5000);
        });
    }

    photoOnClick = (e) => {
        window.open(e.imageData.e.originalImageUrl);
    }

    tileViewItem = (data) => {
        return (
            <React.Fragment>
                <div className={"leopard-thumbnail-image"} style={{width: "100%", height: "100%", padding: "5px"}}>
                    <div className={data.e.id + " leopard-tileviewitem-image"}
                         onClick={(e) => this.photoOnClick({e: e, imageData: data})}
                         style={{backgroundImage: `url(${data.e.imageUrl})`}}></div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        let photoDefinition = this.props.definition.photoDefinition;
        return (
            <React.Fragment>
                <div className={"leopard-photogallery-data-view"}>
                    <TileView dataSource={this.state.photoData} height={"100%"}
                              baseItemHeight={photoDefinition.heightOfThumbnail}
                              baseItemWidth={photoDefinition.widthOfThumbnail}
                              noDataText={"No photo available to display."}
                              ref={this.setPhotoInstance}
                              direction={"vertical"} itemMargin={0}
                              itemComponent={(e) => this.tileViewItem({
                                  e: e, definition: this.props.definition
                              })}/>
                </div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

export default connect(RetrieveDataFromReducer)(LeopardPhotoGalleryEngine);