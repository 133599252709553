export const InitChartData = (data, id) => {
    return {
        type: "INIT_CHART_DATA",
        chartData: data,
        dataViewId: id
    }
};

export const InitPhotoGalleryData = (data, id) => {
    return {
        type: "INIT_PHOTO_GALLERY_DATA",
        photoData: data,
        dataViewId: id
    }
};

export const SetUserCredential = (data) => {
    return {
        type: "SET_USER_CREDENTIAL",
        credential: data
    }
};

export const SetUserProfile = (data) => {
    return {
        type: "SET_USER_PROFILE",
        userProfile: data
    }
};

export const SetAuthenticatedUser = (data) => {
    return {
        type: "SET_AUTHENTICATED_USER",
        authenticatedUser: data
    }
};

export const InitCustomStore = (store, id) => {
    return {
        type: "INIT_CUSTOM_STORE",
        customStore: store,
        gridViewId: id
    }
};

export const InitCustomStoreForChart = (store, id) => {
    return {
        type: "INIT_CUSTOM_STORE_FOR_CHART",
        customStore: store,
        dataViewId: id
    }
};

export const InitCustomStoreForMap = (store, id) => {
    return {
        type: "INIT_CUSTOM_STORE_FOR_MAP",
        customStore: store,
        mapId: id
    }
};

export const UpdateGridViewDefinition = (data, id) => {
    return {
        type: "UPDATE_GRIDVIEW_COLUMN_DEF",
        gridDefinition: data,
        gridViewId: id
    }
};

export const SetLeftMenuItem = (data) => {
    return {
        type: "SET_LEFT_MENU_ITEM",
        selectedLeftMenuItem: data,
    }
};

export const DeleteGridViewDataById = (id) => {
    return {
        type: "DELETE_GRID_DATA_BY_ID",
        gridViewId: id
    }
};

export const KeepGridViewDataById = (id) => {
    return {
        type: "KEEP_GRID_DATA_BY_ID",
        gridViewId: id
    }
};

export const KeepChartDataById = (id) => {
    return {
        type: "KEEP_CHART_DATA_BY_ID",
        dataViewId: id
    }
};

export const KeepMapDataById = (id) => {
    return {
        type: "KEEP_MAP_DATA_BY_ID",
        mapId: id
    }
};

export const SetUserPermissions = (data) => {
    return {
        type: "SET_USER_PERMISSIONS",
        permissions: data
    }
};