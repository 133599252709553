import React, {Component} from 'react';
import {connect} from 'react-redux';
import Box, {Item} from 'devextreme-react/box';
import $ from 'jquery';
import {Button, CheckBox, NumberBox, SelectBox, TextArea} from 'devextreme-react';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import TabPanel from 'devextreme-react/tab-panel';
import FilterBuilder from 'devextreme-react/filter-builder';
import {UpdateGridViewDefinition} from '../foundation/LeopardActionCreators';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardDesignerTagBox from "./LeopardDesignerTagBox";
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LeopardTooltipWithText from "./LeopardTooltipWithText";
import LeopardCommandLinks from "./LeopardCommandLinks";
import {TextBox} from "devextreme-react/text-box";

class LeopardColumnDesigner extends Component {
    constructor(props) {
        super(props);

        this.filterFields = [];
        this.filterBuilderPreviewValue = "";
        this.columnDefinitionToSave = [];
        this.hasColumnFilterChanged = false;
        this.filterBuilderValue = "";
        this.hiddenFieldsForEditing = [];
        this.disabledFieldsForEditing = [];
        this.commandButtons = [];
        this.enableAdaptiveColumnOption = false;
        this.enableAutoRefresh = false;
        this.autoRefreshInterval = 30;
        this.customColumnOverallWidth = 200;
        this.clientSideQuery = "";
        this.oDataQueryForLinkedView = "";
        this.showAutoRefreshSwitch = true;
        this.optimizePagerForLargeDataset = true;
        this.defaultPageSize = 10;
        this.customColumnConfiguration = null;
        this.allowDraggingOption = false;
        this.allowDroppingOption = false;
        this.gridViewId = null;
        this.columnFieldList = [];
        this.columnFieldListForDropdown = [];
        this.dataSourceId = "";
        this.dataSourceURI = "";
        this.uiObjectInstances = [];
        this.explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
        this.columnResizingMode = LeopardStaticUIConfig.GridView_ColumnResizingMode;

        this.columnTypeDataSource = [
            {text: "String", value: "string"},
            {text: "Number", value: "number"},
            {text: "Date", value: "date"},
            {text: "Date Time", value: "datetime"},
            {text: "Boolean", value: "boolean"}
        ];

        this.columnAlignmentDataSource = [
            {text: "Left", value: "left"},
            {text: "Center", value: "center"},
            {text: "Right", value: "right"}
        ];

        this.sortOrderDataSource = [
            {text: "None", value: "none"},
            {text: "Ascending", value: "asc"},
            {text: "Descending", value: "desc"}
        ];

        this.filterOperationSourceForAll = [{
            value: "contains",
            text: "Contains"
        }, {
            value: "notcontains",
            text: "Not Contains"
        }, {
            value: "startswith",
            text: "Starts With"
        }, {
            value: "endswith",
            text: "Ends With"
        }, {
            value: "=",
            text: "="
        }, {
            value: "<>",
            text: "<>"
        }, {
            value: "<",
            text: "<"
        }, {
            value: ">",
            text: ">"
        }, {
            value: "<=",
            text: "<="
        }, {
            value: ">=",
            text: ">="
        }, {
            value: "between",
            text: "Between"
        }];

        this.filterOperationSourceForString = [{
            value: "=",
            text: "="
        }, {
            value: "<>",
            text: "<>"
        }, {
            value: "contains",
            text: "Contains"
        }, {
            value: "notcontains",
            text: "Not Contains"
        }, {
            value: "startswith",
            text: "Starts With"
        }, {
            value: "endswith",
            text: "Ends With"
        }];

        this.filterOperationSourceForNumber = [{
            value: "=",
            text: "="
        }, {
            value: "<>",
            text: "<>"
        }, {
            value: "<",
            text: "<"
        }, {
            value: ">",
            text: ">"
        }, {
            value: "<=",
            text: "<="
        }, {
            value: ">=",
            text: ">="
        }, {
            value: "between",
            text: "Between"
        }];

        this.filterOperationSourceForDate = [{
            value: "=",
            text: "="
        }, {
            value: "<>",
            text: "<>"
        }, {
            value: "<",
            text: "<"
        }, {
            value: ">",
            text: ">"
        }, {
            value: "<=",
            text: "<="
        }, {
            value: ">=",
            text: ">="
        }, {
            value: "between",
            text: "Between"
        }];

        this.filterOperationSourceForBoolean = [];

        this.autoCompleteOperationSource = [{
            value: "contains",
            text: "Contains"
        }, {
            value: "startswith",
            text: "Starts With"
        }];

        this.tabPanelDataSource = [{
            tabId: 0,
            tabTitle: 'Data Columns'
        }, {
            tabId: 1,
            tabTitle: 'Command Links'
        }, {
            tabId: 2,
            tabTitle: "Grid View"
        }, {
            tabId: 3,
            tabTitle: "Advanced Filter"
        }];

        this.selectionYesNo = [{
            value: false,
            text: "No"
        }, {
            value: true,
            text: "Yes"
        }];

        this.defaultPageSizeSource = [{
            value: 10,
            text: "10"
        }, {
            value: 25,
            text: "25"
        }, {
            value: 50,
            text: "50"
        }];

        this.columnResizingModeSource = [{
            value: "nextColumn",
            text: "Next Column"
        }, {
            value: "widget",
            text: "Grid View"
        }];

        this.filterOptionSource = [{
            value: "auto",
            text: "No"
        }, {
            value: "onClick",
            text: "Yes"
        }];
    }

    componentDidMount() {
        let definition = this.props.gridViewDefinition;
        this.columnDefinitionToSave = definition.columnDefinition;
        this.filterBuilderValue = definition.filterBuilderValue;
        this.hiddenFieldsForEditing = definition.hiddenFieldsForEditing;
        this.disabledFieldsForEditing = definition.disabledFieldsForEditing;
        this.commandButtons = definition.commandButtons;
        this.dataSourceId = definition.dataSourceId;
        this.dataSourceURI = definition.dataSourceURI;
        this.enableAdaptiveColumnOption = definition.adaptiveColumn;
        this.enableAutoRefresh = definition.enableAutoRefresh;
        this.autoRefreshInterval = definition.autoRefreshInterval;
        this.clientSideQuery = definition.clientSideQuery;
        this.oDataQueryForLinkedView = definition.oDataQueryForLinkedView;
        this.customColumnOverallWidth = definition.customColumnOverallWidth;
        this.showAutoRefreshSwitch = definition.showAutoRefreshSwitch;
        this.customColumnConfiguration = definition.customColumnConfiguration;

        this.optimizePagerForLargeDataset = definition.optimizePagerForLargeDataset;
        if (LDH.IsValueEmpty(this.optimizePagerForLargeDataset)) {
            this.optimizePagerForLargeDataset = true;
        }

        this.defaultPageSize = definition.defaultPageSize;
        if (LDH.IsValueEmpty(this.defaultPageSize)) {
            this.defaultPageSize = 10;
        }

        if (LDH.IsValueEmpty(definition.enableAutoRefresh)) {
            this.enableAutoRefresh = false;
        }
        if (LDH.IsValueEmpty(definition.autoRefreshInterval)) {
            this.autoRefreshInterval = 30;
        }
        if (LDH.IsValueEmpty(definition.clientSideQuery)) {
            this.clientSideQuery = "";
        }
        if (LDH.IsValueEmpty(definition.oDataQueryForLinkedView)) {
            this.oDataQueryForLinkedView = "";
        }
        if (LDH.IsValueEmpty(definition.customColumnOverallWidth)) {
            this.customColumnOverallWidth = 200;
        }
        if (LDH.IsValueEmpty(definition.showAutoRefreshSwitch)) {
            this.showAutoRefreshSwitch = true;
        }

        this.allowDraggingOption = definition.allowDragging;
        this.columnResizingMode = definition.columnResizingMode;

        this.explicitFilterOption = definition.explicitFilterOption;
        if (LDH.IsValueEmpty(this.explicitFilterOption)) {
            this.explicitFilterOption = LeopardStaticUIConfig.GridView_ExplicitFilterOption;
        }

        this.gridViewId = this.props.gridViewId;
        this.columnFieldList = this.props.columnFieldList.fullColumns;

        this.columnFieldListForDropdown = [];
        for (var i = 0; i < this.columnFieldList.length; i++) {
            this.columnFieldListForDropdown.push({
                value: this.columnFieldList[i],
                text: this.columnFieldList[i]
            });
        }

        setTimeout(function () {
            $('.leopard-table-with-fixedcolumn tbody').scroll(function (e) {
                var $tbody = $(".leopard-table-with-fixedcolumn tbody");
                var scroll = $tbody.scrollLeft();
                $('.leopard-table-with-fixedcolumn thead').css("left", -scroll);
                $('.leopard-table-with-fixedcolumn thead th:nth-child(1)').css("left", scroll);
                $('.leopard-table-with-fixedcolumn tbody td:nth-child(1)').css("left", scroll);
            });
            LRH.TriggerWindowResizeEvent();
        }, 100);
    }

    addUIObjectInstance = (data) => {
        if (data.ref === null || data.ref.instance === null) return;
        this.uiObjectInstances[data.uiObjectName] = data.ref.instance;
    };

    leopardDesignerTagBoxOnOpened = (data) => {
        let columnName = data.columnName;
        let instance = this.uiObjectInstances["columnType" + columnName];
        if (LDH.IsObjectNull(instance)) return;

        let columnType = instance.option("value");
        if (LDH.IsObjectNull(columnType) || columnType === "string") {
            data.e.component.option("items", this.filterOperationSourceForString);
        }
        if (!LDH.IsObjectNull(columnType) && columnType === "number") {
            data.e.component.option("items", this.filterOperationSourceForNumber);
        }
        if (!LDH.IsObjectNull(columnType) && columnType === "boolean") {
            data.e.component.option("items", this.filterOperationSourceForBoolean);
        }
        if (!LDH.IsObjectNull(columnType) && columnType === "date") {
            data.e.component.option("items", this.filterOperationSourceForDate);
        }
        if (!LDH.IsObjectNull(columnType) && columnType === "datetime") {
            data.e.component.option("items", this.filterOperationSourceForDate);
        }
    }

    leopardDesignerTagBoxOnClosed = (data) => {
        let columnName = data.columnName;
        let instance = this.uiObjectInstances["columnType" + columnName];
        if (LDH.IsObjectNull(instance)) return;

        data.e.component.option("items", this.filterOperationSourceForAll);
    }

    previewButtonOnClick = (callback, filter) => {
        $(".leopard-columndesigner-loading-progress").css("visibility", "visible");
        let that = this;
        LDH.DeleteLocalStorageById("GridViewLayoutState_" + that.gridViewId);

        setTimeout(() => {
            that.filterBuilderValue = filter;

            let gridDefinition = {
                columnDefinition: that.columnDefinitionToSave,
                adaptiveColumn: that.enableAdaptiveColumnOption,
                optimizePagerForLargeDataset: that.optimizePagerForLargeDataset,
                defaultPageSize: that.defaultPageSize,
                customColumnConfiguration: that.customColumnConfiguration,
                columnResizingMode: that.columnResizingMode,
                explicitFilterOption: that.explicitFilterOption,
                filterBuilderValue: that.filterBuilderValue,
                hiddenFieldsForEditing: that.hiddenFieldsForEditing,
                disabledFieldsForEditing: that.disabledFieldsForEditing,
                commandButtons: that.commandButtons,
                dataSourceId: that.dataSourceId,
                dataSourceURI: that.dataSourceURI,
                allowDragging: that.allowDraggingOption,
                allowDropping: that.allowDroppingOption,
                enableAutoRefresh: that.enableAutoRefresh,
                autoRefreshInterval: that.autoRefreshInterval,
                clientSideQuery: that.clientSideQuery,
                oDataQueryForLinkedView: that.oDataQueryForLinkedView,
                customColumnOverallWidth: that.customColumnOverallWidth,
                showAutoRefreshSwitch: that.showAutoRefreshSwitch
            };
            that.props.UpdateGridViewDefinition(gridDefinition, that.gridViewId);
            that.props.updateGridViewDefinitionOnSingleDataView(gridDefinition);

            that.props.applyGridViewConfigurationOnDataView({
                columnResizingMode: that.columnResizingMode,
                explicitFilterOption: that.explicitFilterOption,
                filterBuilderValue: that.filterBuilderValue,
                allowDragging: that.allowDraggingOption,
                allowDropping: that.allowDroppingOption,
                hiddenFieldsForEditing: that.hiddenFieldsForEditing,
                disabledFieldsForEditing: that.disabledFieldsForEditing,
                commandButtons: that.commandButtons,
                dataSourceId: that.dataSourceId,
                dataSourceURI: that.dataSourceURI,
                enableAutoRefresh: that.enableAutoRefresh,
                autoRefreshInterval: that.autoRefreshInterval,
                clientSideQuery: that.clientSideQuery,
                oDataQueryForLinkedView: that.oDataQueryForLinkedView,
                customColumnOverallWidth: that.customColumnOverallWidth,
                showAutoRefreshSwitch: that.showAutoRefreshSwitch
            });

            if (!LDH.IsObjectNull(that.commandButtons) && that.commandButtons.length > 0) {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").show();
            } else {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").hide();
            }

            if (!LDH.IsObjectNull(that.enableAutoRefresh) && that.enableAutoRefresh === true) {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
            } else {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
            }

            if (callback !== undefined && callback !== null) {
                callback();
            }

            that.cancelButtonOnClick();
            $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
        }, 100);
    };

    saveButtonOnClick = () => {
        let that = this;
        this.filterBuilderValue = this.filterBuilderPreviewValue;

        let columnDefinition = this.columnDefinitionToSave;
        let userProfile = this.props.state.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
        $(".leopard-columndesigner-loading-progress").css("visibility", "visible");

        for (var i = 0; i < this.columnDefinitionToSave.length; i++) {
            this.columnDefinitionToSave[i].hasSorted = false;
        }
        LDH.DeleteLocalStorageById("GridViewLayoutState_" + that.gridViewId);

        setTimeout(function () {
            LeopardAjaxHelper.UpdateGridViewDefintion(userId, organizationId, that.gridViewId, {
                columnDefinition: columnDefinition,
                adaptiveColumn: that.enableAdaptiveColumnOption,
                enableAutoRefresh: that.enableAutoRefresh,
                autoRefreshInterval: that.autoRefreshInterval,
                clientSideQuery: that.clientSideQuery,
                oDataQueryForLinkedView: that.oDataQueryForLinkedView,
                customColumnOverallWidth: that.customColumnOverallWidth,
                showAutoRefreshSwitch: that.showAutoRefreshSwitch,
                optimizePagerForLargeDataset: that.optimizePagerForLargeDataset,
                defaultPageSize: that.defaultPageSize,
                customColumnConfiguration: that.customColumnConfiguration,
                hiddenFieldsForEditing: that.hiddenFieldsForEditing,
                disabledFieldsForEditing: that.disabledFieldsForEditing,
                commandButtons: that.commandButtons,
                dataSourceId: that.dataSourceId,
                dataSourceURI: that.dataSourceURI,
                columnResizingMode: that.columnResizingMode,
                explicitFilterOption: that.explicitFilterOption,
                filterBuilderValue: that.filterBuilderValue,
                allowDragging: that.allowDraggingOption,
                allowDropping: that.allowDroppingOption,
            }, function () {
                LRH.ShowToast("Your Grid View configuration has been successfully saved.", "success", 5000);
                $(".leopard-columndesigner-loading-progress").css("visibility", "hidden");
                $(".leopard-leftmenu-item.selected .leopard-leftmenu-item-text").trigger("click");
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to save your Data View configuration.", "error", 5000);
                }
                $(".leopard-columndesigner-loading-progress").css("visibility", "hidden")
            });
        }, 100);
    };

    cancelButtonOnClick = () => {
        let that = this;
        $(".leopard-columndesigner-loading-progress").css("visibility", "visible");
        setTimeout(function () {
            that.props.gridViewBuilderCancelButtonOnClick();
        }, 100);
    };

    enableAdaptiveColumnsOnChange = (e) => {
        this.enableAdaptiveColumnOption = e.value;

        this.uiObjectInstances["columnResizingMode"]
            .option("disabled", e.value);

        if (this.columnResizingMode === "widget") {
            this.uiObjectInstances["columnResizingMode"]
                .option("value", "nextColumn");
        }
    };

    enableAutoRefreshOnChange = (e) => {
        this.enableAutoRefresh = e.value;

        if (e.value === true) {
            $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
        } else {
            $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
        }
        this.disablePreviewButton();
    };

    showAutoRefreshSwitchOnChange = (e) => {
        this.showAutoRefreshSwitch = e.value;
        this.disablePreviewButton();
    };

    autoRefreshIntervalOnChange = (e) => {
        this.autoRefreshInterval = e.value;
        this.disablePreviewButton();
    };

    clientSideQueryOnValueChanged = (e) => {
        this.clientSideQuery = e.value;
        this.disablePreviewButton();
    }

    oDataQueryForLinkedViewOnValueChanged = (e) => {
        this.oDataQueryForLinkedView = e.value;
        this.disablePreviewButton();
    }

    optimizePagerForLargeDatasetOnChange = (e) => {
        this.optimizePagerForLargeDataset = e.value;
        this.disablePreviewButton();
    };

    disablePreviewButton = () => {
        this.uiObjectInstances["buttonPreview"].option("disabled", true);
    }

    columnColumnDataOnChanged = (data) => {
        this.customColumnConfiguration = data;
    }

    defaultPageSizeOnChange = (e) => {
        this.defaultPageSize = e.value;
        this.disablePreviewButton();
    };

    columnResizingModeOnChange = (e) => {
        this.columnResizingMode = e.value;
    };

    explicitFilterOptionOnChange = (e) => {
        this.explicitFilterOption = e.value;
    };

    dataSourceIdOnChange = (e) => {
        this.dataSourceId = e.value;
    };

    dataSourceURIOnChange = (e) => {
        this.dataSourceURI = e.value;
    };

    filterBuilderOnValueChanged = (e) => {
        this.filterBuilderPreviewValue = e.value;
    };

    leopardDesignerTagBoxValueOnChange = (e) => {
        if (e.fieldType === "hidden-fields") {
            this.hiddenFieldsForEditing = e.fieldValue;
        } else if (e.fieldType === "disabled-fields") {
            this.disabledFieldsForEditing = e.fieldValue;
        } else if (e.fieldType === "command-buttons") {
            this.commandButtons = e.fieldValue;

            if (!LDH.IsObjectNull(e.fieldValue) && e.fieldValue.length > 0) {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").show();
            } else {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").hide();
            }
        }
        this.disablePreviewButton();
    };

    updateUIStateOnColumnTypeChanged = (columnName, columnType) => {
        let instanceFO = this.uiObjectInstances["filterOperations" + columnName];
        let instanceDO = this.uiObjectInstances["defaultOperation" + columnName];
        let instanceAAC = this.uiObjectInstances["allowAutoComplete" + columnName];

        instanceAAC.option("value", false);
        instanceFO.option("disabled", false);
        instanceDO.option("disabled", false);

        if (LDH.IsValueEmpty(columnType) || columnType === "string") {
            instanceAAC.option("disabled", false);
            instanceFO.option("value", ["contains"]);
            instanceDO.option("value", "contains");
        }
        if (!LDH.IsValueEmpty(columnType) && columnType === "number") {
            instanceAAC.option("disabled", true);
            instanceFO.option("value", ["="]);
            instanceDO.option("value", "=");
        }
        if (!LDH.IsValueEmpty(columnType) && columnType === "boolean") {
            instanceAAC.option("disabled", true);
            instanceFO.option("value", ["="]);
            instanceDO.option("value", "=");
        }
        if (!LDH.IsValueEmpty(columnType) && (columnType === "date" ||
            columnType === "datetime")) {
            instanceAAC.option("disabled", true);
            instanceFO.option("value", ["between"]);
            instanceDO.option("value", "between");
        }
    }

    updateUIStateOnInitialize = (columnName, columnType) => {
        let instanceFO = this.uiObjectInstances["filterOperations" + columnName];
        let instanceDO = this.uiObjectInstances["defaultOperation" + columnName];
        let instanceAAC = this.uiObjectInstances["allowAutoComplete" + columnName];

        if (LDH.IsObjectNull(instanceFO) || LDH.IsObjectNull(instanceDO) ||
            LDH.IsObjectNull(instanceAAC)) {
            return;
        }

        instanceFO.option("disabled", false);
        instanceDO.option("disabled", false);

        if (LDH.IsValueEmpty(columnType) || columnType === "string") {
            instanceAAC.option("disabled", false);
        }
        if (!LDH.IsValueEmpty(columnType) && columnType === "number") {
            instanceAAC.option("disabled", true);
            instanceAAC.option("value", false);
        }
        if (!LDH.IsValueEmpty(columnType) && columnType === "boolean") {
            instanceAAC.option("disabled", true);
            instanceAAC.option("value", false);
        }
        if (!LDH.IsValueEmpty(columnType) && (columnType === "date" ||
            columnType === "datetime")) {
            instanceAAC.option("disabled", true);
            instanceAAC.option("value", false);
        }

        if (instanceAAC.option("value") === true) {
            instanceFO.option("disabled", true);
            instanceDO.option("disabled", true);
        } else {
            instanceFO.option("disabled", false);
            instanceDO.option("disabled", false);
        }
    }

    updateColumnDefinition = (data) => {
        let updateType = data.updateType;
        let clonedColumnDef = LDH.DeepClone(this.columnDefinitionToSave);

        if (updateType === "visibleIndex" || updateType === "allowAutoComplete" ||
            updateType === "isEnabled" || updateType === "sortOrder" ||
            updateType === "autoCompleteOperation") {
            this.disablePreviewButton();
        }

        let column = clonedColumnDef.filter(c => {
            return c.columnName === data.column.columnName;
        });
        if (column === undefined || column === null || column.length === 0) {
            if (data.e.fieldType === "filterOperations") {
                data.column[updateType] = data.e.fieldValue;
            } else {
                data.column[updateType] = data.e.value;
            }
            clonedColumnDef.push(data.column);
        } else {
            if (data.e.fieldType === "filterOperations") {
                column[0][updateType] = data.e.fieldValue;
            } else {
                column[0][updateType] = data.e.value;
            }
        }

        this.columnDefinitionToSave = clonedColumnDef;
        if (updateType === "allowFiltering" && data.e.value === false) {
            let userProfile = this.props.state.userProfile;
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let defaultFilter = LeopardStaticUIConfig.GridView_DefaultFilterValue;

            if (LDH.IsValueEmpty(defaultFilter) === false && defaultFilter.length >= 3) {
                defaultFilter[2] = defaultFilter[2].replace("[VALUE]", organizationId);
            }
            this.hasColumnFilterChanged = true;
            this.filterBuilderValue = defaultFilter;
            this.filterBuilderPreviewValue = "";
            $(".leopard-settings-warning-text").show();
        }

        let dataColumn = null;
        if ((column === undefined || column === null || column.length === 0) &&
            !LDH.IsObjectNull(data.column)) {
            dataColumn = data.column;
        } else {
            dataColumn = column[0];
        }
        if (LDH.IsObjectNull(dataColumn)) return;

        if (updateType === "columnType") {
            let cn = dataColumn.columnName;
            this.updateUIStateOnColumnTypeChanged(cn, data.e.value);
        }

        if (updateType === "allowAutoComplete") {
            let cn = dataColumn.columnName;
            let instanceFO = this.uiObjectInstances["filterOperations" + cn];
            let instanceDO = this.uiObjectInstances["defaultOperation" + cn];
            let instanceType = this.uiObjectInstances["columnType" + cn];

            if (data.e.value === true) {
                instanceFO.option("value", ["="]);
                instanceDO.option("value", "=");
            }

            if (instanceType.option("value") === "string") {
                instanceFO.option("disabled", data.e.value);
                instanceDO.option("disabled", data.e.value);
            } else {
                instanceFO.option("disabled", false);
                instanceDO.option("disabled", false);
            }
        }
    };

    onOptionChanged = (args) => {
        if (args.name === 'selectedIndex') {
            if (!LDH.IsObjectNull(this.commandButtons) && this.commandButtons.length > 0) {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").show();
            } else {
                $(".leopard-settings-panel-contentgroup.data-source-id-field").hide();
            }

            if (!LDH.IsObjectNull(this.enableAutoRefresh) && this.enableAutoRefresh === true) {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
            } else {
                $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
            }
        }
    };

    onItemRendered = () => {
        if (!LDH.IsObjectNull(this.commandButtons) && this.commandButtons.length > 0) {
            $(".leopard-settings-panel-contentgroup.data-source-id-field").show();
        } else {
            $(".leopard-settings-panel-contentgroup.data-source-id-field").hide();
        }

        if (!LDH.IsObjectNull(this.enableAutoRefresh) && this.enableAutoRefresh === true) {
            $(".leopard-settings-panel-contentgroup.auto-refresh-feature").show();
        } else {
            $(".leopard-settings-panel-contentgroup.auto-refresh-feature").hide();
        }
    };

    tabItemTitleRender = (data) => {
        return (
            <React.Fragment>
                <span>{data.tabTitle}</span>
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        let that = this;

        if (data.e.tabId === 0) {
            setTimeout(function () {
                for (var j = 0; j < that.columnDefinitionToSave.length; j++) {
                    let columnType = that.columnDefinitionToSave[j].columnType;
                    let columnName = that.columnDefinitionToSave[j].columnName;
                    that.updateUIStateOnInitialize(columnName, columnType);
                }
            }, 100);
            return (
                <React.Fragment>
                    <div>
                        <div className="leopard-table-with-fixedcolumn-container">
                            <table className="leopard-table-with-fixedcolumn">
                                <thead>
                                <tr>
                                    <th className={"leopard-config-table-header leopard-table-fixed-column"}>Column
                                        Name
                                    </th>
                                    <th className={"leopard-config-table-header"}>Column Type</th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "92px"}}>Alignment
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "92px"}}>Min Width
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "92px"}}>Width</th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "117px"}}>
                                        <span>Order Index</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "203px"}}>
                                        <span>Filter Operations</span>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "130px"}}>
                                        <span>Default Operation</span>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "133px"}}>
                                        <LeopardTooltipWithText elementId={"autoCompleteOperationHeader_help"}
                                                                labelText={"AC Operation"} width={200}
                                                                text={"Auto-Complete Operation"}/>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "132px"}}>
                                        <span>Custom Heading</span>
                                    </th>
                                    <th className={"leopard-config-table-header"}><span>Sort Order</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"autoCompleteHeader_help"}
                                                                labelText={"AC"} width={150}
                                                                text={"Auto-Complete"}/>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"resizingHeader_help"}
                                                                labelText={"RE"} width={100}
                                                                text={"Resizing"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"sortingHeader_help"}
                                                                labelText={"SO"} width={100}
                                                                text={"Sorting"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"columnFilterHeader_help"}
                                                                labelText={"CF"} width={100}
                                                                text={"Column Filter"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"headerFilterHeader_help"}
                                                                labelText={"HF"} width={150}
                                                                text={"Header Filter"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"orderingHeader_help"}
                                                                labelText={"OR"} width={100}
                                                                text={"Ordering"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"fixedColumnHeader_help"}
                                                                labelText={"FC"} width={100}
                                                                text={"Fixed Column"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"visibleHeader_help"}
                                                                labelText={"VI"} width={100}
                                                                text={"Visible"}/>
                                    </th>
                                    <th className={"leopard-config-table-header"} style={{minWidth: "35px"}}>
                                        <LeopardTooltipWithText elementId={"enabledHeader_help"}
                                                                labelText={"EN"} width={100}
                                                                text={"Enabled"}/>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <RenderGridViewColumnDefinition gridColumns={this.columnFieldList} state={this.state}
                                                                updateColumnDefinition={(e) => this.updateColumnDefinition(e)}
                                                                thisComp={this}/>
                                </tbody>
                            </table>
                        </div>
                        <div className="leopard-settings-warning-text" style={{display: "none"}}>
                            Warning: You have changed the Filter settings. The Advanced Filter has now been reset.
                        </div>
                    </div>
                    <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting does
                        not support preview.
                    </div>
                </React.Fragment>
            );
        }
        if (data.e.tabId === 1) {
            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <LeopardCommandLinks columnFieldListForDropdown={this.columnFieldListForDropdown}
                                             disablePreviewButton={this.disablePreviewButton}
                                             customColumnConfiguration={this.customColumnConfiguration}
                                             columnColumnDataOnChanged={(e) => this.columnColumnDataOnChanged(e)}
                        />
                    </div>
                    <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting does
                        not support preview.
                    </div>
                </React.Fragment>
            );
        }
        if (data.e.tabId === 2) {
            setTimeout(function () {
                if (!LDH.IsObjectNull(that.uiObjectInstances["columnResizingMode"])) {
                    that.uiObjectInstances["columnResizingMode"].option(
                        "disabled", that.enableAdaptiveColumnOption);
                }
            }, 100);

            return (
                <React.Fragment>
                    <div style={{overflowX: "auto"}} className={"leopard-dataview-configbox"}>
                        <Box direction={'row'} width={'100%'}>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">Optimisation</div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"enableAdaptiveColumnOption_help"}
                                                                    title={"Enable adaptive columns"}
                                                                    text={"The grid view can adapt its layout to narrow screens. " +
                                                                    "If screen width is not enough to display all columns, the widget hides them according to the specified priority. " +
                                                                    "To view values of hidden columns, click the ellipsis button in the last column of the required row."}/>
                                            <span>Enable adaptive columns:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.selectionYesNo} displayExpr={'text'}
                                                       valueExpr={'value'}
                                                       defaultValue={this.enableAdaptiveColumnOption}
                                                       onValueChanged={(e) => this.enableAdaptiveColumnsOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"optimizePagerForLargeDataset_help"}
                                                                    title={"Optimise pager for large dataset"}
                                                                    text={"The grid view allows user to navigate data page by page and prevents user from jumping to a large page number. " +
                                                                    "It is recommended to turn on this feature if you have 500,000+ rows of data."}/>
                                            <span>Optimise pager for large dataset:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.selectionYesNo} displayExpr={'text'}
                                                       valueExpr={'value'}
                                                       defaultValue={this.optimizePagerForLargeDataset}
                                                       onValueChanged={(e) => this.optimizePagerForLargeDatasetOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"defaultPageSize_help"}
                                                                    title={"Number of records per page"}
                                                                    text={"Specify the number of records per page."}/>
                                            <span>Number of records per page:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.defaultPageSizeSource} displayExpr={'text'}
                                                       valueExpr={'value'}
                                                       defaultValue={this.defaultPageSize}
                                                       onValueChanged={(e) => this.defaultPageSizeOnChange(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">General</div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"commandButtonsOnGridView_help"}
                                                                    title={"CRUD operations"}
                                                                    text={"Specify whether allow user to modify, add and delete data row on the grid view."}/>
                                            <span>CRUD operations:</span>
                                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                        </div>
                                        <div>
                                            <LeopardDesignerTagBox
                                                dropdownList={LeopardStaticUIConfig.StaticList_CommandButtonList}
                                                fieldValue={this.commandButtons} uiRef={null}
                                                fieldType={"command-buttons"}
                                                leopardDesignerTagBoxValueOnChange={
                                                    (e) => this.leopardDesignerTagBoxValueOnChange(e)
                                                }/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup data-source-id-field">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"dataSourceId_help"}
                                                                    title={"Specify a data source ID"}
                                                                    text={"A Data Source ID must be specified when a user is adding, editing or deleting a row from the grid view. The Data Source ID must be the data column with primary key in the table."}/>
                                            <span>Specify a data source ID:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.columnFieldListForDropdown} displayExpr={'text'}
                                                       valueExpr={'value'} defaultValue={this.dataSourceId}
                                                       onValueChanged={(e) => this.dataSourceIdOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup data-source-id-field">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"dataSourceURI_help"}
                                                                    title={"Specify a data source URI"}
                                                                    text={"Specify a URI for the data source. If you are not sure about this, please contact Leopard Systems for assistance."}/>
                                            <span>Specify a data source URI:</span>
                                        </div>
                                        <div>
                                            <TextBox defaultValue={this.dataSourceURI}
                                                     onValueChanged={(e) => this.dataSourceURIOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"columnResizingMode_help"}
                                                                    title={"Column resizing mode"}
                                                                    text={"The grid view allows a user to resize columns at runtime. They can be resized in two different modes: " +
                                                                    "by changing the width of the next column or the width of the grid view."}/>
                                            <span>Column resizing mode:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.columnResizingModeSource} displayExpr={'text'}
                                                       valueExpr={'value'} defaultValue={this.columnResizingMode}
                                                       ref={(e) => this.addUIObjectInstance({
                                                           uiObjectName: "columnResizingMode", ref: e
                                                       })}
                                                       onValueChanged={(e) => this.columnResizingModeOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"explicitFilterOption_help"}
                                                                    title={"Explicit data filtering"}
                                                                    text={"The grid view allows a user to filter values on multiple columns, and sends the " +
                                                                    "request to the server by pressing the Enter key or clicking on Apply Filter button."}/>
                                            <span>Explicit data filtering:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.filterOptionSource} displayExpr={'text'}
                                                       valueExpr={'value'} defaultValue={this.explicitFilterOption}
                                                       onValueChanged={(e) => this.explicitFilterOptionOnChange(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Real-time update</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"enableAutoRefreshOnGridView_help"}
                                                                    title={"Enable auto-refresh"}
                                                                    text={"You can enable the auto-refresh feature to achieve real-time data update on the grid view."}/>
                                            <span>Enable auto-refresh:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.selectionYesNo} displayExpr={'text'}
                                                       valueExpr={'value'} defaultValue={this.enableAutoRefresh}
                                                       onValueChanged={(e) => this.enableAutoRefreshOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup auto-refresh-feature">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"autoRefreshInternalOnGridView_help"}
                                                                    title={"Auto-refresh interval (seconds)"}
                                                                    text={"Specify the number of seconds for the data to be automatically refreshed."}/>
                                            <span>Auto-refresh interval (seconds):</span>
                                        </div>
                                        <div>
                                            <NumberBox defaultValue={this.autoRefreshInterval} showSpinButtons={true}
                                                       min={5} max={9999} format={"#"}
                                                       onValueChanged={(e) => this.autoRefreshIntervalOnChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup auto-refresh-feature">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"showAutoRefreshSwitchOnGridView_help"}
                                                                    title={"Show auto-refresh switch"}
                                                                    text={"You can hide the auto-refresh switch on the grid view but still allow it working in the background."}/>
                                            <span>Show auto-refresh switch:</span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.selectionYesNo} displayExpr={'text'}
                                                       valueExpr={'value'} defaultValue={this.showAutoRefreshSwitch}
                                                       onValueChanged={(e) => this.showAutoRefreshSwitchOnChange(e)}/>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={300}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Edit Form</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"disabledFieldsForPopupEditor_help"}
                                                                    title={"Disabled fields"}
                                                                    text={"Specifies which columns on the grid view should be disabled when a user editing a data row."}/>
                                            <span>Disabled fields:</span>
                                        </div>
                                        <div>
                                            <LeopardDesignerTagBox
                                                dropdownList={this.columnFieldListForDropdown}
                                                fieldValue={this.disabledFieldsForEditing}
                                                fieldType={"disabled-fields"} uiRef={null}
                                                leopardDesignerTagBoxValueOnChange={
                                                    (e) => this.leopardDesignerTagBoxValueOnChange(e)
                                                }/>
                                        </div>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"hiddenFieldsForPopupEditor_help"}
                                                                    title={"Hidden fields"}
                                                                    text={"Specifies which columns on the grid view should be hidden when a user editing a data row."}/>
                                            <span>Hidden fields:</span>
                                        </div>
                                        <div>
                                            <LeopardDesignerTagBox
                                                dropdownList={this.columnFieldListForDropdown}
                                                fieldValue={this.hiddenFieldsForEditing}
                                                fieldType={"hidden-fields"} uiRef={null}
                                                leopardDesignerTagBoxValueOnChange={
                                                    (e) => this.leopardDesignerTagBoxValueOnChange(e)
                                                }/>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>OData for Linked View</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"odataCustomQuery_help"}
                                                                    title={"Paramters for linked view"}
                                                                    text={"The Odata parameters will be used when the data view is linked with another data view in a workspace."}/>
                                            <span>OData paramters:</span>
                                        </div>
                                        <TextArea defaultValue={this.oDataQueryForLinkedView} height={"140px"}
                                                  id={"Textbox_oDataCustomQuery"}
                                                  placeholder={"Id eq [Id] or Barcode eq '[Barcode]'"}
                                                  onValueChanged={(e) => this.oDataQueryForLinkedViewOnValueChanged(e)}>
                                        </TextArea>
                                    </div>
                                </div>
                            </Item>
                            <Item baseSize={400}>
                                <div className="leopard-settings-panel-cell">
                                    <div className="leopard-settings-panel-title">
                                        <span>Client-Side Query Editor</span>
                                        <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i>
                                    </div>
                                    <div className="leopard-settings-panel-contentgroup">
                                        <div className="leopard-settings-panel-content-label">
                                            <LeopardTooltipWithIcon elementId={"clientSideQuery_help"}
                                                                    title={"Javascript code"}
                                                                    text={"Write a custom logic in Javascript to process the data on the client-side."}/>
                                            <span>Javascript code:</span>
                                        </div>
                                        <TextArea defaultValue={this.clientSideQuery} height={"140px"}
                                                  id={"Textbox_clientSideQueryEditor"}
                                                  placeholder={"Use the variable \"data\" as a starting point."}
                                                  onValueChanged={(e) => this.clientSideQueryOnValueChanged(e)}>
                                        </TextArea>
                                    </div>
                                </div>
                            </Item>
                        </Box>
                        <div className={"leopard-preview-text"} style={{marginTop: "10px"}}>
                            <i className={"fas fa-exclamation-triangle leopard-field-attention-mark"}></i> This setting
                            does not support preview.
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        if (data.e.tabId === 3) {
            let that = this;
            if (this.hasColumnFilterChanged === true || this.filterFields.length === 0) {
                that.filterFields = [];
                for (var i = 0; i < that.columnFieldList.length; i++) {
                    let gridColumnName = that.columnFieldList[i];
                    let columnDataType = "string";
                    let allowFiltering = true;

                    for (var j = 0; j < that.columnDefinitionToSave.length; j++) {
                        let columnName = that.columnDefinitionToSave[j].columnName;
                        if (columnName !== gridColumnName) continue;
                        if (that.columnDefinitionToSave[j].columnType !== undefined &&
                            that.columnDefinitionToSave[j].columnType !== "") {
                            columnDataType = that.columnDefinitionToSave[j].columnType;
                        }
                        if (that.columnDefinitionToSave[j].allowFiltering !== undefined &&
                            that.columnDefinitionToSave[j].allowFiltering === false) {
                            allowFiltering = false;
                        }
                    }

                    if (allowFiltering === true) {
                        if (columnDataType === "date") {
                            that.filterFields.push({
                                dataField: that.columnFieldList[i],
                                dataType: columnDataType,
                                format: "dd/MM/yyyy"
                            });
                        } else if (columnDataType === "datetime") {
                            that.filterFields.push({
                                dataField: that.columnFieldList[i],
                                dataType: columnDataType,
                                format: "dd/MM/yyyy HH:mm:ss"
                            });
                        } else {
                            that.filterFields.push({
                                dataField: that.columnFieldList[i],
                                dataType: columnDataType
                            });
                        }
                    }
                }
                this.hasColumnFilterChanged = false;
            }

            this.filterBuilderPreviewValue = this.filterBuilderValue;
            return (
                <React.Fragment>
                    <div style={{padding: "20px"}}>
                        <FilterBuilder fields={this.filterFields} defaultValue={this.filterBuilderValue}
                                       onValueChanged={(e) => this.filterBuilderOnValueChanged(e)}
                                       groupOperations={["and", "or"]} maxGroupLevel={0}/>
                    </div>
                </React.Fragment>
            )
        }
        return null;
    };

    render() {
        let gridViewState = this.props.state.gridViewState;
        let currentState = gridViewState.filter(gridView => {
            return gridView.gridViewId === this.props.gridViewId;
        });

        if (this.props.state.gridViewState.length === 0 || currentState === undefined ||
            this.props.gridViewBuilderVisible === false) {
            return ("");
        }
        currentState = currentState[0];
        return (
            <React.Fragment>
                <div style={{width: "100%", overflowX: "auto"}}>
                    <div className={"leopard-editgridview-title"}>Configure Grid</div>
                    <div className={"leopard-gray-panel-container"} style={{marginTop: "5px"}}>
                        <TabPanel dataSource={this.tabPanelDataSource} onOptionChanged={this.onOptionChanged}
                                  loop={true} itemTitleRender={this.tabItemTitleRender}
                                  onItemRendered={this.onItemRendered}
                                  itemComponent={(e) => this.tabItemContent({e: e, currentState: currentState})}
                                  animationEnabled={true} swipeEnabled={false} showNavButtons={true}
                        />
                    </div>
                </div>
                <div style={{padding: "10px 0px 10px 10px", minWidth: "900px", clear: "both"}}>
                    <span style={{padding: "0 10px 0 0"}}>
                        <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Preview"}
                                ref={(e) => this.addUIObjectInstance({uiObjectName: "buttonPreview", ref: e})}
                                onClick={() => this.previewButtonOnClick(null, this.filterBuilderPreviewValue)}/>
                    </span>
                    <span style={{padding: "0 10px 0 0"}}>
                        <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Save"}
                                onClick={() => this.saveButtonOnClick()}></Button>
                    </span>
                    <span style={{padding: "0 0 0 0"}}>
                        <Button className="leopard-standard-button" style={{minWidth: "100px"}} text={"Close"}
                                onClick={(e) => this.cancelButtonOnClick(e)}/>
                    </span>
                    <span className="leopard-columndesigner-loading-progress"
                          style={{
                              marginRight: "15px",
                              marginLeft: "30px",
                              position: "relative",
                              top: "5px",
                              visibility: "hidden"
                          }}>
                        <i className="fas fa-spinner fa-pulse" style={{color: "#FF8000", fontSize: "25px"}}></i>
                    </span>
                </div>
                <hr/>
                <div className={"leopard-editgridview-title"} style={{marginBottom: "10px", marginTop: "20px"}}>
                    Preview Layout
                </div>
            </React.Fragment>
        );
    }
}

const RenderGridViewColumnDefinition = ({gridColumns, state, updateColumnDefinition, thisComp}) => {
    let columnsToBind = [];

    for (var i = 0; i < gridColumns.length; i++) {
        columnsToBind.push({columnName: gridColumns[i]});
    }
    let returnHtml = columnsToBind.map(column => {
        let columnDef = thisComp.columnDefinitionToSave.filter(c => {
            return c.columnName === column.columnName;
        });

        let allowAutoComplete = false;
        if (columnDef.length > 0 && columnDef[0].allowAutoComplete !== undefined &&
            (LDH.IsObjectNull(columnDef[0].columnType) || columnDef[0].columnType === "string")) {
            allowAutoComplete = columnDef[0].allowAutoComplete;
        }

        let allowResizing = true;
        if (columnDef.length > 0 && columnDef[0].allowResizing !== undefined) {
            allowResizing = columnDef[0].allowResizing;
        }

        let allowSorting = true;
        if (columnDef.length > 0 && columnDef[0].allowSorting !== undefined) {
            allowSorting = columnDef[0].allowSorting;
        }

        let allowFiltering = true;
        if (columnDef.length > 0 && columnDef[0].allowFiltering !== undefined) {
            allowFiltering = columnDef[0].allowFiltering;
        }

        let allowHeaderFilter = true;
        if (columnDef.length > 0 && columnDef[0].allowHeaderFilter !== undefined) {
            allowHeaderFilter = columnDef[0].allowHeaderFilter;
        }

        let allowReordering = true;
        if (columnDef.length > 0 && columnDef[0].allowReordering !== undefined) {
            allowReordering = columnDef[0].allowReordering;
        }

        let isFixed = false;
        if (columnDef.length > 0 && columnDef[0].isFixed !== undefined) {
            isFixed = columnDef[0].isFixed;
        }

        let isVisible = true;
        if (columnDef.length > 0 && columnDef[0].isVisible !== undefined) {
            isVisible = columnDef[0].isVisible;
        }

        let isEnabled = true;
        if (columnDef.length > 0 && columnDef[0].isEnabled !== undefined) {
            isEnabled = columnDef[0].isEnabled;
        }

        let visibleIndex = null;
        if (columnDef.length > 0 && columnDef[0].visibleIndex !== undefined) {
            visibleIndex = columnDef[0].visibleIndex;
        }

        let columnCustomHeader = "";
        if (columnDef.length > 0 && columnDef[0].columnCustomHeader !== undefined) {
            columnCustomHeader = columnDef[0].columnCustomHeader;
        }

        let minimumWidth = 50;
        if (columnDef.length > 0 && columnDef[0].minimumWidth !== undefined) {
            minimumWidth = columnDef[0].minimumWidth;
        }

        let width = null;
        if (columnDef.length > 0 && columnDef[0].width !== undefined) {
            width = columnDef[0].width;
        }

        let columnAlign = "left";
        if (columnDef.length > 0 && columnDef[0].columnAlign !== undefined) {
            columnAlign = columnDef[0].columnAlign;
        }

        let columnType = "string";
        if (columnDef.length > 0 && columnDef[0].columnType !== undefined) {
            columnType = columnDef[0].columnType;
        }

        let sortOrder = "none";
        if (columnDef.length > 0 && columnDef[0].sortOrder !== undefined) {
            sortOrder = columnDef[0].sortOrder;
        }

        let defaultOperation = "=";
        if (columnDef.length > 0 && columnDef[0].defaultOperation !== undefined) {
            defaultOperation = columnDef[0].defaultOperation;
        }

        let autoCompleteOperation = "startswith";
        if (columnDef.length > 0 && columnDef[0].autoCompleteOperation !== undefined) {
            autoCompleteOperation = columnDef[0].autoCompleteOperation;
        }

        let filterOperations = [];
        if (columnDef.length > 0 && columnDef[0].filterOperations !== undefined) {
            filterOperations = columnDef[0].filterOperations;
        }
        return (
            <tr key={column.columnName} className={"leopard-configure-grid-tablerow"}>
                <td className={"leopard-table-column-columnname leopard-table-fixed-column"} title={column.columnName}
                    style={{paddingLeft: "10px", borderRight: "1px solid #ddd"}}>
                    {column.columnName}
                </td>
                <td>
                    <SelectBox dataSource={thisComp.columnTypeDataSource} valueExpr={'value'} displayExpr={'text'}
                               defaultValue={columnType} width={100}
                               ref={(e) => thisComp.addUIObjectInstance({
                                   uiObjectName: "columnType" + column.columnName, ref: e
                               })}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "columnType"
                               })}/>
                </td>
                <td><SelectBox dataSource={thisComp.columnAlignmentDataSource} valueExpr={'value'} displayExpr={'text'}
                               defaultValue={columnAlign}
                               width={90}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "columnAlign"
                               })}/></td>

                <td><NumberBox min={1} defaultValue={minimumWidth} showSpinButtons={true} width={90}
                               placeholder={"Min 1"} format={"#"}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "minimumWidth"
                               })}/></td>

                <td><NumberBox min={1} defaultValue={width} showSpinButtons={true} width={90} placeholder={"Min 1"}
                               format={"#"} onValueChanged={(e) => updateColumnDefinition({
                    e: e,
                    column: column,
                    updateType: "width"
                })}/></td>

                <td><NumberBox defaultValue={visibleIndex} showSpinButtons={true} width={115}
                               placeholder={"Starts from 0"} min={0}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "visibleIndex"
                               })}/></td>

                <td>
                    <LeopardDesignerTagBox dropdownList={thisComp.filterOperationSourceForAll}
                                           fieldValue={filterOperations}
                                           fieldType={"filterOperations"}
                                           addUIObjectInstance={(e) => thisComp.addUIObjectInstance(e)}
                                           columnName={column.columnName}
                                           leopardDesignerTagBoxOnOpened={
                                               (e) => thisComp.leopardDesignerTagBoxOnOpened({
                                                   e: e, columnName: column.columnName
                                               })
                                           }
                                           leopardDesignerTagBoxOnClosed={
                                               (e) => thisComp.leopardDesignerTagBoxOnClosed({
                                                   e: e, columnName: column.columnName
                                               })
                                           }
                                           leopardDesignerTagBoxValueOnChange={(e) => updateColumnDefinition({
                                               e: e, column: column,
                                               updateType: "filterOperations"
                                           })}/>
                </td>

                <td><SelectBox dataSource={thisComp.filterOperationSourceForAll} valueExpr={'value'}
                               displayExpr={'text'}
                               defaultValue={defaultOperation} width={130}
                               ref={(e) => thisComp.addUIObjectInstance({
                                   uiObjectName: "defaultOperation" + column.columnName, ref: e
                               })}
                               onOpened={(e) => thisComp.leopardDesignerTagBoxOnOpened({
                                   e: e, columnName: column.columnName
                               })}
                               onClosed={(e) => thisComp.leopardDesignerTagBoxOnClosed({
                                   e: e, columnName: column.columnName
                               })}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "defaultOperation"
                               })}/></td>

                <td><SelectBox dataSource={thisComp.autoCompleteOperationSource} valueExpr={'value'}
                               displayExpr={'text'} defaultValue={autoCompleteOperation}
                               width={130} ref={(e) => thisComp.addUIObjectInstance({
                    uiObjectName: "autoCompleteOperation" + column.columnName, ref: e
                })}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "autoCompleteOperation"
                               })}/></td>

                <td><TextBox defaultValue={columnCustomHeader} maxLength={50} width={130}
                             onValueChanged={(e) => updateColumnDefinition({
                                 e: e,
                                 column: column,
                                 updateType: "columnCustomHeader"
                             })}/></td>

                <td><SelectBox dataSource={thisComp.sortOrderDataSource} valueExpr={'value'} displayExpr={'text'}
                               defaultValue={sortOrder} width={100}
                               onValueChanged={(e) => updateColumnDefinition({
                                   e: e,
                                   column: column,
                                   updateType: "sortOrder"
                               })}/>
                </td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowAutoComplete}
                              ref={(e) => thisComp.addUIObjectInstance({
                                  uiObjectName: "allowAutoComplete" + column.columnName, ref: e
                              })} onValueChanged={(e) => updateColumnDefinition({
                        e: e,
                        column: column,
                        updateType: "allowAutoComplete"
                    })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowResizing}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowResizing"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowSorting}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowSorting"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowFiltering}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowFiltering"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowHeaderFilter}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowHeaderFilter"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={allowReordering}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "allowReordering"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={isFixed}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "isFixed"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={isVisible}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "isVisible"
                              })}/></td>

                <td style={{textAlign: "center"}}>
                    <CheckBox width={33} defaultValue={isEnabled}
                              onValueChanged={(e) => updateColumnDefinition({
                                  e: e,
                                  column: column,
                                  updateType: "isEnabled"
                              })}/></td>
            </tr>);
    });

    return (<React.Fragment>{returnHtml}</React.Fragment>);
};

const RetrieveDataFromReducer = (state) => {
    return {state: state};
};

const SendDataToReducer = (dispatch) => {
    return {
        UpdateGridViewDefinition: (data, id) => {
            dispatch(UpdateGridViewDefinition(data, id));
        }
    };
};

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardColumnDesigner);