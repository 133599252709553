import {Component} from 'react';
import 'url-search-params-polyfill';
import LRH from './LeopardReactHelper';
import Auth, {API, Signer} from "aws-amplify";
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardTestHelper from './LeopardTestHelper';
import LDH from './LeopardDataHelper';
import LeopardSecurity from '../security/LeopardSecurity';
import LeopardCognitoConfig from "../foundation/LeopardCognitoConfig";

class LeopardAjaxHelper extends Component {
    static IsResponseContainsError = (response, callbackError) => {
        if (response === null || response === "null") {
            callbackError(response, false);
            return true;
        } else if (response.message !== undefined && response.message !== null &&
            response.message.statusCode !== undefined &&
            response.message.statusCode === 400 &&
            response.message.error !== undefined && response.message.error !== null &&
            response.message.error.indexOf("Item not found") !== -1) {
            return false;
        } else if (response.message !== undefined && response.message !== null &&
            response.message.statusCode !== undefined &&
            response.message.statusCode === 400) {
            callbackError(response, false);
            return true;
        } else if (response.errorMessage !== undefined && response.errorMessage !== null &&
            response.errorMessage !== "") {
            callbackError(response, false);
            return true;
        } else if (response.message !== undefined && response.message !== null &&
            response.message.error !== undefined &&
            response.message.error !== "") {
            callbackError(response, false);
            return true;
        } else if (LeopardAjaxHelper.IsLoginSessionTimedOut(response)) {
            callbackError(response, true);
            return true;
        }
        return false;
    };

    static IsLoginSessionTimedOut = (error) => {
        try {
            if (error !== undefined && error !== null && error === "null") {
                LeopardSecurity.UserLogout();
                return true;
            } else if (error !== null &&
                LDH.IsValueEmpty(error.message) === false && (
                    error.message.indexOf("security token") !== -1 ||
                    error.message.indexOf("request is expired") !== -1 ||
                    error.message.indexOf("Authentication Token") !== -1 ||
                    error.message.indexOf("is forbidden") !== -1 ||
                    error.message.indexOf("Unauthenticated access") !== -1)) {
                LeopardSecurity.UserLogout();
                return true;
            }
            return false;
        } catch (ex) {
            return false;
        }
    };

    static HandleSessionTimeoutEvent = (error) => {
        if (error === "not authenticated") {
            LeopardSecurity.UserLogout();
            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
        }
    }

    static GenericRequestHeaders = () => {
        return {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            "Pragma": "no-cache",
            "Expires": "Sat, 01 Jan 2000 00:00:00 GMT"
        };
    }

    static GetLeftMenuItems = (userId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_Main_LeftMenuItems";

                    if (profile.ParentGroup.Configs === undefined ||
                        profile.ParentGroup.Configs === null) {
                        profile.ParentGroup.Configs = [];
                    }

                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateLeftMenuItemForDashboard = (userId, organizationId, menuItemId, menuItemName,
                                             dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    menuItemsObj.push({
                        contentPageType: "dashboard-view",
                        menuItemName: menuItemName,
                        menuItemId: menuItemId,
                        dashboardId: dashboardId,
                        menuItemType: "dashboard",
                        menuItemVersion: LeopardStaticUIConfig.LeftMenuItemVersion,
                        parentMenuItemName: "Workspaces"
                    });

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateLeftMenuItemForDashboard = (userId, organizationId, menuItemName,
                                             dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }

                    let dashboardItem = null;
                    let menuItemsIndex = 0;
                    for (var i = 0; i < menuItemsObj.length; i++) {
                        if (menuItemsObj[i].menuItemType !== "dashboard") {
                            continue;
                        }
                        if (menuItemsObj[i].dashboardId === dashboardId) {
                            dashboardItem = menuItemsObj[i];
                            menuItemsIndex = i;
                            break;
                        }
                    }

                    if (dashboardItem === null && callbackError !== undefined && callbackError !== null) {
                        callbackError({error: "Dashboard item not found"});
                    }
                    if (dashboardItem === null) return;

                    dashboardItem.menuItemVersion = LeopardStaticUIConfig.LeftMenuItemVersion;
                    dashboardItem.menuItemName = menuItemName;
                    menuItemsObj[menuItemsIndex] = dashboardItem;
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(dashboardItem);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateLeftMenuItemForDataView = (userId, organizationId, menuItemId, menuItemName, menuItemType,
                                            dataViewType, dataViewNote, dataTableName, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let parentMenuItemName = "Data Views";
                    if (menuItemType === "system-view") {
                        parentMenuItemName = "System Views";
                    }
                    if (menuItemType === "default-view") {
                        parentMenuItemName = "Default Views";
                    }
                    if (menuItemType === "report") {
                        parentMenuItemName = "Reports";
                    }

                    menuItemsObj.push({
                        contentPageType: "single-data-view",
                        menuItemName: menuItemName,
                        menuItemId: menuItemId,
                        dataViewId: dataViewId,
                        dataTableName: dataTableName,
                        dataViewType: dataViewType,
                        dataViewNote: dataViewNote,
                        menuItemType: menuItemType,
                        menuItemVersion: LeopardStaticUIConfig.LeftMenuItemVersion,
                        parentMenuItemName: parentMenuItemName
                    });

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateLeftMenuItemForDataView = (userId, organizationId, menuItemName, dataViewId,
                                            callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                    if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                        menuItemsObj = [];
                    }
                    let dataViewItem = null;
                    let menuItemsIndex = 0;
                    for (var i = 0; i < menuItemsObj.length; i++) {
                        if (!LDH.IsValueEmpty(menuItemsObj[i].menuItemType) &&
                            menuItemsObj[i].menuItemType !== "data-view" &&
                            menuItemsObj[i].menuItemType !== "system-view" &&
                            menuItemsObj[i].menuItemType !== "default-view" &&
                            menuItemsObj[i].menuItemType !== "report") {
                            continue;
                        }
                        if (menuItemsObj[i].dataViewId === dataViewId) {
                            dataViewItem = menuItemsObj[i];
                            menuItemsIndex = i;
                            break;
                        }
                    }
                    if (dataViewItem === null && callbackError !== undefined && callbackError !== null) {
                        callbackError({error: "Data view item not found"});
                    }
                    if (dataViewItem === null) return;

                    dataViewItem.menuItemVersion = LeopardStaticUIConfig.LeftMenuItemVersion;
                    dataViewItem.menuItemName = menuItemName;
                    menuItemsObj[menuItemsIndex] = dataViewItem;
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let appConfig = menuItemsObj;
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(dataViewItem);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteLeftMenuItemForDataView = (userId, organizationId, dataViewId, callbackSuccess,
                                            callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.DeleteDataView(organizationId, dataViewId, function () {
                    LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                        if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                            menuItemsObj = [];
                        }
                        for (var i = 0; i < menuItemsObj.length; i++) {
                            if (!LDH.IsValueEmpty(menuItemsObj[i].menuItemType) &&
                                menuItemsObj[i].menuItemType !== "data-view" &&
                                menuItemsObj[i].menuItemType !== "system-view" &&
                                menuItemsObj[i].menuItemType !== "default-view" &&
                                menuItemsObj[i].menuItemType !== "report") {
                                continue;
                            }
                            if (menuItemsObj[i].dataViewId === dataViewId) {
                                menuItemsObj.splice(i, 1);
                                break;
                            }
                        }
                        Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                        let appConfig = menuItemsObj;
                        let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                        url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                        API.put("aws-api-gateway", url, {
                            headers: LeopardAjaxHelper.GenericRequestHeaders(),
                            body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                        }).then(response => {
                            if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                                return;
                            }
                            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                                callbackSuccess(response);
                            }
                        }).catch(error => {
                            if (callbackError !== undefined && callbackError !== null) {
                                let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                                callbackError(error, sessionTimeout);
                            }
                        });
                    }, function (error) {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteLeftMenuItemForDashboard = (userId, organizationId, dashboardId, callbackSuccess,
                                             callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.DeleteDashboard(organizationId, dashboardId, function () {
                    LeopardAjaxHelper.GetLeftMenuItems(userId, function (menuItemsObj) {
                        if (menuItemsObj === null || menuItemsObj === "" || menuItemsObj.length === 0) {
                            menuItemsObj = [];
                        }
                        for (var i = 0; i < menuItemsObj.length; i++) {
                            if (menuItemsObj[i].menuItemType !== "dashboard") {
                                continue;
                            }
                            if (menuItemsObj[i].dashboardId === dashboardId) {
                                menuItemsObj.splice(i, 1);
                                break;
                            }
                        }
                        Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                        let appConfig = menuItemsObj;
                        let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                        url = url.replace("{configName}", "CC_Main_LeftMenuItems");

                        API.put("aws-api-gateway", url, {
                            headers: LeopardAjaxHelper.GenericRequestHeaders(),
                            body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(appConfig, false)}
                        }).then(response => {
                            if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                                return;
                            }
                            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                                callbackSuccess(response);
                            }
                        }).catch(error => {
                            if (callbackError !== undefined && callbackError !== null) {
                                let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                                callbackError(error, sessionTimeout);
                            }
                        });
                    }, function (error) {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateDashboard = (organizationId, dashboardName, dashboardNote, dashboardId,
                              dataViewIds, dashboardLayout, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Dashboard_" + dashboardId);

                let config = {
                    relationships: [],
                    dashboardName: dashboardName,
                    dashboardId: dashboardId,
                    dashboardLayout: dashboardLayout,
                    dashboardNote: dashboardNote,
                    contentPageType: "dashboard-view",
                    dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                };

                API.put("aws-api-gateway", url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders(),
                    body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        callbackError(error, false);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDashboard = (userId, organizationId, dashboardId, dashboardLayout, dashboardName,
                              callbackSuccess, callbackError, isResetRelationship) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    let dashboardLayoutFromDb = dashboard.dashboardLayout;

                    for (var i = 0; i < dashboardLayoutFromDb.length; i++) {
                        for (var j = 0; j < dashboardLayout.length; j++) {
                            if (dashboardLayoutFromDb[i].i === dashboardLayout[j].i) {
                                dashboardLayoutFromDb[i].definition = dashboardLayout[j].definition;
                                break;
                            }
                        }
                    }
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);
                    let relationships = isResetRelationship ? [] : dashboard.relationships;

                    let config = {
                        relationships: relationships,
                        dashboardName: dashboardName,
                        dashboardId: dashboard.dashboardId,
                        dashboardLayout: dashboardLayoutFromDb,
                        dashboardNote: dashboard.dashboardNote,
                        contentPageType: dashboard.contentPageType,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    };

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDashboardRelationship = (userId, organizationId, dashboardId, relationships,
                                          callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);
                    dashboard.relationships = relationships;

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(dashboard, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDashboardLayout = (userId, organizationId, dashboardId, dashboardLayout,
                                    callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    for (var i = 0; i < dashboard.dashboardLayout.length; i++) {
                        for (var j = 0; j < dashboardLayout.length; j++) {
                            if (dashboard.dashboardLayout[i].i === dashboardLayout[j].i) {
                                dashboardLayout[j].definition = dashboard.dashboardLayout[i].definition;
                                break;
                            }
                        }
                    }
                    Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_Dashboard_" + dashboard.dashboardId);

                    let config = {
                        relationships: dashboard.relationships,
                        dashboardName: dashboard.dashboardName,
                        dashboardId: dashboard.dashboardId,
                        dashboardLayout: dashboardLayout,
                        dashboardNote: dashboard.dashboardNote,
                        contentPageType: dashboard.contentPageType,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    };

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(response => {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(response);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static CreateDataView = (organizationId, dataViewName, dataViewType, dataViewNote, dataSourceUrl,
                             dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                let dataToSend = {
                    dataSourceUrl: dataSourceUrl,
                    dataViewName: dataViewName,
                    dataViewId: dataViewId,
                    dataViewType: dataViewType,
                    dataViewNote: dataViewNote,
                    contentPageType: "single-data-view",
                    dataViewConfigVersion: LeopardStaticUIConfig.DataViewConfigVersion
                };

                API.put("aws-api-gateway", url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders(),
                    body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(dataToSend, false)}
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        callbackError(error, false);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDataViewDefinition = (userId, organizationId, dataViewId, definition,
                                       callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    config.dataViewNote = definition;
                    config.dataViewConfigVersion = LeopardStaticUIConfig.DataViewConfigVersion;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateDataView = (userId, organizationId, dataViewName, dataViewId, definition, callbackSuccess,
                             callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    config.dataViewName = dataViewName;
                    config.dataViewConfigVersion = LeopardStaticUIConfig.DataViewConfigVersion;

                    if (config.dataViewType === "chart") {
                        let dataViewNote = config.dataViewNote;
                        dataViewNote.chartDefinition.chartTitle = dataViewName;
                        config.dataViewNote = dataViewNote;
                    }

                    if (config.dataViewType === "photo") {
                        config.dataViewNote = definition;
                    }

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateChartDefintion = (userId, organizationId, dataViewId, definition,
                                   callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    let dataViewNoteObj = config.dataViewNote;
                    dataViewNoteObj.chartDefinition = definition;
                    config.dataViewNote = dataViewNoteObj;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateGridViewDefintion = (userId, organizationId, dataViewId, definition,
                                      callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }

                    let dataViewNoteObj = config.dataViewNote;
                    dataViewNoteObj.gridViewDefinition = definition;
                    config.dataViewNote = dataViewNoteObj;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static UpdateGridViewStyle = (userId, organizationId, dataViewId, styleDefinition,
                                  callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    let dataViewNoteObj = config.dataViewNote;
                    dataViewNoteObj.styleDefinition = styleDefinition;
                    config.dataViewNote = dataViewNoteObj;

                    let url = window.UpdateGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                    url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                    API.put("aws-api-gateway", url, {
                        headers: LeopardAjaxHelper.GenericRequestHeaders(),
                        body: {AppConfig: LDH.ConvertJsonDataNullOrEmptyValues(config, false)}
                    }).then(function (response) {
                        if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                            return;
                        }
                        if (callbackSuccess !== undefined && callbackSuccess !== null) {
                            callbackSuccess(config);
                        }
                    }).catch(error => {
                        if (callbackError !== undefined && callbackError !== null) {
                            let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                            callbackError(error, sessionTimeout);
                        }
                    });
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetUserProfile = (userId, callbackSuccess, callbackError) => {
        // --------------------- For Test Mode Only --------------------------
        if (LRH.IsControlCentreInTestMode()) {
            let config = LeopardTestHelper.GetTestUserProfile();
            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                callbackSuccess(config);
            }
            return;
        }
        // ------------------------------------------------------------------

        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);
                let url = window.UserProfileUrlPath.replace("{userId}", userId);

                API.get("aws-api-gateway", url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        let msg = LDH.UnconvertJsonDataNullOrEmptyValues(response.message, false);
                        callbackSuccess(msg);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetAttachmentsByItemId = (itemId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                API.get('aws-api-gateway', window.AttachmentUrlPath + "/" + itemId, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }).then(response => {
                    if (!LDH.IsObjectNull(response) && !LDH.IsObjectNull(response.message) &&
                        !LDH.IsObjectNull(response.message.statusCode) &&
                        response.message.statusCode === "404") {
                        response = {message: []};
                    }
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    let orderedMessage = response.message.orderBy(function (d) {
                        return parseInt(LDH.IsValueEmpty(d.Order) ? "0" : d.Order);
                    });
                    response.message = orderedMessage;
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDataSourceList = (callbackSuccess, callbackError) => {
        let url = window.ODataAPIGatewayUrl + "/$metadata&authentication=true";
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LRH.SendAjaxForRetriveXmlFromReportDB(url, "get", function (rawJSON) {
                    let response = [];
                    for (var i = 0; i < rawJSON.elements[0].elements[0].elements.length; i++) {
                        let elemList = rawJSON.elements[0].elements[0].elements[i];
                        if (elemList.attributes.Namespace !== "OdataReportingService.Models") {
                            continue;
                        }
                        for (var j = 0; j < elemList.elements.length; j++) {
                            response.push({
                                id: elemList.elements[j].attributes.Name,
                                name: elemList.elements[j].attributes.Name,
                                group: "Data Tables",
                                category: "data-table"
                            });
                        }
                    }

                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        if (callbackError !== undefined && callbackError !== null) {
                            callbackError(response);
                        }
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDataViewById = (userId, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_DataView_" + dataViewId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDashboardById = (userId, dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LeopardAjaxHelper.GetUserProfile(userId, function (profile) {
                    let config = [];
                    let name = "CC_Dashboard_" + dashboardId;
                    for (var i = 0; i < profile.ParentGroup.Configs.length; i++) {
                        if (profile.ParentGroup.Configs[i].Name === name) {
                            config = profile.ParentGroup.Configs[i].AppConfig;
                            break;
                        }
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(config);
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteDataView = (organizationId, dataViewId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let url = window.DeleteGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_DataView_" + dataViewId);

                API.del('aws-api-gateway', url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static DeleteDashboard = (organizationId, dashboardId, callbackSuccess, callbackError) => {
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                Auth.configure(LeopardCognitoConfig.AmplifyAuthAndAPIConfig);

                let url = window.DeleteGroupConfigByNameUrlPath.replace("{groupId}", organizationId);
                url = url.replace("{configName}", "CC_Dashboard_" + dashboardId);

                API.del('aws-api-gateway', url, {
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }).then(response => {
                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess(response);
                    }
                }).catch(error => {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static GetDataTableColumnsByDataSourceUrl = (tableName, limitDataColumns, callbackSuccess, callbackError) => {
        // --------------------- For Test Mode Only --------------------------
        if (LRH.IsControlCentreInTestMode()) {
            let config = LeopardTestHelper.GetTestDataTableColumns();
            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                callbackSuccess({fullColumns: config, limitedColumns: config});
            }
            return;
        }
        // ------------------------------------------------------------------

        let url = window.ODataAPIGatewayUrl + "/$metadata&authentication=true";
        LeopardSecurity.GetCurrentAuthenticatedUser(function () {
                LRH.SendAjaxForRetriveXmlFromReportDB(url, "get", function (rawJSON) {
                    let response = [];
                    for (var i = 0; i < rawJSON.elements[0].elements[0].elements.length; i++) {
                        let elemList = rawJSON.elements[0].elements[0].elements[i];
                        if (elemList.attributes.Namespace !== "OdataReportingService.Models") {
                            continue;
                        }
                        for (var j = 0; j < elemList.elements.length; j++) {
                            if (elemList.elements[j].attributes.Name !== tableName) {
                                continue;
                            }
                            for (var k = 0; k < elemList.elements[j].elements.length; k++) {
                                let propertyElem = elemList.elements[j].elements[k];
                                let attrName = "";
                                if (propertyElem.name === "Key") {
                                    attrName = propertyElem.elements[0].attributes.Name;
                                }
                                if (propertyElem.name === "Property") {
                                    attrName = propertyElem.attributes.Name;
                                }

                                let foundElem = false;
                                for (var n = 0; n < response.length; n++) {
                                    if (response[n].toLowerCase() === attrName.toLowerCase()) {
                                        foundElem = true;
                                        break;
                                    }
                                }
                                if (foundElem === false) response.push(attrName);
                            }
                        }
                    }

                    let newData = [];
                    if (limitDataColumns !== undefined && limitDataColumns !== null &&
                        limitDataColumns.length > 0) {
                        for (var c = 0; c < response.length; c++) {
                            let found = false;
                            for (var v = 0; v < limitDataColumns.length; v++) {
                                if (limitDataColumns[v] === response[c]) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found === false) {
                                newData.push(response[c]);
                            }
                        }
                    } else {
                        newData = response;
                    }

                    if (LeopardAjaxHelper.IsResponseContainsError(response, callbackError)) {
                        if (callbackError !== undefined && callbackError !== null) {
                            callbackError({fullColumns: response, limitedColumns: newData}, false);
                        }
                        return;
                    }
                    if (callbackSuccess !== undefined && callbackSuccess !== null) {
                        callbackSuccess({fullColumns: response, limitedColumns: newData});
                    }
                }, function (error) {
                    if (callbackError !== undefined && callbackError !== null) {
                        let sessionTimeout = LeopardAjaxHelper.IsLoginSessionTimedOut(error);
                        callbackError(error, sessionTimeout);
                    }
                });
            },
            function (error) {
                LeopardAjaxHelper.HandleSessionTimeoutEvent(error);
            });
    };

    static AddSecurityHeadersToAjaxRequestForOdata() {
        XMLHttpRequest.prototype.open = (function (open) {
            return function (method, url, async) {
                if (!LDH.IsObjectNull(method) && !LDH.IsObjectNull(url) &&
                    url.toString().toLowerCase().indexOf("authentication=true") > -1) {
                    let resultUrl = LDH.AddMacroToString(url);
                    let resultUrlSplit = resultUrl.split("?");

                    if (resultUrlSplit.length > 1) {
                        let urlParams = resultUrlSplit[1];
                        let params = new URLSearchParams("?" + urlParams);
                        let newUrlParams = "";
                        let paramCount = 0;
                        for (var key of params.keys()) {
                            let dkey = key;
                            let dvalue = encodeURIComponent(params.get(key));

                            let symbol = "";
                            if (paramCount > 0 && dkey.toString().indexOf("$") === 0) {
                                symbol = "&";
                            }
                            dkey = encodeURIComponent(dkey);
                            newUrlParams += symbol + dkey + "=" + dvalue;
                            paramCount++;
                        }
                        var finalUrl = resultUrlSplit[0] + "?" + newUrlParams;
                        var argumentUrl = finalUrl.replace("?authentication=true", "");
                        argumentUrl = argumentUrl.replace("&authentication=true", "");
                        argumentUrl = argumentUrl.replace("authentication=true", "");
                        arguments[1] = argumentUrl;
                        window.httpRequestCache = {method: method, url: finalUrl};
                    } else {
                        var finalUrl2 = resultUrlSplit[0];
                        var argumentUrl2 = finalUrl2.replace("?authentication=true", "");
                        argumentUrl2 = argumentUrl2.replace("&authentication=true", "");
                        argumentUrl2 = argumentUrl2.replace("authentication=true", "");
                        arguments[1] = argumentUrl2;
                        window.httpRequestCache = {method: method, url: finalUrl2};
                    }
                }
                open.apply(this, arguments);
            };
        })(XMLHttpRequest.prototype.open);

        let send = XMLHttpRequest.prototype.send;
        XMLHttpRequest.prototype.send = function (data) {
            if (!LDH.IsObjectNull(window.userCredential) && !LDH.IsObjectNull(window.httpRequestCache) &&
                window.httpRequestCache.url.toLowerCase().indexOf("authentication=true")) {
                let argumentUrl = window.httpRequestCache.url.replace("?authentication=true", "");
                argumentUrl = argumentUrl.replace("&authentication=true", "");
                argumentUrl = argumentUrl.replace("authentication=true", "");

                let cred = {
                    accessKeyId: window.userCredential.accessKeyId,
                    region: LeopardCognitoConfig.AmplifyAuthAndAPIConfig.Auth.region,
                    secretAccessKey: window.userCredential.secretAccessKey,
                    token: window.userCredential.sessionToken,
                    url: argumentUrl,
                    method: window.httpRequestCache.method.toUpperCase()
                };
                window.httpRequestCache = null;

                let signedByAws = Signer.sign({
                    method: cred.method,
                    url: cred.url,
                    data: {},
                    headers: LeopardAjaxHelper.GenericRequestHeaders()
                }, {
                    access_key: cred.accessKeyId,
                    secret_key: cred.secretAccessKey,
                    session_token: cred.token
                }, {
                    service: "execute-api",
                    region: cred.region
                });

                this.setRequestHeader('Content-Type', "application/json");
                this.setRequestHeader('Cache-Control', "no-cache");
                this.setRequestHeader('Pragma', "no-cache");
                this.setRequestHeader('Expires', "Sat, 01 Jan 2000 00:00:00 GMT");
                this.setRequestHeader('Authorization', signedByAws.headers["Authorization"]);
                this.setRequestHeader('x-amz-date', signedByAws.headers["x-amz-date"]);
                this.setRequestHeader('X-Amz-Security-Token', signedByAws.headers["X-Amz-Security-Token"]);
            }
            send.call(this, data);
        };
    }
}

export default LeopardAjaxHelper;