import React from 'react';
import {Column} from 'devextreme-react/data-grid';
import {NumberBox, Button, TextBox} from 'devextreme-react';
import {DateBox} from 'devextreme-react/date-box';
import LDH from '../helpers/LeopardDataHelper';
import {HeaderFilter} from 'devextreme-react/tree-list';
import {Item} from 'devextreme-react/form';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardDesignerCountdownSwitch from '../datashaping/LeopardDesignerCountdownSwitch';
import LeopardTooltipWithLink from "./LeopardTooltipWithLink";
import LRH from "../helpers/LeopardReactHelper";

export const gridViewEditFormComponent = (e) => {
    let currentRow = LeopardStaticUIConfig.Global_GridViewCurrentEditRowData;
    if (LDH.IsObjectNull(currentRow) || LDH.IsObjectNull(currentRow.data)) {
        currentRow = null;
    }
    let data = currentRow === null ? "" : currentRow.data[e.name];
    if (currentRow === null && e.name === e.gridDefinition.dataSourceId) {
        data = LDH.GenerateGuidWithDashes();
    }
    if (LDH.IsValueEmpty(data)) data = "";

    if (e.dataType === "number") {
        return (<NumberBox disabled={e.disabled} defaultValue={data} showSpinButtons={true}></NumberBox>);
    }
    if (e.dataType === "date") {
        return (<DateBox disabled={e.disabled} defaultValue={data} type={'date'} width={"100%"}/>);
    }
    if (e.dataType === "datetime") {
        return (<DateBox disabled={e.disabled} defaultValue={data} type={'datetime'} width={"100%"}/>);
    }
    return (
        <TextBox disabled={e.disabled} defaultValue={data}></TextBox>
    );
};

export const LeopardGridViewColumnBuilder = (gridDefinition, columnFieldList, thisComp, currentState) => {
    let columnComponent = [];
    if (columnFieldList === undefined || columnFieldList === null || columnFieldList.length === 0) {
        return null;
    }
    for (var colIndex = 0; colIndex < columnFieldList.length; colIndex++) {
        let columnName = columnFieldList[colIndex];
        let columnDef = null;

        columnDef = gridDefinition.columnDefinition.filter(c => {
            return c.columnName === columnName;
        });
        if (columnDef !== undefined && columnDef !== null && columnDef.length > 0) {
            columnDef = columnDef[0];
        }

        let columnType = "string";
        if (columnDef !== null && columnDef.columnType !== undefined &&
            columnDef.columnType !== null && columnDef.columnType !== "") {
            columnType = columnDef.columnType;
        }

        let columnCustomHeader = columnDef.columnName;
        if (columnDef !== null && columnDef.columnCustomHeader !== undefined &&
            columnDef.columnCustomHeader !== null && columnDef.columnCustomHeader !== "") {
            columnCustomHeader = columnDef.columnCustomHeader;
        }

        let allowSorting = true;
        if (columnDef !== null && columnDef.allowSorting !== undefined &&
            columnDef.allowSorting !== null && columnDef.allowSorting !== "") {
            allowSorting = columnDef.allowSorting;
        }

        let allowFiltering = true;
        if (columnDef !== null && columnDef.allowFiltering !== undefined &&
            columnDef.allowFiltering !== null && columnDef.allowFiltering !== "") {
            allowFiltering = columnDef.allowFiltering;
        }

        let allowGrouping = true;
        if (columnDef !== null && columnDef.allowGrouping !== undefined &&
            columnDef.allowGrouping !== null && columnDef.allowGrouping !== "") {
            allowGrouping = columnDef.allowGrouping;
        }

        let allowReordering = true;
        if (columnDef !== null && columnDef.allowReordering !== undefined &&
            columnDef.allowReordering !== null && columnDef.allowReordering !== "") {
            allowReordering = columnDef.allowReordering;
        }

        let allowResizing = true;
        if (columnDef !== null && columnDef.allowResizing !== undefined &&
            columnDef.allowResizing !== null && columnDef.allowResizing !== "") {
            allowResizing = columnDef.allowResizing;
        }

        let isFixed = false;
        if (columnDef !== null && columnDef.isFixed !== undefined &&
            columnDef.isFixed !== null && columnDef.isFixed !== "") {
            isFixed = columnDef.isFixed;
        }

        let columnAlign = "left";
        if (columnDef !== null && columnDef.columnAlign !== undefined &&
            columnDef.columnAlign !== null && columnDef.columnAlign !== "") {
            columnAlign = columnDef.columnAlign;
        }

        let minimumWidth = "50";
        if (columnDef !== null && columnDef.minimumWidth !== undefined &&
            columnDef.minimumWidth !== null && columnDef.minimumWidth !== "") {
            minimumWidth = columnDef.minimumWidth;
        }

        let width = null;
        if (columnDef !== null && columnDef.width !== undefined &&
            columnDef.width !== null && columnDef.width !== "") {
            width = columnDef.width;
        }

        let groupIndex = null;
        if (columnDef !== null && columnDef.groupIndex !== undefined &&
            columnDef.groupIndex !== null && columnDef.groupIndex !== "") {
            groupIndex = columnDef.groupIndex;
        }

        let allowHeaderFilter = true;
        if (columnDef !== null && columnDef.allowHeaderFilter !== undefined &&
            columnDef.allowHeaderFilter !== null && columnDef.allowHeaderFilter !== "") {
            allowHeaderFilter = columnDef.allowHeaderFilter;
        }

        let sortOrder = null;
        if (columnDef !== null && columnDef.sortOrder !== undefined &&
            columnDef.sortOrder !== null && columnDef.sortOrder !== "") {
            sortOrder = columnDef.sortOrder;
        }

        let filterOperations = [];
        if (LDH.IsValueEmpty(columnType) || columnType === "string") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForString;
        } else if (columnType === "number") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "date") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "datetime") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForNonString;
        } else if (columnType === "boolean") {
            filterOperations = LeopardStaticUIConfig.FilterOperationsForBoolean;
        }
        if (columnDef !== null && columnDef.filterOperations !== undefined &&
            columnDef.filterOperations !== null && columnDef.filterOperations !== "") {
            filterOperations = columnDef.filterOperations;
        }

        let hidingPriority = columnFieldList.length - (colIndex + 1);
        if (gridDefinition.adaptiveColumn === undefined ||
            gridDefinition.adaptiveColumn === null ||
            gridDefinition.adaptiveColumn === false) {
            hidingPriority = null;
        }

        let cssClass = "leopard_gridview_column_template";
        if (allowSorting === true) {
            cssClass += " allow-sorting";
        } else {
            cssClass += " disallow-sorting";
        }

        let domain = LDH.APIEndpointAdapter();
        let url = thisComp.props.GetDataFromUrl.replace("?tableName=", "");

        if (columnType === "date") {
            columnComponent.push(
                <Column key={colIndex} dataField={columnName} dataType={columnType}
                        format="dd/MM/yyyy" cssClass={cssClass}
                        caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                        filterOperations={filterOperations}
                        allowSorting={allowSorting} allowFiltering={allowFiltering}
                        allowGrouping={allowGrouping} allowReordering={allowReordering}
                        allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                        minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                        sortOrder={sortOrder} hidingPriority={hidingPriority}>
                    <HeaderFilter allowSearch={true} dataSource={
                        LRH.InitCustomStoreForGridViewFilter(domain, url, columnName)
                    }/>
                </Column>
            );
        } else if (columnType === "datetime") {
            columnComponent.push(
                <Column key={colIndex} dataField={columnName} dataType={columnType}
                        format="dd/MM/yyyy HH:mm" cssClass={cssClass}
                        caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                        filterOperations={filterOperations}
                        allowSorting={allowSorting} allowFiltering={allowFiltering}
                        allowGrouping={allowGrouping} allowReordering={allowReordering}
                        allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                        minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                        sortOrder={sortOrder} hidingPriority={hidingPriority}>
                    <HeaderFilter allowSearch={true} dataSource={
                        LRH.InitCustomStoreForGridViewFilter(domain, url, columnName)
                    }/>
                </Column>
            );
        } else {
            columnComponent.push(
                <Column key={colIndex} dataField={columnName} dataType={columnType}
                        cssClass={cssClass}
                        caption={columnCustomHeader} allowHeaderFiltering={allowHeaderFilter}
                        filterOperations={filterOperations}
                        allowSorting={allowSorting} allowFiltering={allowFiltering}
                        allowGrouping={allowGrouping} allowReordering={allowReordering}
                        allowResizing={allowResizing} fixed={isFixed} alignment={columnAlign}
                        minWidth={minimumWidth} width={width} groupIndex={groupIndex}
                        sortOrder={sortOrder} hidingPriority={hidingPriority}>
                    <HeaderFilter allowSearch={true} dataSource={
                        LRH.InitCustomStoreForGridViewFilter(domain, url, columnName)
                    }/>
                </Column>
            );
        }
    }

    return columnComponent;
};

export const LeopardGridViewEditFormBuilder = (gridDefinition, columnFieldList, disabledFieldsForEditing,
                                               hiddenFieldsForEditing) => {
    let columnComponent = [];
    if (columnFieldList === undefined || columnFieldList === null || columnFieldList.length === 0) {
        return null;
    }
    for (var colIndex = 0; colIndex < columnFieldList.length; colIndex++) {
        let columnName = columnFieldList[colIndex];
        let columnDef = null;

        columnDef = gridDefinition.columnDefinition.filter(c => {
            return c.columnName === columnName;
        });
        if (columnDef !== undefined && columnDef !== null && columnDef.length > 0) {
            columnDef = columnDef[0];
        }

        let columnType = "string";
        if (columnDef !== null && LDH.IsValueEmpty(columnDef.columnType) === false) {
            columnType = columnDef.columnType;
        }

        let visible = true;
        if (!LDH.IsObjectNull(hiddenFieldsForEditing) &&
            hiddenFieldsForEditing.length > 0) {
            for (var i = 0; i < hiddenFieldsForEditing.length; i++) {
                if (hiddenFieldsForEditing[i] === columnName) {
                    visible = false;
                    break;
                }
            }
        }

        let disabled = false;
        if (!LDH.IsObjectNull(disabledFieldsForEditing) &&
            disabledFieldsForEditing.length > 0) {
            for (var j = 0; j < disabledFieldsForEditing.length; j++) {
                if (disabledFieldsForEditing[j] === columnName) {
                    disabled = true;
                    break;
                }
            }
        }

        columnComponent.push(
            <Item key={colIndex} dataField={columnName} dataType={columnType} visible={visible}
                  component={() => gridViewEditFormComponent({
                      name: columnName, gridDefinition: gridDefinition, disabled: disabled,
                      dataType: columnType
                  })}>
            </Item>
        );
    }

    return columnComponent;
};

export const LeopardGridViewToolbar =
    ({
         groupingButtonOnClick, groupingEnabled, addRowButtonOnClick, clearFilterButtonOnClick,
         viewOptionsButtonOnClick, minHeight, gridViewId, exportButtonOnClick, allowEditing,
         refreshButtonOnClick, applyFilterButtonOnClick, showAddButton, explicitFilterOption,
         gridDefinition, autoRefreshInterval, enableAutoRefresh, autoRefreshCountdownOnEnd,
         showAutoRefreshSwitch
     }) => {
        return (
            <div className={"leopard-gridview-toolbar"} style={{minHeight: minHeight}}>
                {
                    enableAutoRefresh === false ? "" :
                        <span id={"autoRefresh_" + gridViewId} className={"leopard-autorefresh-button_wrapper"}
                              style={{display: showAutoRefreshSwitch ? "block" : "none"}}>
                         <div id={"autoRefreshCountdownControl_" + gridViewId}>
                            <LeopardDesignerCountdownSwitch
                                autoRefreshCountdownOnEnd={autoRefreshCountdownOnEnd}
                                tooltip={"The grid view will be refreshed automatically when timer counts down to 0."}
                                autoRefreshInterval={autoRefreshInterval}
                                fieldValue={enableAutoRefresh} gridViewId={gridViewId}/>
                         </div>
                    </span>
                }
                <span id={"gridViewToobar_" + gridViewId} className="leopard-gridview-dataloading">
                    <i className="fas fa-spinner fa-pulse" style={{
                        color: "rgb(255, 128, 0)", fontSize: "18px"
                    }}></i>
                    <span className={"toolbar-warming-up-text"}>Warming up backend process...</span>
                </span>
                <span style={{padding: "0 2px 0 0"}}>
                     <LeopardTooltipWithLink
                         elementId={"GridView_TopBar_ClearFilter_" + gridViewId}
                         labelText={"Clear filter"} width={250} title={"Clear Filter"}
                         onClick={(e) => clearFilterButtonOnClick({e: e, gridViewId: gridViewId})}
                         text={"The Clear Filter button allows you to clear the filter criteria typed in the gridview's filter boxes."}/>
                </span>
                {
                    explicitFilterOption === "auto" ? "" :
                        <span style={{padding: "0 2px 0 0"}}>
                            <LeopardTooltipWithLink
                                elementId={"GridView_TopBar_ApplyFilter_" + gridViewId}
                                labelText={"Apply filter"} width={250}
                                title={"Apply Filter"}
                                onClick={(e) => applyFilterButtonOnClick({
                                    e: e,
                                    gridViewId: gridViewId
                                })}
                                text={"The Apply Filter button allows you to apply the filter criteria typed in the data view's filter boxes."}/>
                        </span>
                }
                <span style={{padding: "0 2px 0 0"}}>
                    <LeopardTooltipWithLink
                        elementId={"GridView_TopBar_Refresh_" + gridViewId}
                        labelText={"Refresh"} width={250} title={"Refresh"}
                        onClick={(e) => refreshButtonOnClick({
                            e: e,
                            gridViewId: gridViewId
                        })}
                        text={"The Refresh button allows you to refresh data and repaint the data view."}/>
                </span>
                {
                    gridDefinition.optimizePagerForLargeDataset === false ?
                        <span style={{padding: "0 2px 0 2px"}}>
                            <LeopardTooltipWithLink
                                elementId={"GridView_TopBar_Export_" + gridViewId}
                                labelText={"Export"} width={350} title={"Export"}
                                onClick={(e) => exportButtonOnClick({
                                    e: e,
                                    gridViewId: gridViewId
                                })}
                                text={"The data view makes it easy to export your data to an Excel file. Data is exported as it is " +
                                "displayed in the data view: sorting, filtering and grouping are applied."}/>
                        </span> : ""
                }
                <span style={{padding: "0 2px 0 2px"}}>
                    <LeopardTooltipWithLink
                        elementId={"GridView_TopBar_ViewOptions_" + gridViewId}
                        labelText={"Columns"} width={250} title={"Columns"}
                        onClick={(e) => viewOptionsButtonOnClick({
                            e: e,
                            gridViewId: gridViewId
                        })}
                        text={"This feature allows a user to hide or show columns in grid view at runtime."}/>
                </span>
                {   /* <span style={{ padding: "0 2px 0 2px" }}>
                        <Button text={'Group Panel'} className="leopard-buttonlink"
                            type={groupingEnabled ? "success" : "normal"}
                            id={"GridView_TopBar_GroupPanel_" + gridViewId}
                            onClick={(e) => groupingButtonOnClick({ e: e })} />
                        </span>
                   */}
                {
                    allowEditing === false || showAddButton === false ? "" :
                        <span style={{padding: "0 2px 0 2px"}}>
                            <Button text={'Add Record'} className="leopard-buttonlink"
                                    id={"GridView_TopBar_AddRecord_" + gridViewId}
                                    onClick={(e) => addRowButtonOnClick({e: e, gridViewId: gridViewId})}/>
                        </span>
                }
                <div className={"drag-and-drop-selection-count"} id={gridViewId + "_DragAndDropSelectionCount"}></div>
            </div>
        );
    };