import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import DataSource from 'devextreme/data/data_source';
import {Validator, RequiredRule} from 'devextreme-react/validator';
import LeopardMenuDataViewList from '../components/LeopardMenuDataViewList';
import LeopardMenuTemplateList from '../components/LeopardMenuTemplateList';
import {SetLeftMenuItem, KeepGridViewDataById, KeepChartDataById} from '../foundation/LeopardActionCreators';
import List from 'devextreme-react/list';
import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import {TextBox, Button, SelectBox, Switch, TagBox, FileUploader, TextArea, NumberBox} from 'devextreme-react';
import LeopardDataHelper from '../helpers/LeopardDataHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardTooltipWithIcon from "../datashaping/LeopardTooltipWithIcon";
import {Button as TextBoxButton} from "devextreme-react/text-box";
import LeopardMenuConfigItemBox from '../components/LeopardMenuConfigItemBox';

class LeopardMasterLeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = this.initializeDefaultState(false, false, []);

        this.dataViewMenuItems = null;
        this.systemViewMenuItems = null;
        this.defaultViewMenuItems = null;
        this.reportMenuItems = null;
        this.templateMenuItems = null;
        this.setLeftMenu = false;
        this.leftMenuItemToBeSelected = null;
        this.uiObjectInstance = [];
        this.isDataSourceLoaded = false;
        this.numberOfSeriesForChartText = "";
        this.isConfigItemBoxValueOnChangeDisabled = false;

        this.dataViewTypeList = [{
            id: "datagrid",
            name: "Data Grid"
        }, {
            id: "chart",
            name: "Chart"
        }, {
            id: "photo",
            name: "Photo Gallery"
        }];

        this.dateRangeSelectionForChart = [{
            id: "7days",
            name: "Last 7 days"
        }, {
            id: "14days",
            name: "Last 14 days"
        }, {
            id: "30days",
            name: "Last 30 days"
        }, {
            id: "lastMonth",
            name: "Last Month"
        }, {
            id: "currentMonth",
            name: "Current Month"
        }];

        this.reportTypeList = [{
            id: "report",
            name: "Standard"
        }];

        this.chartTypeList = [{
            id: "spline-chart",
            name: "Spline Chart"
        }, {
            id: "scatter-chart",
            name: "Scatter Chart"
        }, {
            id: "doughnut-chart",
            name: "Doughnut Chart"
        }, {
            id: "bar-chart",
            name: "Bar Chart"
        }, {
            id: "line-chart",
            name: "Line Chart"
        }, {
            id: "area-chart",
            name: "Area Chart"
        }];
    }

    // =============== Component Behaviours ==================

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        let that = this;
        let userProfile = this.props.state.userProfile;

        if (userProfile !== undefined && userProfile !== null &&
            this.state.isUserProfileLoaded === false) {
            this.setState({isUserProfileLoaded: true}, function () {
                that.loadLeftMenuItems(true, null, false);
            });
        }

        this.setLeftMenuItemToBeSelected();
    };

    componentWillUnmount = () => {
        this.dataViewMenuItems = null;
        this.systemViewMenuItems = null;
        this.defaultViewMenuItems = null;
        this.reportMenuItems = null;
        this.templateMenuItems = null;
        this.setLeftMenu = false;
        this.leftMenuItemToBeSelected = null;
        this.resetOptionPanelStates(false, false, [], [], []);
    };

    // ================ State Behaviours ===================

    initializeDefaultState = (leftMenuItemsInitialized, isUserProfileLoaded,
                              availableDataViewListForCreate, availableDataViewListForEdit,
                              availableReportListForEdit) => {
        return {
            showMenuOptionPanel: null,
            showChartTypeOption: false,
            showLoadingProgress: false,
            availableColumnNamesFromDataSourceWithoutBlank: [],
            validationInput: "",
            createDataViewType: "datagrid",
            createDataViewDataSourceUrl: 0,
            createDataViewDataSourceId: 0,
            createDataViewName: "",
            createDataViewChartType: "bar-chart",
            createDataViewChartValueField: null,
            createDataViewChartArgumentField: null,
            createDashboardName: "",
            availableDataSourceList: [],
            selectedDataSourceObject: null,
            createDataViewDataSourceURI: null,
            selectDateRangeForCreateChart: null,
            createDataViewChartDateRangeField: null,
            customQueryAttributesForChart: null,
            heightOfThumbnailForPhotoGallery: 140,
            widthOfThumbnailForPhotoGallery: 211,
            parentDataSourceIdForPhotoGallery: "",

            editDataViewType: "",
            editDataViewName: "",
            editDataViewId: "",
            selectedDisabledFieldsForDataView: [],
            selectedCommandButtonsForDataView: [],
            selectedHiddenFieldsForDataView: [],
            disableEditingConfigurationFields: true,
            isShowSelectDataSourceID: false,
            dataViewJSONToExport: null,
            dataViewListToImport: [],
            numberOfSeriesOnChart: [],
            dashboardEditManageRelationship: [],
            availableDataViewsOnDashboard: [],
            isResetDashboardRelationshipsOnSave: false,

            editDashboardId: "",
            editDashboardInitialized: false,
            selectedDataViewsForDashboard: [],
            isUserProfileLoaded: isUserProfileLoaded,
            leftMenuItemsInitialized: leftMenuItemsInitialized,
            availableDataViewListForCreate: availableDataViewListForCreate,
            availableDataViewListForEdit: availableDataViewListForEdit,
            availableReportListForEdit: availableReportListForEdit
        };
    };

    resetOptionPanelStates = (leftMenuItemsInitialized, isUserProfileLoaded,
                              availableDataViewListForCreate, availableDataViewListForEdit,
                              availableReportListForEdit) => {
        this.isDataSourceLoaded = false;
        this.setState(this.initializeDefaultState(leftMenuItemsInitialized,
            isUserProfileLoaded, availableDataViewListForCreate,
            availableDataViewListForEdit, availableReportListForEdit));
    };

    loadLeftMenuItems = (selectDefaultMenuItem, callbackSuccess, forceNotSelectMenu) => {
        let that = this;
        let userId = LeopardDataHelper.GetUserIdFromUserProfile(
            this.props.state.userProfile);

        LeopardAjaxHelper.GetLeftMenuItems(userId, function (items) {
            let filteredDataViewsList = [];
            let filteredSystemViewsList = [];
            let filteredDefaultViewsList = [];
            let filteredTemplateList = [];
            let filteredReportsList = [];
            let availableDataViewListForCreate = [{id: 0, name: "--- Empty ---", group: "Blank View"}];
            let availableDataViewListForEdit = [];
            let availableReportListForEdit = [];

            for (var i = 0; i < items.length; i++) {
                if (LDH.IsValueEmpty(items[i].menuItemType) ||
                    items[i].menuItemType === "data-view") {
                    filteredDataViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Data Views",
                        category: "data-view",
                        type: items[i].dataViewType
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Data Views",
                        category: "data-view",
                        type: items[i].dataViewType
                    });
                } else if (items[i].menuItemType === "system-view") {
                    filteredSystemViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "System Views",
                        category: "system-view",
                        type: items[i].dataViewType
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "System Views",
                        category: "system-view",
                        type: items[i].dataViewType
                    });
                } else if (items[i].menuItemType === "default-view") {
                    filteredDefaultViewsList.push(items[i]);

                    availableDataViewListForCreate.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Default Views",
                        category: "default-view",
                        type: items[i].dataViewType
                    });

                    availableDataViewListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Default Views",
                        category: "default-view",
                        type: items[i].dataViewType
                    });
                } else if (items[i].menuItemType === "dashboard") {
                    filteredTemplateList.push(items[i]);
                } else if (items[i].menuItemType === "report") {
                    filteredReportsList.push(items[i]);

                    availableReportListForEdit.push({
                        id: items[i].dataViewId,
                        name: items[i].menuItemName,
                        group: "Reports",
                        category: "report",
                        type: items[i].dataViewType
                    });
                }
            }

            that.setState({
                availableDataViewListForCreate: availableDataViewListForCreate,
                availableDataViewListForEdit: availableDataViewListForEdit,
                availableReportListForEdit: availableReportListForEdit,
                leftMenuItemsInitialized: true
            });

            that.dataViewMenuItems = filteredDataViewsList;
            that.systemViewMenuItems = filteredSystemViewsList;
            that.defaultViewMenuItems = filteredDefaultViewsList;
            that.reportMenuItems = filteredReportsList;
            that.templateMenuItems = filteredTemplateList;

            if (LDH.IsObjectNull(forceNotSelectMenu) || forceNotSelectMenu === false) {
                if (selectDefaultMenuItem === undefined || selectDefaultMenuItem) {
                    if (LDH.IsObjectNull(localStorage.getItem("LoadFirstMenuItem")) ||
                        LDH.IsValueEmpty(localStorage.getItem("LoadFirstMenuItem")) ||
                        LDH.IsValueTrue(localStorage.getItem("LoadFirstMenuItem"))) {
                        that.props.SetLeftMenuItem(items[0]);
                    } else {
                        localStorage.setItem("LoadFirstMenuItem", true);
                        that.props.SetLeftMenuItem(Object.create(null));
                    }
                } else {
                    let selectedItem = that.props.state.selectedLeftMenuItem;
                    if (selectedItem !== undefined && selectedItem !== null) {
                        that.props.SetLeftMenuItem(selectedItem);
                    } else {
                        if (LDH.IsObjectNull(localStorage.getItem("LoadFirstMenuItem")) ||
                            LDH.IsValueEmpty(localStorage.getItem("LoadFirstMenuItem")) ||
                            LDH.IsValueTrue(localStorage.getItem("LoadFirstMenuItem"))) {
                            that.props.SetLeftMenuItem(items[0]);
                        } else {
                            localStorage.setItem("LoadFirstMenuItem", true);
                            that.props.SetLeftMenuItem(Object.create(null));
                        }
                    }
                }
            }
            if (callbackSuccess !== undefined && callbackSuccess !== null) {
                callbackSuccess();
            }
        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to get left menu items.", "error", 5000);
            }
            that.setState({leftMenuItemsInitialized: true});
        });
    };

    setLeftMenuItemToBeSelected = () => {
        let item = this.leftMenuItemToBeSelected;
        if (this.setLeftMenu && this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null && this.leftMenuItemToBeSelected !== null &&
            this.props.state.selectedLeftMenuItem.menuItemId === null) {
            this.setLeftMenu = false;
            this.props.SetLeftMenuItem(item);
            this.leftMenuItemToBeSelected = null;
        }
    };

    // ==================== UI Events ========================

    displayMenuOptionByType(data) {
        let that = this;
        if (data !== undefined && data !== null && data.option === "add_template") {
            return this.buildMenuOptionPanelCreateTemplate();
        }

        if (data !== undefined && data !== null && (data.option === "edit_template" ||
            data.option === "manage_dashboard_relationship")) {
            if (that.state.editDashboardInitialized === false) {
                let profile = that.props.state.userProfile;
                let userId = LeopardDataHelper.GetUserIdFromUserProfile(profile);
                let dashboardId = that.state.editDashboardId;

                LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (dashboard) {
                    let dataViewList = [];
                    if (dashboard === [] || dashboard.length === 0) {
                        return;
                    }
                    if (LDH.IsObjectNull(dashboard.relationships)) {
                        dashboard.relationships = [];
                    }

                    for (var i = 0; i < dashboard.dashboardLayout.length; i++) {
                        let definition = dashboard.dashboardLayout[i].definition;
                        let menuItem = null;

                        for (var j = 0; j < that.dataViewMenuItems.length; j++) {
                            if (that.dataViewMenuItems[j].dataViewId === definition.dataViewId) {
                                menuItem = that.dataViewMenuItems[j];
                                break;
                            }
                        }

                        for (var v = 0; v < that.systemViewMenuItems.length; v++) {
                            if (that.systemViewMenuItems[v].dataViewId === definition.dataViewId) {
                                menuItem = that.systemViewMenuItems[v];
                                break;
                            }
                        }

                        for (var x = 0; x < that.defaultViewMenuItems.length; x++) {
                            if (that.defaultViewMenuItems[x].dataViewId === definition.dataViewId) {
                                menuItem = that.defaultViewMenuItems[x];
                                break;
                            }
                        }

                        for (var w = 0; w < that.reportMenuItems.length; w++) {
                            if (that.reportMenuItems[w].dataViewId === definition.dataViewId) {
                                menuItem = that.reportMenuItems[w];
                                break;
                            }
                        }

                        let dataViewItem = {
                            selectedValue: definition.dataViewName,
                            selectedId: definition.dataViewId,
                            dashboardItemId: definition.dashboardItemId,
                            combinedId: definition.dashboardItemId + ":" + definition.dataViewId,
                            customDataViewTitle: definition.customDataViewTitle,
                            index: parseInt(dashboard.dashboardLayout[i].i),
                            item: menuItem
                        };
                        dataViewList.push(dataViewItem);
                    }

                    that.setState({
                        selectedDataViewsForDashboard: dataViewList,
                        editDashboardInitialized: true,
                        dashboardEditManageRelationship: dashboard.relationships
                    }, function () {
                        that.props.updateWindowDimensionsRequired();
                    });
                }, function (error, sessionTimeout) {
                    if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) &&
                        !LDH.IsValueEmpty(error.message) &&
                        error.message.indexOf("Cannot read property '") !== -1 &&
                        error.message.indexOf("' of undefined") !== -1) {
                        that.createDashboardDeleteButtonOnClick("Your workspace could not be found. <br/>" +
                            "Do you want to delete the reference(s) to it from the database?");
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to initialize workspace settings. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                        that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                        that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);
                });
            }

            if (data.option === "edit_template") {
                return this.buildMenuOptionPanelEditTemplate();
            }
            if (data.option === "manage_dashboard_relationship") {
                return this.buildMenuOptionPanelManageDashboardRelationship();
            }
            return null;
        }

        if (data !== undefined && data !== null && data.option === "add_dataview_options") {
            return this.buildMenuOptionPanelDataViewOptions();
        }

        if (data !== undefined && data !== null && data.option === "edit_dashboard_options") {
            return this.buildMenuOptionPanelDashboardOptions();
        }

        if (data !== undefined && data !== null && data.option === "export_dataview") {
            return this.buildMenuOptionPanelExportDataView();
        }

        if (data !== undefined && data !== null && data.option === "import_dataview") {
            return this.buildMenuOptionPanelImportDataView();
        }

        if (data !== undefined && data !== null && data.option === "clone_dataview") {
            return this.buildMenuOptionPanelCloneDataView();
        }

        if (data !== undefined && data !== null && data.option === "add_dataview") {
            if (that.isDataSourceLoaded === false) {
                that.isDataSourceLoaded = true;
                setTimeout(function () {
                    that.setState({showLoadingProgress: true}, function () {
                        LeopardAjaxHelper.GetDataSourceList(function (result) {
                            let list = [];
                            if (that.state.showMenuOptionPanel.dataViewCategory === "default-view") {
                                for (var j = 0; j < result.length; j++) {
                                    list.push(result[j]);
                                }
                            } else {
                                for (var i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                                    let view = that.state.availableDataViewListForEdit[i];
                                    if (view.category === "default-view" &&
                                        view.type === that.state.createDataViewType) {
                                        list.push(that.state.availableDataViewListForEdit[i]);
                                    }
                                }
                            }
                            that.setState({availableDataSourceList: list, showLoadingProgress: false});
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve data sources from the database. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        });
                    });
                }, 100);
            }
            return this.buildMenuOptionPanelCreateDataView();
        }

        if (data !== undefined && data !== null && data.option === "edit_dataview") {
            return this.buildMenuOptionPanelEditDataView();
        }
        return null;
    }

    showMenuOptionPanelStatusOnChange = (option) => {
        let that = this;
        if (!LDH.IsObjectNull(option.option) && option.option === "edit_template") {
            if (option.currentOption === option.option) return;

            this.setState({showMenuOptionPanel: option}, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        if (!LDH.IsObjectNull(option.option) && option.option === "manage_dashboard_relationship") {
            if (option.currentOption === option.option) return;

            this.setState({showMenuOptionPanel: option}, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        let dataViewType = option.dataViewCategory === "report" ? "report" : "datagrid";
        if (LDH.IsValueEmpty(this.state.createDataViewType) ||
            option.dataViewCategory === "report") {
            this.setState({
                showMenuOptionPanel: option,
                selectedDataViewsForDashboard: [{
                    selectedValue: "none", index: 0, item: null,
                    selectedId: null, dashboardItemId: null,
                    customDataViewTitle: ""
                }],
                createDataViewType: dataViewType
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
            return;
        }

        this.setState({
            showMenuOptionPanel: option,
            selectedDataViewsForDashboard: [{
                selectedValue: "none", index: 0, item: null,
                selectedId: null, dashboardItemId: null,
                customDataViewTitle: ""
            }]
        }, function () {
            that.props.updateWindowDimensionsRequired();
        });
    };

    menuItemSettingsButtonOnClick = (option) => {
        let that = this;

        if (option.option === "edit_dataview") {
            this.setState({
                showMenuOptionPanel: option,
                editDataViewId: option.item.dataViewId,
                editDataViewName: option.item.menuItemName,
                editDataViewType: option.item.dataViewType,
                heightOfThumbnailForPhotoGallery: null,
                widthOfThumbnailForPhotoGallery: null,
                parentDataSourceIdForPhotoGallery: ""
            }, function () {
                that.props.updateWindowDimensionsRequired();

                if (option.item.dataViewType === "photo") {
                    that.setState({showLoadingProgress: true}, function () {
                        let userId = LeopardDataHelper.GetUserIdFromUserProfile(that.props.state.userProfile);
                        LeopardAjaxHelper.GetDataViewById(userId, option.item.dataViewId, function (result) {
                            let definition = result.dataViewNote.photoDefinition;
                            that.setState({
                                heightOfThumbnailForPhotoGallery: definition.heightOfThumbnail,
                                widthOfThumbnailForPhotoGallery: definition.widthOfThumbnail,
                                parentDataSourceIdForPhotoGallery: definition.parentDataSourceId,
                                showLoadingProgress: false
                            }, function () {
                                that.props.updateWindowDimensionsRequired();
                            });
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve Data View details. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        });
                    });
                }
            });
        } else if (option.option === "edit_dashboard_options") {
            this.setState({
                showMenuOptionPanel: option,
                editDashboardId: option.item.dashboardId,
                editDataViewName: option.item.menuItemName
            }, function () {
                that.props.updateWindowDimensionsRequired();
            });
        }
    };

    dataViewOrTemplateMenuItemOnClick = (data) => {
        let that = this;
        let allowProcess = true;

        $(".leopard-gridview-dataloading").each(function () {
            if ($(this).is(":visible") === true) {
                allowProcess = false;
            }
        })

        if (allowProcess === false) {
            LRH.ShowToast("You are not allowed to change the Data View or Workspace " +
                "while the data on the current view is still loading. Please try again later.", "error", 10000);
            return;
        }

        that.leftMenuItemToBeSelected = data.item;
        that.setLeftMenu = true;

        let emptyItem = {
            dataSourceUrl: null, contentPageType: null,
            menuItemId: null, dataViewType: null
        };

        that.props.SetLeftMenuItem(emptyItem);

        if (data.item !== undefined && data.item !== null) {
            let id = data.item.menuItemId;
            that.props.KeepGridViewDataById(id);
            that.props.KeepChartDataById(id);
        } else {
            that.props.KeepGridViewDataById("");
            that.props.KeepChartDataById("");
        }
    };

    selectDataViewCustomTitleOnChange = (data) => {
        let dataViews = this.state.selectedDataViewsForDashboard;
        let clonedDataViewList = LDH.DeepClone(dataViews);
        for (var i = 0; i < clonedDataViewList.length; i++) {
            if (clonedDataViewList[i].dashboardItemId === data.item.dashboardItemId) {
                clonedDataViewList[i].customDataViewTitle = data.e.value;
                break;
            }
        }
        this.setState({
            ...this.state,
            selectedDataViewsForDashboard: clonedDataViewList
        });
    };

    selectDataViewValueOnChange = (data) => {
        let dataViews = this.state.selectedDataViewsForDashboard;
        let clonedDataViewList = LDH.DeepClone(dataViews);
        let addNewEntry = true;

        for (var i = 0; i < clonedDataViewList.length; i++) {
            if (clonedDataViewList[i].index === data.index) {
                clonedDataViewList[i].selectedValue = "none";
                clonedDataViewList[i].selectedId = null;
                clonedDataViewList[i].item = null;

                for (var j = 0; j < this.dataViewMenuItems.length; j++) {
                    if (this.dataViewMenuItems[j].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.dataViewMenuItems[j];
                        clonedDataViewList[i].selectedId = this.dataViewMenuItems[j].dataViewId;
                        clonedDataViewList[i].selectedValue = this.dataViewMenuItems[j].menuItemName;
                        break;
                    }
                }

                for (var v = 0; v < this.systemViewMenuItems.length; v++) {
                    if (this.systemViewMenuItems[v].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.systemViewMenuItems[v];
                        clonedDataViewList[i].selectedId = this.systemViewMenuItems[v].dataViewId;
                        clonedDataViewList[i].selectedValue = this.systemViewMenuItems[v].menuItemName;
                        break;
                    }
                }

                for (var x = 0; x < this.defaultViewMenuItems.length; x++) {
                    if (this.defaultViewMenuItems[x].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.defaultViewMenuItems[x];
                        clonedDataViewList[i].selectedId = this.defaultViewMenuItems[x].dataViewId;
                        clonedDataViewList[i].selectedValue = this.defaultViewMenuItems[x].menuItemName;
                        break;
                    }
                }

                for (var w = 0; w < this.reportMenuItems.length; w++) {
                    if (this.reportMenuItems[w].dataViewId === data.e.value) {
                        clonedDataViewList[i].item = this.reportMenuItems[w];
                        clonedDataViewList[i].selectedId = this.reportMenuItems[w].dataViewId;
                        clonedDataViewList[i].selectedValue = this.reportMenuItems[w].menuItemName;
                        break;
                    }
                }
            }
            if (clonedDataViewList[i].selectedValue === "none") {
                addNewEntry = false;
            }
        }

        if (addNewEntry === false || this.state.showMenuOptionPanel.option === "edit_template") {
            this.setState({
                ...this.state,
                selectedDataViewsForDashboard: clonedDataViewList,
                isResetDashboardRelationshipsOnSave: true
            });
        } else {
            clonedDataViewList.push({
                selectedValue: "none",
                index: clonedDataViewList.length,
                item: null,
                selectedId: null
            });
            this.setState({
                ...this.state,
                selectedDataViewsForDashboard: clonedDataViewList,
                isResetDashboardRelationshipsOnSave: true
            });
        }
    };

    createDataViewSelectDataViewTypeOnValueChanged = (e) => {
        let that = this;
        let show = false;
        if (e.value !== null && e.value !== "" && e.value === "chart") {
            show = true;
        }

        let list = [];
        if (that.state.showMenuOptionPanel.dataViewCategory !== "default-view") {
            for (var i = 0; i < that.state.availableDataViewListForEdit.length; i++) {
                let view = that.state.availableDataViewListForEdit[i];
                if (view.type === e.value && view.category === "default-view") {
                    list.push(that.state.availableDataViewListForEdit[i]);
                }
            }
            this.setState({
                createDataViewType: e.value,
                showChartTypeOption: show,
                availableDataSourceList: list
            });
        } else {
            this.setState({
                createDataViewType: e.value,
                showChartTypeOption: show
            });
        }

        // ---------- Re-initialize DevExtreme validation -------------
        let random = LDH.GenerateGuid();
        this.uiObjectInstance.validationInput.option("value", random);
        // ------------------------------------------------------------
    };

    createDataViewSelectChartTypeOnValueChanged = (e) => {
        this.setState({createDataViewChartType: e.value});
    };

    createDataViewValueFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartValueField: e.value});
    };

    createDataViewChartDateRangeFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartDateRangeField: e.value});
    };

    selectDateRangeForCreateChartOnValueChanged = (e) => {
        this.setState({selectDateRangeForCreateChart: e.value});
    };

    createDataViewArgumentFieldOnValueChanged = (e) => {
        this.setState({createDataViewChartArgumentField: e.value});
    };

    createDataViewDataSourceUrlOnValueChanged = (e) => {
        let that = this;
        let selectedDataSource = null;

        for (let i = 0; i < this.state.availableDataSourceList.length; i++) {
            if (this.state.availableDataSourceList[i].id === e.value) {
                let source = this.state.availableDataSourceList[i];
                selectedDataSource = source;
                this.setState({selectedDataSourceObject: source});
            }
        }

        if (!LDH.IsObjectNull(selectedDataSource) && selectedDataSource.category === "data-table") {
            this.setState({createDataViewDataSourceUrl: e.value, showLoadingProgress: true});
            let tableName = e.value;

            LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(tableName, [], function (data) {
                let dataSourceIds = [];
                for (var i = 0; i < data.fullColumns.length; i++) {
                    dataSourceIds.push({id: data.fullColumns[i], name: data.fullColumns[i]});
                }
                that.setState({
                    availableColumnNamesFromDataSourceWithoutBlank: dataSourceIds,
                    showLoadingProgress: false,
                    selectedDisabledFieldsForDataView: [],
                    selectedHiddenFieldsForDataView: []
                });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve data for your data source. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        } else {
            that.setState({createDataViewDataSourceUrl: e.value, showLoadingProgress: true}, function () {
                let userId = LeopardDataHelper.GetUserIdFromUserProfile(that.props.state.userProfile);

                LeopardAjaxHelper.GetDataViewById(userId, e.value, function (result) {
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve Data View details. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
                });
            });
        }
    };

    createDataViewDataSourceIdOnValueChanged = (e) => {
        this.setState({createDataViewDataSourceId: e.value});
    };

    createDataViewNameOnValueChanged = (e) => {
        this.setState({createDataViewName: e.value});
    };

    heightOfThumbnailOnValueChanged = (e) => {
        this.setState({heightOfThumbnailForPhotoGallery: e.value});
    };

    widthOfThumbnailOnValueChanged = (e) => {
        this.setState({widthOfThumbnailForPhotoGallery: e.value});
    };

    parentDataSourceIdOnValueChanged = (e) => {
        this.setState({parentDataSourceIdForPhotoGallery: e.value});
    };

    customQueryAttributesOnValueChanged = (e) => {
        this.setState({customQueryAttributesForChart: e.value});
    }

    numberOfSeriesForChartOnValueChanged = (e) => {
        this.numberOfSeriesForChartText = e.value;
    }

    createDataViewDataSourceURIOnValueChanged = (e) => {
        this.setState({createDataViewDataSourceURI: e.value});
    };

    createDashboardNameInputOnValueChanged = (e) => {
        this.setState({createDashboardName: e.value});
    };

    validationInputOnValueChanged = (e) => {
        this.setState({validationInput: e.value});
    };

    editDataViewNameOnValueChanged = (e) => {
        this.setState({editDataViewName: e.value});
    };

    customValidationRuleCallback = (data) => {
        let instances = this.uiObjectInstance;
        return LRH.BindValidationRulesToUIObject(data, instances);
    };

    dataEditingCheckboxOnValueChanged = (e) => {
        this.setState({disableEditingConfigurationFields: !e.value});
    };

    setInputFieldInstance = (data) => {
        if (data.e === undefined || data.e === null || data.e.instance === null) {
            return;
        }
        let instances = this.uiObjectInstance;
        instances[data.input] = data.e.instance;
    };

    numberOfSeriesOnChartTemplate = (item) => {
        return (<div>{item.name}</div>);
    };

    // ==================== Data Views =======================

    cloneDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (LDH.IsObjectNull(this.state.dataViewJSONToExport) ||
            LDH.IsValueEmpty(this.state.dataViewJSONToExport.dataViewName)) {
            LRH.ShowToast("Please select a data view first.", "error", 5000);
            return;
        }

        that.setState({showLoadingProgress: true}, function () {
            let dataToImport = that.state.dataViewJSONToExport;
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            dataToImport.dataViewId = LDH.GenerateGuid();

            LeopardAjaxHelper.CreateDataView(organizationId, that.state.createDataViewName,
                dataToImport.dataViewType, dataToImport.dataViewNote, dataToImport.dataSourceUrl,
                dataToImport.dataViewId, function () {
                    // Then create menu item for that data view.
                    LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId,
                        LDH.GenerateGuid(), that.state.createDataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                        dataToImport.dataViewType, {chartType: ""}, dataToImport.dataSourceUrl, dataToImport.dataViewId, function () {
                            LRH.ShowToast("Your Data View has been successfully cloned.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);

                            that.loadLeftMenuItems(false, null, false);
                            that.setState({showLoadingProgress: false});
                        }, function () {
                            LRH.ShowToast("Failed to update the left-hand side menu item. " +
                                "Please check your network connection status and try again.", "error", 5000);
                            LeopardAjaxHelper.DeleteDataView(organizationId,
                                dataToImport.dataViewId, function () {
                                    return;
                                });
                            that.setState({showLoadingProgress: false});
                        });
                }, function () {
                    LRH.ShowToast("Failed to clone your Data View. " +
                        "Please check your network connection status and try again.", "error", 5000);
                    that.setState({showLoadingProgress: false});
                });
        });
    }

    exportDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();

        if (LDH.IsObjectNull(this.state.dataViewJSONToExport) ||
            LDH.IsValueEmpty(this.state.dataViewJSONToExport.dataViewName)) {
            LRH.ShowToast("Please select a data view first.", "error", 5000);
            return;
        }

        let dataToExport = JSON.stringify(this.state.dataViewJSONToExport);
        let dataView = this.state.dataViewJSONToExport.dataViewName + ".json";
        LRH.DownloadStringToFile(dataView, dataToExport);
        this.createDataViewCancelButtonOnClickWithoutConfirm();
    }

    importDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if (LDH.IsObjectNull(that.state.dataViewListToImport) ||
            that.state.dataViewListToImport.length === 0) {
            LRH.ShowToast("Please select at least one file to import.", "error", 5000);
            return;
        }

        that.setState({showLoadingProgress: true}, function () {
            try {
                let userProfile = that.props.state.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                for (var i = 0; i < that.state.dataViewListToImport.length; i++) {
                    let dataToImport = JSON.parse(that.state.dataViewListToImport[i]);
                    if (LDH.IsValueEmpty(dataToImport.dataViewName) || LDH.IsValueEmpty(dataToImport.dataViewType) ||
                        LDH.IsValueEmpty(dataToImport.dataSourceUrl) || LDH.IsValueEmpty(dataToImport.dataViewId)) {
                        LRH.ShowToast("Unable to import Data View. The data you specified is invalid.", "error", 5000);
                        that.setState({showLoadingProgress: false});
                        return;
                    }
                }
                that.recurrsiveDataViewImportProcess(userId, organizationId, 0, that.state.dataViewListToImport);
            } catch {
                LRH.ShowToast("Unable to import Data View. Please check for the data format before proceeding.", "error", 5000);
                that.setState({showLoadingProgress: false});
            }
        });
    }

    recurrsiveDataViewImportProcess = (userId, organizationId, processedIndex, dataViewListToImport) => {
        let that = this;
        let dataToImport = JSON.parse(dataViewListToImport[processedIndex]);
        dataToImport.dataViewId = LDH.GenerateGuid();

        LeopardAjaxHelper.CreateDataView(organizationId, dataToImport.dataViewName,
            dataToImport.dataViewType, dataToImport.dataViewNote, dataToImport.dataSourceUrl,
            dataToImport.dataViewId, function () {
                // Then create menu item for that data view.
                LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId, LDH.GenerateGuid(),
                    dataToImport.dataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                    dataToImport.dataViewType, {chartType: ""}, dataToImport.dataSourceUrl,
                    dataToImport.dataViewId, function () {
                        if (that.state.dataViewListToImport.length === processedIndex + 1) {
                            LRH.ShowToast("Your Data Views have been successfully imported.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);

                            that.loadLeftMenuItems(false, null, false);
                            that.setState({showLoadingProgress: false});
                        }
                        if (dataViewListToImport.length > processedIndex + 1) {
                            that.recurrsiveDataViewImportProcess(userId, organizationId, processedIndex + 1,
                                dataViewListToImport);
                        }
                    }, function () {
                        LRH.ShowToast("Failed to update the left-hand side menu item. " +
                            "Please check your network connection status and try again.", "error", 5000);
                        LeopardAjaxHelper.DeleteDataView(organizationId,
                            dataToImport.dataViewId, function () {
                                return;
                            });
                        that.setState({showLoadingProgress: false});
                    });
            }, function () {
                LRH.ShowToast("Failed to import your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
                that.setState({showLoadingProgress: false});
            });
    }

    createDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        if ((this.state.createDataViewType === "chart" && this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
            (that.state.selectedDataSourceObject !== null && that.state.selectedDataSourceObject.category === "data-table" &&
                this.state.createDataViewType === "datagrid") || this.state.showMenuOptionPanel.dataViewCategory === "report" ||
            this.state.createDataViewType === "photo") {
            that.setState({showLoadingProgress: true}, function () {
                let dataViewId = LDH.GenerateGuid();
                let userProfile = that.props.state.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                let definition = null;
                let devExtremeChartType = null;

                if (that.state.createDataViewChartType === "bar-chart") {
                    devExtremeChartType = "bar";
                }
                if (that.state.createDataViewChartType === "line-chart") {
                    devExtremeChartType = "line";
                }
                if (that.state.createDataViewChartType === "spline-chart") {
                    devExtremeChartType = "spline";
                }
                if (that.state.createDataViewChartType === "scatter-chart") {
                    devExtremeChartType = "scatter";
                }
                if (that.state.createDataViewChartType === "area-chart") {
                    devExtremeChartType = "area";
                }

                if (that.state.createDataViewType === "chart") {
                    definition = {
                        chartType: that.state.createDataViewChartType,
                        chartDefinition: {
                            ...LeopardStaticUIConfig.ChartDefaultDefinition,
                            chartTitle: that.state.createDataViewName,
                            seriesArgumentField: that.state.createDataViewChartArgumentField,
                            seriesValueField: that.state.createDataViewChartValueField,
                            dateRangeValue: that.state.selectDateRangeForCreateChart,
                            dateRangeField: that.state.createDataViewChartDateRangeField,
                            customQueryAttributes: that.state.customQueryAttributesForChart,
                            seriesTypeOfSeries: devExtremeChartType,
                            numberOfSeriesOnChart: that.state.numberOfSeriesOnChart
                        }
                    };
                }

                if (that.state.createDataViewType === "datagrid") {
                    definition = {
                        gridViewDefinition: LeopardStaticUIConfig.GridViewDefaultDefinition,
                        styleDefinition: null
                    };

                    let userProfile = that.props.state.userProfile;
                    let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
                    let defaultFilter = LeopardStaticUIConfig.GridView_DefaultFilterValue;

                    if (LDH.IsValueEmpty(defaultFilter) === false && defaultFilter.length >= 3) {
                        defaultFilter[2] = defaultFilter[2].replace("[VALUE]", organizationId);
                    }
                    definition.gridViewDefinition.filterBuilderValue = defaultFilter;
                    definition.gridViewDefinition.hiddenFieldsForEditing = that.state.selectedHiddenFieldsForDataView;
                    definition.gridViewDefinition.disabledFieldsForEditing = that.state.selectedDisabledFieldsForDataView;
                    definition.gridViewDefinition.commandButtons = that.state.selectedCommandButtonsForDataView;
                    definition.gridViewDefinition.dataSourceId = that.state.createDataViewDataSourceId;
                    definition.gridViewDefinition.dataSourceURI = that.state.createDataViewDataSourceURI;
                }

                if (that.state.createDataViewType === "report") {
                    definition = {
                        reportLayout: [],
                        reportSettings: []
                    };
                }

                if (that.state.createDataViewType === "photo") {
                    definition = {
                        photoDefinition: {
                            heightOfThumbnail: that.state.heightOfThumbnailForPhotoGallery,
                            widthOfThumbnail: that.state.widthOfThumbnailForPhotoGallery,
                            parentDataSourceId: that.state.parentDataSourceIdForPhotoGallery
                        }
                    };
                }

                LeopardAjaxHelper.CreateDataView(organizationId, that.state.createDataViewName,
                    that.state.createDataViewType, definition, that.state.createDataViewDataSourceUrl,
                    dataViewId, function () {
                        LeopardAjaxHelper.CreateLeftMenuItemForDataView(userId, organizationId, LDH.GenerateGuid(),
                            that.state.createDataViewName, that.state.showMenuOptionPanel.dataViewCategory,
                            that.state.createDataViewType, {chartType: that.state.createDataViewChartType},
                            that.state.createDataViewDataSourceUrl, dataViewId, function () {
                                LRH.ShowToast("Your Data View has been successfully created.", "success", 5000);

                                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);

                                that.loadLeftMenuItems(false, null, false);
                                that.setState({showLoadingProgress: false});
                            }, function () {
                                LRH.ShowToast("Failed to update the left-hand side menu item. " +
                                    "Please check your network connection status and try again.", "error", 5000);

                                LeopardAjaxHelper.DeleteDataView(organizationId, dataViewId, function () {
                                    return;
                                });
                                that.setState({showLoadingProgress: false});
                            });
                    }, function () {
                        LRH.ShowToast("Failed to create your Data View. " +
                            "Please check your network connection status and try again.", "error", 5000);
                        that.setState({showLoadingProgress: false});
                    });
            });
        } else {
            that.cloneDataViewApplyButtonOnClick(e);
        }
    };

    createDataViewDeleteButtonOnClick = () => {
        let that = this;

        LRH.ShowDialog("Are you sure you want to delete this Data View? <br/>This will impact any Workspaces that are linked to it.",
            "Confirm Delete", function () {
                that.setState({showLoadingProgress: true}, function () {
                    let dataViewId = that.state.editDataViewId;
                    let userProfile = that.props.state.userProfile;
                    let userId = LDH.GetUserIdFromUserProfile(userProfile);
                    let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                    LeopardAjaxHelper.DeleteLeftMenuItemForDataView(userId, organizationId,
                        dataViewId, function () {
                            LRH.ShowToast("Your Data View has been successfully deleted.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);

                            that.loadLeftMenuItems(true, null, false);
                            that.dataViewOrTemplateMenuItemOnClick({item: null});
                            that.setState({showLoadingProgress: false});
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to delete your Data View. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        });
                });
            });
    };

    manageDashboardRelationshipApplyOnClick = (e) => {
        e.preventDefault();
        let that = this;
        let isValid = true;

        let allowProcess = true;
        $(".leopard-gridview-dataloading").each(function () {
            if ($(this).is(":visible") === true) {
                allowProcess = false;
            }
        })
        if (allowProcess === false) {
            LRH.ShowToast("You are not allowed to edit the Data View or Workspace " +
                "while the data on the current view is still loading. Please try again later.", "error", 10000);
            return;
        }

        $(".leopard-option-panel-block.error").each(function () {
            if ($(this).is(":visible")) isValid = false;
        });
        if (isValid === false) {
            LRH.ShowToast("Failed to save your data. Please correct the errors before proceeding.", "error", 5000);
            return;
        }

        for (var i = 0; i < that.state.dashboardEditManageRelationship.length; i++) {
            let relationship = that.state.dashboardEditManageRelationship[i];
            if (LDH.IsValueEmpty(relationship.parentDataViewId) ||
                LDH.IsValueEmpty(relationship.childDataViewId)) {
                LRH.ShowToast("Failed to save your data. Please check for your configuration settings.", "error", 5000);
                return;
            }
        }

        that.setState({showLoadingProgress: true}, function () {
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let relationship = that.state.dashboardEditManageRelationship;
            let dashboardId = that.state.editDashboardId;

            LeopardAjaxHelper.UpdateDashboardRelationship(userId, organizationId, dashboardId, relationship, function () {
                // Then update menu item for that dashboard.
                LeopardAjaxHelper.UpdateLeftMenuItemForDashboard(userId, organizationId, that.state.editDataViewName,
                    that.state.editDashboardId, function (menuItem) {
                        LRH.ShowToast("Your workspace has been successfully updated.", "success", 5000);
                        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);

                        that.loadLeftMenuItems(false, function () {
                            that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                            that.setState({showLoadingProgress: false});
                        }, true);
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to update your workspace. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        that.setState({showLoadingProgress: false});
                    });
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to update your workspace. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        });
    }

    editDataViewApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        this.setState({showLoadingProgress: true}, function () {
            let dataViewId = that.state.editDataViewId;
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            let definition = null;
            if (that.state.editDataViewType === "photo") {
                definition = {
                    photoDefinition: {
                        heightOfThumbnail: that.state.heightOfThumbnailForPhotoGallery,
                        widthOfThumbnail: that.state.widthOfThumbnailForPhotoGallery,
                        parentDataSourceId: that.state.parentDataSourceIdForPhotoGallery
                    }
                };
            }

            // Update data view first.
            LeopardAjaxHelper.UpdateDataView(userId, organizationId, that.state.editDataViewName, dataViewId,
                definition, function () {
                    LeopardAjaxHelper.UpdateLeftMenuItemForDataView(userId, organizationId,
                        that.state.editDataViewName, dataViewId, function (menuItem) {
                            LRH.ShowToast("Your Data View has been successfully updated.", "success", 5000);

                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);

                            that.loadLeftMenuItems(false, function () {
                                that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                that.setState({showLoadingProgress: false});
                            }, false);
                        }, function () {
                            LRH.ShowToast("Failed to update your Data View. " +
                                "Please check your network connection status and try again.", "error", 5000);
                            that.setState({showLoadingProgress: false});
                        });
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to update your Data View. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                });
        });
    };

    createDataViewCancelButtonOnClick = () => {
        let that = this;
        LRH.ShowDialog("Unsaved data will be lost. Do you want to continue?",
            "Confirm Cancel", function () {
                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);
            });
    };

    createDataViewCancelButtonOnClickWithoutConfirm = () => {
        let that = this;
        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);
    };

    // ==================== Dashboards =======================

    createDashboardCancelButtonOnClick = () => {
        let that = this;
        LRH.ShowDialog("Unsaved data will be lost. Do you want to continue?",
            "Confirm Cancel", function () {
                that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                    that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                    that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);
            });
    };

    createDashboardApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        let allowProcess = true;
        $(".leopard-gridview-dataloading").each(function () {
            if ($(this).is(":visible") === true) {
                allowProcess = false;
            }
        })
        if (allowProcess === false) {
            LRH.ShowToast("You are not allowed to create a Data View or Workspace " +
                "while the data on the current view is still loading. Please try again later.", "error", 10000);
            return;
        }

        that.setState({showLoadingProgress: true}, function () {
            let dashboardId = LDH.GenerateGuid();
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            let dataViewIds = [];
            let dashboardLayout = [];
            let xValue = -72;
            let yValue = 0;

            for (var i = 0; i < this.state.selectedDataViewsForDashboard.length; i++) {
                let dataView = this.state.selectedDataViewsForDashboard[i];
                if (dataView.selectedValue === "none" || dataView.item === null) continue;
                dataViewIds.push({dataViewId: dataView.selectedValue, index: dataView.index});

                if (xValue + 72 > 144) {
                    xValue = 0;
                    yValue += 1;
                } else {
                    xValue += 72;
                }

                dashboardLayout.push({
                    i: i.toString(), x: xValue, y: yValue, w: 72, h: 36, minW: 18, minH: 12, definition: {
                        dashboardType: dataView.item.dataViewType,
                        dataViewId: dataView.item.dataViewId,
                        dataViewName: dataView.item.menuItemName,
                        color: "leopard-bgcolor-gray",
                        dashboardItemId: LDH.GenerateGuid(),
                        dataViewNote: dataView.item.dataViewNote,
                        dataSourceUrl: dataView.item.dataSourceUrl,
                        customDataViewTitle: dataView.customDataViewTitle,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    }
                });
            }
            if (dataViewIds.length === 0 || dashboardLayout.length === 0) {
                LRH.ShowToast("You must add at least one Data View for your workspace.", "error", 5000);
                that.setState({showLoadingProgress: false});
                return;
            }

            LeopardAjaxHelper.CreateDashboard(organizationId, this.state.createDashboardName, null,
                dashboardId, dataViewIds, dashboardLayout, function () {
                    // Then create menu item for that data view.
                    LeopardAjaxHelper.CreateLeftMenuItemForDashboard(userId, organizationId, LDH.GenerateGuid(),
                        that.state.createDashboardName, dashboardId, function () {
                            LRH.ShowToast("Your workspace has been successfully created.", "success", 5000);
                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);

                            that.loadLeftMenuItems(false, null, false);
                            that.setState({showLoadingProgress: false});
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to create your workspace. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            LeopardAjaxHelper.DeleteDashboard(organizationId, dashboardId, function () {
                                return;
                            });
                            that.setState({showLoadingProgress: false});
                        });
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to create your workspace. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                });
        });
    };

    editDashboardSaveSettings = (e) => {
        let that = this;

        that.setState({showLoadingProgress: true}, function () {
            let userProfile = that.props.state.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let dataViewIds = [];
            let dashboardLayout = [];

            for (var i = 0; i < this.state.selectedDataViewsForDashboard.length; i++) {
                let dataView = this.state.selectedDataViewsForDashboard[i];
                if (dataView.selectedValue === "none" || dataView.item === null) continue;
                dataViewIds.push({dataViewId: dataView.selectedValue, index: dataView.index});

                if (LDH.IsObjectNull(dataView.dashboardItemId) || LDH.IsValueEmpty(dataView.dashboardItemId)) {
                    dataView.dashboardItemId = LDH.GenerateGuid();
                }

                dashboardLayout.push({
                    i: i.toString(), definition: {
                        dashboardType: dataView.item.dataViewType,
                        dataViewId: dataView.item.dataViewId,
                        dataViewName: dataView.item.menuItemName,
                        color: "leopard-bgcolor-gray",
                        dashboardItemId: dataView.dashboardItemId,
                        dataViewNote: dataView.item.dataViewNote,
                        dataSourceUrl: dataView.item.dataSourceUrl,
                        customDataViewTitle: dataView.customDataViewTitle,
                        dashboardVersion: LeopardStaticUIConfig.DashboardConfigVersion
                    }
                });
            }
            if (dataViewIds.length === 0 || dashboardLayout.length === 0) {
                LRH.ShowToast("You must add at least one Data View for your workspace.", "error", 5000);
                that.setState({showLoadingProgress: false});
                return;
            }

            LeopardAjaxHelper.UpdateDashboard(userId, organizationId, that.state.editDashboardId, dashboardLayout,
                that.state.editDataViewName, function () {
                    // Then update menu item for that dashboard.
                    LeopardAjaxHelper.UpdateLeftMenuItemForDashboard(userId, organizationId, that.state.editDataViewName,
                        that.state.editDashboardId, function (menuItem) {
                            LRH.ShowToast("Your workspace has been successfully updated.", "success", 5000);
                            that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                                that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                                that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);

                            that.loadLeftMenuItems(false, function () {
                                that.dataViewOrTemplateMenuItemOnClick({item: menuItem, selected: "selected"});
                                that.setState({showLoadingProgress: false});
                            }, true);
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to update your workspace. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            that.setState({showLoadingProgress: false});
                        });
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to update your workspace. " +
                            "Please check your network connection status and try again.", "error", 5000);
                    }
                    that.setState({showLoadingProgress: false});
                }, that.state.isResetDashboardRelationshipsOnSave);
        });
    };

    editDashboardApplyButtonOnClick = (e) => {
        e.preventDefault();
        let that = this;

        let allowProcess = true;
        $(".leopard-gridview-dataloading").each(function () {
            if ($(this).is(":visible") === true) {
                allowProcess = false;
            }
        })
        if (allowProcess === false) {
            LRH.ShowToast("You are not allowed to edit the Data View or Workspace " +
                "while the data on the current view is still loading. Please try again later.", "error", 10000);
            return;
        }

        if (this.state.isResetDashboardRelationshipsOnSave) {
            LRH.ShowDialog("You have changed the structure of your workspace, this will reset all the relationships of the workspace." +
                "<br/>Are you sure you want to continue?", "Confirm Update", function () {
                that.editDashboardSaveSettings(e);
            });
        } else {
            this.editDashboardSaveSettings(e);
        }
    };

    createDashboardDeleteButtonOnClick = (dialogMessage, dashboardIdToDelete) => {
        let that = this;

        let allowProcess = true;
        $(".leopard-gridview-dataloading").each(function () {
            if ($(this).is(":visible") === true) {
                allowProcess = false;
            }
        })
        if (allowProcess === false) {
            LRH.ShowToast("You are not allowed to delete the Data View or Workspace " +
                "while the data on the current view is still loading. Please try again later.", "error", 10000);
            return;
        }

        if (LDH.IsValueEmpty(dialogMessage) === true) {
            dialogMessage = "Are you sure you want to delete this workspace?";
        }
        if (LDH.IsValueEmpty(dashboardIdToDelete) === true) {
            dashboardIdToDelete = that.state.editDashboardId;
        }

        LRH.ShowDialog(dialogMessage, "Confirm Delete", function () {
            that.setState({showLoadingProgress: true}, function () {
                let dashboardId = dashboardIdToDelete;
                let userProfile = that.props.state.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

                LeopardAjaxHelper.DeleteLeftMenuItemForDashboard(userId, organizationId,
                    dashboardId, function () {
                        LRH.ShowToast("Your workspace has been successfully deleted.", "success", 5000);
                        that.resetOptionPanelStates(that.state.leftMenuItemsInitialized,
                            that.state.isUserProfileLoaded, that.state.availableDataViewListForCreate,
                            that.state.availableDataViewListForEdit, that.state.availableReportListForEdit);

                        that.loadLeftMenuItems(true, null, false);
                        that.dataViewOrTemplateMenuItemOnClick({item: null});
                        that.setState({showLoadingProgress: false});
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to delete your Dashbaord. " +
                                "Please check your network connection status and try again.", "error", 5000);
                        }
                        that.setState({showLoadingProgress: false});
                    });
            });
        });
    };

    commandButtonsTagBoxOnValueChanged = (e) => {
        let isShowSelectDataSourceID = false;
        if (!LDH.IsObjectNull(e.value) && e.value.length > 0) {
            isShowSelectDataSourceID = true;
        }
        this.setState({
            selectedCommandButtonsForDataView: e.value,
            isShowSelectDataSourceID: isShowSelectDataSourceID
        });
    };

    disabledFieldsTagBoxOnValueChanged = (e) => {
        this.setState({selectedDisabledFieldsForDataView: e.value});
    };

    hiddenFieldsTagBoxOnValueChanged = (e) => {
        this.setState({selectedHiddenFieldsForDataView: e.value});
    };

    cloneDataViewSelectInputOnChanged = (e) => {
        let that = this;
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(
                this.props.state.userProfile);

            LeopardAjaxHelper.GetDataViewById(userId, e.value, function (result) {
                that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
            });
        });
    }

    exportDataViewSelectInputOnChanged = (e) => {
        let that = this;
        that.setState({showLoadingProgress: true}, function () {
            let userId = LeopardDataHelper.GetUserIdFromUserProfile(
                this.props.state.userProfile);

            LeopardAjaxHelper.GetDataViewById(userId, e.value, function (result) {
                that.setState({showLoadingProgress: false, dataViewJSONToExport: result});
            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false, dataViewJSONToExport: null});
            });
        });
    }

    // ================= Render Templates ====================

    buildLeopardMenuDataViews = (dataViewMenuItems, dataViewCategory) => {
        if (dataViewMenuItems !== null &&
            this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null) {
            return (
                <React.Fragment>
                    <LeopardMenuDataViewList menuItems={dataViewMenuItems}
                                             dataViewCategory={dataViewCategory}
                                             menuItemOnClick={(e) => this.dataViewOrTemplateMenuItemOnClick(e)}
                                             selectedMenuItem={this.props.state.selectedMenuItem}
                                             menuItemSettingsButtonOnClick={(e) => this.menuItemSettingsButtonOnClick(e)}
                                             selectedId={this.props.state.selectedLeftMenuItem.menuItemId}/>
                </React.Fragment>
            )
        }
        return null;
    };

    buildLeopardMenuTemplates = () => {
        if (this.templateMenuItems !== null &&
            this.props.state.selectedLeftMenuItem !== undefined &&
            this.props.state.selectedLeftMenuItem !== null) {
            return (
                <React.Fragment>
                    <LeopardMenuTemplateList menuItems={this.templateMenuItems}
                                             menuItemOnClick={(e) => this.dataViewOrTemplateMenuItemOnClick(e)}
                                             selectedMenuItem={this.props.state.selectedMenuItem}
                                             menuItemSettingsButtonOnClick={(e) => this.menuItemSettingsButtonOnClick(e)}
                                             selectedId={this.props.state.selectedLeftMenuItem.menuItemId}/>
                </React.Fragment>
            )
        }
        return null;
    };

    buildMenuOptionPanelEditDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Edit Data View
                </div>
                <form onSubmit={this.editDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Rename data view" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Rename system view" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Rename default view" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Rename report" : ""
                                    }
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Rename data view"}
                                                                    text={"Give a name for your data view. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Rename system view"}
                                                                    text={"Give a name for your system view. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Rename default view"}
                                                                    text={"Give a name for your default view. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Rename report"}
                                                                    text={"Give a name for your report. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                    </span>
                            </div>
                            <div>
                                <TextBox defaultValue={this.state.editDataViewName} maxLength={50}
                                         disabled={this.state.showLoadingProgress} id={"dataViewNameInput"}
                                         ref={(e) => this.setInputFieldInstance({e: e, input: "dataViewNameInput"})}
                                         onValueChanged={(e) => this.editDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">2.</i>
                                        <span>Height of thumbnails</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"heightOfThumbnailInput_help"}
                                                                            title={"Height of thumbnails"}
                                                                            text={"Set the height of the thumbnails by pixels."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                   value={this.state.heightOfThumbnailForPhotoGallery}
                                                   id={"heightOfThumbnailForPhotoGalleryInput"}
                                                   showSpinButtons={true} format={"#"}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e: e,
                                                       input: "heightOfThumbnailForPhotoGalleryInput"
                                                   })}
                                                   onValueChanged={(e) => this.heightOfThumbnailOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e: e,
                                                        input: "heightOfThumbnailForPhotoGalleryInput",
                                                        rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">3.</i>
                                        <span>Width of thumbnails</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"widthOfThumbnailInput_help"}
                                                                            title={"Width of thumbnails"}
                                                                            text={"Set the width of the thumbnails by pixels."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                   value={this.state.widthOfThumbnailForPhotoGallery}
                                                   id={"widthOfThumbnailForPhotoGalleryInput"}
                                                   showSpinButtons={true} format={"#"}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e: e,
                                                       input: "widthOfThumbnailForPhotoGalleryInput"
                                                   })}
                                                   onValueChanged={(e) => this.widthOfThumbnailOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e: e,
                                                        input: "heightOfThumbnailForPhotoGalleryInput",
                                                        rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                </div>
                        }
                        {
                            this.state.editDataViewType !== "photo" ? "" :
                                <div className="leopard-option-panel-block">
                                    <div>
                                        <i className="leopard-option-panel-stepindex">4.</i>
                                        <span>Parent data source ID</span>
                                        <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"parentDataSourceIdForPhotoInput_help"}
                                                        title={"Parent data source ID"}
                                                        text={"Specify the parent data source ID for this Data View."}/>
                                            </span>
                                    </div>
                                    <div>
                                        <TextBox disabled={this.state.showLoadingProgress}
                                                 value={this.state.parentDataSourceIdForPhotoGallery}
                                                 id={"parentDataSourceIdForPhotoInput"}
                                                 ref={(e) => this.setInputFieldInstance({
                                                     e: e,
                                                     input: "parentDataSourceIdForPhotoInput"
                                                 })}
                                                 onValueChanged={(e) => this.parentDataSourceIdOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e: e,
                                                        input: "parentDataSourceIdForPhotoInput",
                                                        rules: [
                                                            {rule: "required"},
                                                            {rule: "safeinput"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Delete'} id={"createDataViewDeleteButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewDeleteButtonOnClick(e)}/>
                            </span>
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'} id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                            </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'} id={"createDataViewApplyButton"}
                                        disabled={this.state.showLoadingProgress} useSubmitBehavior={true}/>
                            </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    dashboardManageRelationshipAddOnClick = (e) => {
        let relationships = LDH.DeepClone(this.state.dashboardEditManageRelationship);
        relationships.push({
            parentDataViewId: "",
            childDataViewId: "",
            interactiveFeatures: [],
            dataSourceId: "",
            dataColumns: [],
            relationshipId: LDH.GenerateGuid()
        });
        this.setState({dashboardEditManageRelationship: relationships});
    }

    manageRelationshipDeleteItem = (e) => {
        let list = this.state.dashboardEditManageRelationship;
        let result = [];
        for (var i = 0; i < list.length; i++) {
            if (e.item.relationshipId !== list[i].relationshipId) {
                result.push(list[i]);
            }
        }
        this.setState({dashboardEditManageRelationship: result});
    }

    configItemBoxValueOnChange = (data) => {
        if (this.isConfigItemBoxValueOnChangeDisabled === true) {
            this.isConfigItemBoxValueOnChangeDisabled = false;
            return;
        }
        let list = LDH.DeepClone(this.state.dashboardEditManageRelationship);

        for (var i = 0; i < list.length; i++) {
            let dataItem = list[i];
            if (data.item.relationshipId !== dataItem.relationshipId) {
                continue;
            }
            if (data.inputField === "parent") {
                if (LDH.ValidateDashboardRelationships(LDH.DeepClone(list), data.e.value, data.item.childDataViewId,
                    data.item.relationshipId) === false) {
                    if (!LDH.IsObjectNull(this.uiObjectInstance[data.instanceName])) {
                        this.isConfigItemBoxValueOnChangeDisabled = true;
                        this.uiObjectInstance[data.instanceName].option("value", data.e.previousValue);
                        LRH.ShowToast("Circular relationship detected. Your change has been undone.", "error", 5000);
                    }
                    return;
                }

                dataItem.parentDataViewId = data.e.value;
                this.setState({dashboardEditManageRelationship: list});
            } else if (data.inputField === "child") {
                if (LDH.ValidateDashboardRelationships(LDH.DeepClone(list), data.item.parentDataViewId, data.e.value,
                    data.item.relationshipId) === false) {
                    if (!LDH.IsObjectNull(this.uiObjectInstance[data.instanceName])) {
                        this.isConfigItemBoxValueOnChangeDisabled = true;
                        this.uiObjectInstance[data.instanceName].option("value", data.e.previousValue);
                        LRH.ShowToast("Circular relationship detected. Your change has been undone.", "error", 5000);
                    }
                    return;
                }

                dataItem.childDataViewId = data.e.value;
                this.setState({dashboardEditManageRelationship: list});
            } else if (data.inputField === "features") {
                dataItem.interactiveFeatures = data.e.value;
                this.setState({dashboardEditManageRelationship: list});
            }
        }
    }

    buildMenuOptionPanelManageDashboardRelationship = () => {
        if (this.state.editDashboardInitialized === false) {
            return this.renderProgressLoadingPanel(true);
        }
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Manage Relationship
                </div>
                <form onSubmit={this.manageDashboardRelationshipApplyOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    Configure relationships from the workspace
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon elementId={"dashboardManageRelationship_help"}
                                                                    title={"Manage relationship"}
                                                                    text={"Create and manage relationships between data views."}/>
                                </span>
                                <i className={"leopard-menuoption-add-button fas fa-plus"} onClick={
                                    (e) => this.dashboardManageRelationshipAddOnClick(e)}
                                />
                            </div>
                        </div>
                        <LeopardMenuConfigItemBox relationships={this.state.dashboardEditManageRelationship}
                                                  dataSource={this.state.selectedDataViewsForDashboard}
                                                  onDelete={(e) => this.manageRelationshipDeleteItem(e)}
                                                  setInputFieldInstance={(e) => this.setInputFieldInstance(e)}
                                                  configItemBoxValueOnChange={(e) => this.configItemBoxValueOnChange(e)}
                                                  showLoadingProgress={this.state.showLoadingProgress}/>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                            </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                            </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDashboardOptions = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Select an option
                </div>
                <div className="leopard-option-panel-content" style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "edit_template" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "edit_template",
                                     currentOption: this.state.showMenuOptionPanel.option
                                 })}>
                                Edit workspace
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "manage_dashboard_relationship" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "manage_dashboard_relationship",
                                     currentOption: this.state.showMenuOptionPanel.option
                                 })}>
                                Manage relationship
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showMenuOptionPanel.option !== "edit_dashboard_options" ? "" :
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                <span style={{paddingRight: "20px"}}>
                                        <Button className="leopard-button" text={'Cancel'}
                                                id={"createDataViewCancelButton"}
                                                disabled={this.state.showLoadingProgress} onClick={
                                            (e) => this.createDataViewCancelButtonOnClickWithoutConfirm(e)}/>
                                      </span>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    };

    buildMenuOptionPanelDataViewOptions = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Select an option
                </div>
                <div className="leopard-option-panel-content" style={{paddingLeft: 0, paddingRight: 0}}>
                    <div className="leopard-option-panel-block">
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "add_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "add_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Create a new data view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Create a new system view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Create a new default view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Create a new report" : ""
                                }
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "clone_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "clone_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Clone an existing data view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Clone an existing system view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Clone an existing default view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Clone an existing report" : ""
                                }
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "import_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "import_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Import data view(s)" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Import system view(s)" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Import default view(s)" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Import report(s)" : ""
                                }
                            </div>
                        </div>
                        <div className={"leopard-link-for-menuoption-parent " +
                        (this.state.showMenuOptionPanel.option === "export_dataview" ? "selected" : "")}>
                            <div className={"leopard-link-for-menuoption"}
                                 onClick={(e) => this.showMenuOptionPanelStatusOnChange({
                                     option: "export_dataview",
                                     dataViewCategory: this.state.showMenuOptionPanel.dataViewCategory
                                 })}>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Export a data view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Export a system view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Export a default view" : ""
                                }
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Export a report" : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showMenuOptionPanel.option !== "add_dataview_options" ? "" :
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                <span style={{paddingRight: "20px"}}>
                                        <Button className="leopard-button" text={'Cancel'}
                                                id={"createDataViewCancelButton"}
                                                disabled={this.state.showLoadingProgress} onClick={
                                            (e) => this.createDataViewCancelButtonOnClickWithoutConfirm(e)}/>
                                      </span>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    };

    onSelectedFilesChanged = (e) => {
        $(".dx-fileuploader-button.dx-fileuploader-upload-button").remove();
        let dataToImport = [];
        for (let i = 0; i < e.value.length; i++) {
            var reader = new FileReader()
            reader.onload = function (event) {
                dataToImport.push(event.target.result);
            }
            reader.readAsText(e.value[i]);
        }
        this.setState({dataViewListToImport: dataToImport});
    }

    buildMenuOptionPanelImportDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Import data view(s)" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Import system view(s)" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Import default view(s)" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Import report(s)" : ""
                    }
                </div>
                <form onSubmit={this.importDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select files to import</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"selectFilesToImport_help"}
                                                            title={"Select files to import"}
                                                            text={"You can import a data view by selecting a on your PC, you can import multiple data views at once. Please note that the file must be in JSON format and is compatible with Control Centre."}/>
                                    </span>
                            </div>
                            <div>
                                <FileUploader multiple={true} uploadMode={"useButtons"}
                                              readyToUploadMessage={"Ready to import"}
                                              accept={'.json'}
                                              onValueChanged={this.onSelectedFilesChanged}/>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress}
                                    useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCloneDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Clone an existing data view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Clone an existing system view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Clone an existing default view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Clone an existing report" : ""
                    }
                </div>
                <form onSubmit={this.cloneDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Select data view to clone" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Select system view to clone" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Select default view to clone" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Select report to clone" : ""
                                    }
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToClone_help"}
                                                                    title={"Select data view to clone"}
                                                                    text={"You can clone a data view by selecting an existing view in Control Centre."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToClone_help"}
                                                                    title={"Select system view to clone"}
                                                                    text={"You can clone a system view by selecting an existing view in Control Centre."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToClone_help"}
                                                                    title={"Select default view to clone"}
                                                                    text={"You can clone a default view by selecting an existing view in Control Centre."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToClone_help"}
                                                                    title={"Select report to clone"}
                                                                    text={"You can clone a report by selecting an existing report in Control Centre."}/>
                                            : null
                                    }
                                    </span>
                            </div>
                            <div>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                        <SelectBox dataSource={new DataSource({
                                            store: this.state.availableReportListForEdit, key: 'id', group: 'group'
                                        })} displayExpr={'name'} grouped={true}
                                                   valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                   id={"cloneDataViewSelectInput"}
                                                   onValueChanged={(e) => this.cloneDataViewSelectInputOnChanged(e)}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e: e,
                                                       input: "cloneDataViewSelectInput"
                                                   })}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e: e, input: "cloneDataViewSelectInput", rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </SelectBox>
                                        :
                                        <SelectBox dataSource={new DataSource({
                                            store: this.state.availableDataViewListForEdit, key: 'id', group: 'group'
                                        })} displayExpr={'name'} grouped={true}
                                                   valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                   id={"cloneDataViewSelectInput"}
                                                   onValueChanged={(e) => this.cloneDataViewSelectInputOnChanged(e)}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e: e,
                                                       input: "cloneDataViewSelectInput"
                                                   })}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e: e, input: "cloneDataViewSelectInput", rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </SelectBox>
                                }
                            </div>
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Name of data view" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Name of system view" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Name of default view" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Name of report" : ""
                                    }
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of data view"}
                                                                        text={"Give a name for your data view. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Name of system view"}
                                                                    text={"Give a name for your system view. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Name of default view"}
                                                                    text={"Give a name for your default view. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                    title={"Name of report"}
                                                                    text={"Give a name for your report. The length of this field is limited to 50 characters."}/>
                                            : null
                                    }
                                </span>
                            </div>
                            <div>
                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                         id={"dataViewNameInput"}
                                         ref={(e) => this.setInputFieldInstance({
                                             e: e,
                                             input: "dataViewNameInput"
                                         })}
                                         onValueChanged={(e) => this.createDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"createDataViewCancelButton"}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                                <Button className="leopard-button" text={'Apply'}
                                        id={"createDataViewApplyButton"}
                                        disabled={this.state.showLoadingProgress}
                                        useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelExportDataView = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Export a data view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Export a system view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Export a default view" : ""
                    }
                    {
                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Export a report" : ""
                    }
                </div>
                <form onSubmit={this.exportDataViewApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Select data view to export" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Select system view to export" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Select default view to export" : ""
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Select report to export" : ""
                                    }
                                </span>
                                <span style={{marginLeft: "5px"}}>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToExport_help"}
                                                                    title={"Select data view to export"}
                                                                    text={"You can export any data view to a file in JSON format, so you can import it at any time in the future."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToExport_help"}
                                                                    title={"Select system view to export"}
                                                                    text={"You can export any system view to a file in JSON format, so you can import it at any time in the future."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToExport_help"}
                                                                    title={"Select default view to export"}
                                                                    text={"You can export any default view to a file in JSON format, so you can import it at any time in the future."}/>
                                            : null
                                    }
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <LeopardTooltipWithIcon elementId={"selectFilesToExport_help"}
                                                                    title={"Select report to export"}
                                                                    text={"You can export any report to a file, so you can import it at any time in the future."}/>
                                            : null
                                    }
                                </span>
                            </div>
                            <div>
                                {
                                    this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                        <SelectBox dataSource={new DataSource({
                                            store: this.state.availableReportListForEdit, key: 'id', group: 'group'
                                        })} displayExpr={'name'} valueExpr={'id'}
                                                   disabled={this.state.showLoadingProgress}
                                                   id={"exportDataViewSelectInput"} grouped={true}
                                                   onValueChanged={(e) => this.exportDataViewSelectInputOnChanged(e)}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e: e,
                                                       input: "exportDataViewSelectInput"
                                                   })}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e: e, input: "exportDataViewSelectInput", rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </SelectBox>
                                        :
                                        <SelectBox dataSource={new DataSource({
                                            store: this.state.availableDataViewListForEdit, key: 'id', group: 'group'
                                        })} displayExpr={'name'} valueExpr={'id'}
                                                   disabled={this.state.showLoadingProgress}
                                                   id={"exportDataViewSelectInput"} grouped={true}
                                                   onValueChanged={(e) => this.exportDataViewSelectInputOnChanged(e)}
                                                   ref={(e) => this.setInputFieldInstance({
                                                       e: e,
                                                       input: "exportDataViewSelectInput"
                                                   })}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e: e, input: "exportDataViewSelectInput", rules: [
                                                            {rule: "required"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </SelectBox>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        disabled={this.state.showLoadingProgress}
                                        id={"createDataViewCancelButton"}
                                        onClick={(e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                            <span style={{paddingRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress}
                                    useSubmitBehavior={true}/>
                          </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCreateDataView = () => {
        return (
            <React.Fragment>
                <div>
                    <div className="leopard-option-panel-title">
                        {
                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Create a new data view" : ""
                        }
                        {
                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Create a new system view" : ""
                        }
                        {
                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Create a new default view" : ""
                        }
                        {
                            this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Create a new report" : ""
                        }
                    </div>
                    <form onSubmit={this.createDataViewApplyButtonOnClick}>
                        <div className="leopard-option-panel-content">
                            <div className="leopard-option-panel-block">
                                <div>
                                    <i className="leopard-option-panel-stepindex">1.</i>
                                    <span>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Select a type of data view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Select a type of system view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Select a type of default view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Select a type of report" : ""
                                        }
                                    </span>
                                    <span style={{marginLeft: "5px"}}>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                                <LeopardTooltipWithIcon elementId={"selectDataViewTypeInput_help"}
                                                                        title={"Select a type of data view"}
                                                                        text={"There are many types of data view that the Control Centre supports. " +
                                                                        "Administrators can create a data view with data grids, charts, maps and reports."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                                <LeopardTooltipWithIcon elementId={"selectDataViewTypeInput_help"}
                                                                        title={"Select a type of system view"}
                                                                        text={"There are many types of system view that the Control Centre supports. " +
                                                                        "Administrators can create a system view with data grids, charts, maps and reports."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                                <LeopardTooltipWithIcon elementId={"selectDataViewTypeInput_help"}
                                                                        title={"Select a type of default view"}
                                                                        text={"There are many types of default view that the Control Centre supports. " +
                                                                        "Administrators can create a default view with data grids, charts, maps and reports."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                                <LeopardTooltipWithIcon elementId={"selectDataViewTypeInput_help"}
                                                                        title={"Select a type of report"}
                                                                        text={"There are many types of report that the Control Centre supports."}/>
                                                : null
                                        }
                                    </span>
                                </div>
                                <div>
                                    {
                                        this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                            <SelectBox dataSource={this.reportTypeList} displayExpr={'name'}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       id={"selectDataViewTypeInput"}
                                                       defaultValue={this.state.createDataViewType}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e: e,
                                                           input: "selectDataViewTypeInput"
                                                       })}
                                                       onValueChanged={
                                                           (e) => this.createDataViewSelectDataViewTypeOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e: e, input: "selectDataViewTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                            :
                                            <SelectBox dataSource={this.dataViewTypeList} displayExpr={'name'}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       defaultValue={this.state.createDataViewType}
                                                       id={"selectDataViewTypeInput"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e: e,
                                                           input: "selectDataViewTypeInput"
                                                       })}
                                                       onValueChanged={
                                                           (e) => this.createDataViewSelectDataViewTypeOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e: e, input: "selectDataViewTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                    }
                                </div>
                            </div>
                            {
                                (this.state.showMenuOptionPanel.dataViewCategory === "report" || (this.state.createDataViewType === "chart" &&
                                    this.state.showMenuOptionPanel.dataViewCategory === "default-view") ||
                                    this.state.createDataViewType === "photo") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Select data source</span>
                                            <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"selectChartTypeInput_help"}
                                                            title={"Select data source"}
                                                            text={"Select a data source for the data view to access, so the user will be able to view or edit data on that data source."}/>
                                    </span>
                                        </div>
                                        <div>
                                            <SelectBox displayExpr={'name'} id={"selectDataSourceInput"}
                                                       grouped={true}
                                                       defaultValue={this.state.createDataViewDataSourceUrl}
                                                       dataSource={new DataSource({
                                                           store: this.state.availableDataSourceList,
                                                           key: 'id',
                                                           group: 'group'
                                                       })}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e: e,
                                                           input: "selectDataSourceInput"
                                                       })}
                                                       valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                       onValueChanged={
                                                           (e) => this.createDataViewDataSourceUrlOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e: e, input: "selectDataSourceInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.createDataViewType !== "chart" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Select type of chart</span>
                                            <span style={{marginLeft: "5px"}}>
                                            <LeopardTooltipWithIcon elementId={"selectTypeOfChart_help"}
                                                                    title={"Select type of chart"}
                                                                    text={"Specify what type of chart would you like to create. The Control Centre currently supports 6 types of chart, such as bar chart, scatter chart, doughnut chart, bar chart, line chart and area chart."}/>
                                </span>
                                        </div>
                                        <div>
                                            <SelectBox dataSource={this.chartTypeList} displayExpr={'name'}
                                                       disabled={this.state.showLoadingProgress}
                                                       visible={this.state.showChartTypeOption}
                                                       id={"selectChartTypeInput"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e: e,
                                                           input: "selectChartTypeInput"
                                                       })}
                                                       valueExpr={'id'}
                                                       defaultValue={this.state.createDataViewChartType}
                                                       onValueChanged={
                                                           (e) => this.createDataViewSelectChartTypeOnValueChanged(e)
                                                       }>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e: e, input: "selectChartTypeInput", rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </SelectBox>
                                        </div>
                                    </div>
                            }
                            {
                                (this.state.selectedDataSourceObject === null || this.state.selectedDataSourceObject.category !== "data-table" ||
                                    this.state.createDataViewType === "chart") ? "" :
                                    <React.Fragment>
                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex">
                                                    {this.state.showChartTypeOption ? "4." : "3."}</i>
                                                <span>Configure CRUD operations</span>
                                                <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"selectedCommandButtonsForDataView_help"}
                                                        title={"Configure CRUD operations"}
                                                        text={"Specify whether allow user to modify, add and delete data row on the grid view."}/>
                                                </span>
                                            </div>
                                            <div>
                                                <TagBox dataSource={LeopardStaticUIConfig.StaticList_CommandButtonList}
                                                        showSelectionControls={true} displayExpr={'text'}
                                                        valueExpr={'value'}
                                                        id={"commandButtonTagbox"} multiline={false}
                                                        value={this.state.selectedCommandButtonsForDataView}
                                                        disabled={this.state.showLoadingProgress}
                                                        onValueChanged={(e) => this.commandButtonsTagBoxOnValueChanged(e)}
                                                        applyValueMode={'useButtons'}/>
                                            </div>
                                            {
                                                this.state.isShowSelectDataSourceID === false ? "" :
                                                    <div>
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <span>Specify a data source ID:</span>
                                                            <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"dataSourceIDForCreateOrEditDataView_help"}
                                                                    title={"Specify a data source ID"}
                                                                    text={"Specify what field is the primary data source ID for modify, add and delete data row on the grid view."}/>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            {this.renderDataSourceIDForCreateOrEditDataView()}
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                this.state.isShowSelectDataSourceID === false ? "" :
                                                    <div>
                                                        <div className={"leopard-leftmenu-settings-section"}>
                                                            <span>Specify a data source URI:</span>
                                                            <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"dataSourceURIForCreateOrEditDataView_help"}
                                                                    title={"Specify a data source URI"}
                                                                    text={"Specify a URI for the data source. If you are not sure about this, please contact Leopard Systems for assistance."}/>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            {this.renderDataSourceURIForCreateOrEditDataView()}
                                                        </div>
                                                    </div>
                                            }
                                        </div>

                                        <div className="leopard-option-panel-block">
                                            <div>
                                                <i className="leopard-option-panel-stepindex" style={{float: "left"}}>
                                                    {this.state.showChartTypeOption ? "5." : "4."}</i>
                                                <div style={{position: "relative", top: "9px", height: "30px"}}>
                                                    <span>Customise data edit form</span>
                                                    <span style={{float: "right"}}>
                                                        <Switch value={!this.state.disableEditingConfigurationFields}
                                                                width={"80px"}
                                                                switchedOffText={"DEFAULT"} switchedOnText={"CUSTOM"}
                                                                id={"disableEditingConfigurationFieldsSwitch"}
                                                                disabled={this.state.showLoadingProgress}
                                                                onValueChanged={(e) => this.dataEditingCheckboxOnValueChanged(e)}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            {this.state.disableEditingConfigurationFields ? "" :
                                                <div>
                                                    <div className={"leopard-leftmenu-settings-section"}>
                                                        <span>Disable the following fields:</span>
                                                        <span style={{marginLeft: "5px"}}>
                                                            <LeopardTooltipWithIcon
                                                                elementId={"disabledFieldsTagbox_help"}
                                                                title={"Disable fields"}
                                                                text={"Specifies which columns on the grid view should be disabled when a user editing a data row."}/>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <TagBox
                                                            dataSource={this.state.availableColumnNamesFromDataSourceWithoutBlank}
                                                            showSelectionControls={true} displayExpr={'name'}
                                                            valueExpr={'id'}
                                                            value={this.state.selectedDisabledFieldsForDataView}
                                                            id={"disabledFieldsTagbox"} multiline={false}
                                                            noDataText={"Please select a data source first.<br/>There is no data available to display."}
                                                            onValueChanged={(e) => this.disabledFieldsTagBoxOnValueChanged(e)}
                                                            disabled={this.state.showLoadingProgress}
                                                            applyValueMode={'useButtons'}/>
                                                    </div>
                                                    <div className={"leopard-leftmenu-settings-section"}>
                                                        <span>Hide the following fields:</span>
                                                        <span style={{marginLeft: "5px"}}>
                                                            <LeopardTooltipWithIcon
                                                                elementId={"hiddenFieldsTagbox_help"}
                                                                title={"Hide fields"}
                                                                text={"Specifies which columns on the grid view should be hidden when a user editing a data row."}/>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <TagBox
                                                            dataSource={this.state.availableColumnNamesFromDataSourceWithoutBlank}
                                                            showSelectionControls={true} displayExpr={'name'}
                                                            valueExpr={'id'}
                                                            value={this.state.selectedHiddenFieldsForDataView}
                                                            id={"hiddenFieldsTagbox"} multiline={false}
                                                            noDataText={"Please select a data source first.<br/>There is no data available to display."}
                                                            onValueChanged={(e) => this.hiddenFieldsTagBoxOnValueChanged(e)}
                                                            disabled={this.state.showLoadingProgress}
                                                            applyValueMode={'useButtons'}/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                            }
                            {
                                (this.state.createDataViewType !== "chart" || this.state.showMenuOptionPanel.dataViewCategory !== "default-view") ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div style={{marginBottom: "10px"}}>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Mandatory configuration</span>
                                            <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"mandatoryConfigurationForChart_help"}
                                                                    title={"Mandatory configuration"}
                                                                    text={"You must configure all the settings in this section for a chart to work. These are the mandatory fields for any charts."}/>
                                                            </span>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Specify a column for x-axis value</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectColumnForXAxisValueForChart_help"}
                                                                    title={"Specify a column"}
                                                                    text={"Specify a column on the data source for display x-axis values."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                                         defaultValue={this.state.createDataViewChartArgumentField}
                                                         placeholder={"Column1 | Column2 | Column3"}
                                                         id={"selectArgumentFieldInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e: e,
                                                             input: "selectArgumentFieldInput"
                                                         })}
                                                         onValueChanged={(e) => this.createDataViewArgumentFieldOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e: e, input: "selectArgumentFieldInput", rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Specify a column for y-axis value</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectColumnForYAxisValueForChart_help"}
                                                                    title={"Specify a column"}
                                                                    text={"Specify a column on the data source for display y-axis values."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                                         defaultValue={this.state.createDataViewChartValueField}
                                                         placeholder={"Column1 | Column2 | Column3"}
                                                         id={"selectValueFieldInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e: e,
                                                             input: "selectValueFieldInput"
                                                         })}
                                                         onValueChanged={(e) => this.createDataViewValueFieldOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e: e, input: "selectValueFieldInput", rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block" style={{display: "none"}}>
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Select a date range</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectDateRangeForChart_help"}
                                                                    title={"Select a date range"}
                                                                    text={"Specify a date range for the chart. For example, if you want to display a chart with total number of transactions per day for the last 7 days, you should select \"Last 7 Days\" from the dropdown list."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <SelectBox
                                                    dataSource={this.dateRangeSelectionForChart}
                                                    displayExpr={'name'} id={"selectDateRangeForCreateChartInput"}
                                                    // visible={this.state.showChartTypeOption}
                                                    visible={false}
                                                    defaultValue={this.state.selectDateRangeForCreateChart}
                                                    ref={(e) => this.setInputFieldInstance({
                                                        e: e,
                                                        input: "selectDateRangeForCreateChartInput"
                                                    })}
                                                    valueExpr={'id'} disabled={this.state.showLoadingProgress}
                                                    onValueChanged={
                                                        (e) => this.selectDateRangeForCreateChartOnValueChanged(e)
                                                    }>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e: e,
                                                                input: "selectDateRangeForCreateChartInput",
                                                                rules: [
                                                                    {rule: "required"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block" style={{display: "none"}}>
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Specify a column for date range</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"selectDateRangeLookupForChart_help"}
                                                                    title={"Specify a column"}
                                                                    text={"Specify a column on the data source for date range lookup."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                                         visible={false}
                                                         defaultValue={this.state.createDataViewChartDateRangeField}
                                                         id={"createDataViewChartDateRangeFieldInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e: e,
                                                             input: "createDataViewChartDateRangeFieldInput"
                                                         })}
                                                         onValueChanged={(e) => this.createDataViewChartDateRangeFieldOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e: e,
                                                                input: "createDataViewChartDateRangeFieldInput",
                                                                rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>OData query string</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"customQueryAttributesForChart_help"}
                                                                    title={"OData query string"} customWidth={450}
                                                                    text={"Before you can create a chart, you must write a query to tell what data should be shown on the chart. For more info about how to write a query, please refer to OData V4 protocol."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextArea disabled={this.state.showLoadingProgress} height={"80px"}
                                                          id={"customQueryAttributesInput"}
                                                          placeholder={"/Transaction?$filter=...&apply=..."}
                                                          ref={(e) => this.setInputFieldInstance({
                                                              e: e,
                                                              input: "customQueryAttributesInput"
                                                          })}
                                                          onValueChanged={(e) => this.customQueryAttributesOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e: e, input: "customQueryAttributesInput", rules: [
                                                                    {rule: "required"},
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                    <TextBoxButton name={"customQueryAttributesInput_MiniButton"}
                                                                   location={'after'}
                                                                   options={{
                                                                       icon: "fas fa-question",
                                                                       type: 'default',
                                                                       onClick: () => {
                                                                           window.open(LeopardStaticUIConfig.ODataDocUrl);
                                                                       }
                                                                   }}
                                                    />
                                                </TextArea>
                                            </div>
                                        </div>
                                        <div className="leopard-option-panel-block">
                                            <div style={{paddingLeft: "24px"}}>
                                                <span>Chart series</span>
                                                <span style={{marginLeft: "5px"}}>
                                                                <LeopardTooltipWithIcon
                                                                    elementId={"numberOfSeriesForChart_help"}
                                                                    title={"Chart series"} customWidth={450}
                                                                    text={"You must define at least one series to be shown on the chart."}/>
                                                            </span>
                                            </div>
                                            <div style={{paddingLeft: "24px"}}>
                                                <TextBox disabled={this.state.showLoadingProgress} maxLength={4000}
                                                         id={"numberOfSeriesForChartInput"}
                                                         ref={(e) => this.setInputFieldInstance({
                                                             e: e,
                                                             input: "numberOfSeriesForChartInput"
                                                         })}
                                                         onValueChanged={(e) => this.numberOfSeriesForChartOnValueChanged(e)}>
                                                    <Validator>
                                                        <RequiredRule type="custom" validationCallback={
                                                            (e) => this.customValidationRuleCallback({
                                                                e: e, input: "numberOfSeriesForChartInput", rules: [
                                                                    {rule: "safeinput"}
                                                                ]
                                                            })
                                                        }/>
                                                    </Validator>
                                                    <TextBoxButton name={"numberOfSeriesForChartInput_MiniButton"}
                                                                   location={'after'}
                                                                   options={{
                                                                       icon: "fas fa-plus",
                                                                       type: 'default',
                                                                       onClick: () => {
                                                                           if (LDH.IsValueEmpty(this.numberOfSeriesForChartText)) {
                                                                               return;
                                                                           }
                                                                           this.setState({
                                                                               numberOfSeriesOnChart: [
                                                                                   ...this.state.numberOfSeriesOnChart,
                                                                                   {
                                                                                       id: "series_" + (this.state.numberOfSeriesOnChart.length + 1),
                                                                                       name: this.numberOfSeriesForChartText
                                                                                   }
                                                                               ]
                                                                           }, function () {
                                                                               this.uiObjectInstance["numberOfSeriesForChartInput"].option("value", "");
                                                                           });
                                                                       }
                                                                   }}
                                                    />
                                                </TextBox>
                                            </div>
                                            <div className={"leopard-listcontainer"}
                                                 style={{paddingLeft: "24px", marginTop: "5px"}}>
                                                <List allowItemDeleting={true} activeStateEnabled={false}
                                                      noDataText={"There is no series on the chart"}
                                                      hoverStateEnabled={false} focusStateEnabled={false}
                                                      dataSource={this.state.numberOfSeriesOnChart}
                                                      itemRender={this.numberOfSeriesOnChartTemplate}/>
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">2.</i>
                                            <span>Height of thumbnails</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"heightOfThumbnailInput_help"}
                                                                            title={"Height of thumbnails"}
                                                                            text={"Set the height of the thumbnails by pixels."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                       defaultValue={this.state.heightOfThumbnailForPhotoGallery}
                                                       id={"heightOfThumbnailForPhotoGalleryInput"}
                                                       showSpinButtons={true} format={"#"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e: e,
                                                           input: "heightOfThumbnailForPhotoGalleryInput"
                                                       })}
                                                       onValueChanged={(e) => this.heightOfThumbnailOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e: e,
                                                            input: "heightOfThumbnailForPhotoGalleryInput",
                                                            rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </NumberBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">3.</i>
                                            <span>Width of thumbnails</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon elementId={"widthOfThumbnailInput_help"}
                                                                            title={"Width of thumbnails"}
                                                                            text={"Set the width of the thumbnails by pixels."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <NumberBox disabled={this.state.showLoadingProgress} min={30} max={9999}
                                                       defaultValue={this.state.widthOfThumbnailForPhotoGallery}
                                                       id={"widthOfThumbnailForPhotoGalleryInput"}
                                                       showSpinButtons={true} format={"#"}
                                                       ref={(e) => this.setInputFieldInstance({
                                                           e: e,
                                                           input: "widthOfThumbnailForPhotoGalleryInput"
                                                       })}
                                                       onValueChanged={(e) => this.widthOfThumbnailOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e: e,
                                                            input: "heightOfThumbnailForPhotoGalleryInput",
                                                            rules: [
                                                                {rule: "required"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </NumberBox>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.createDataViewType !== "photo" ? "" :
                                    <div className="leopard-option-panel-block">
                                        <div>
                                            <i className="leopard-option-panel-stepindex">4.</i>
                                            <span>Parent data source ID</span>
                                            <span style={{marginLeft: "5px"}}>
                                                    <LeopardTooltipWithIcon
                                                        elementId={"parentDataSourceIdForPhotoInput_help"}
                                                        title={"Parent data source ID"}
                                                        text={"Specify the parent data source ID for this Data View."}/>
                                            </span>
                                        </div>
                                        <div>
                                            <TextBox disabled={this.state.showLoadingProgress}
                                                     value={this.state.parentDataSourceIdForPhotoGallery}
                                                     id={"parentDataSourceIdForPhotoInput"}
                                                     ref={(e) => this.setInputFieldInstance({
                                                         e: e,
                                                         input: "parentDataSourceIdForPhotoInput"
                                                     })}
                                                     onValueChanged={(e) => this.parentDataSourceIdOnValueChanged(e)}>
                                                <Validator>
                                                    <RequiredRule type="custom" validationCallback={
                                                        (e) => this.customValidationRuleCallback({
                                                            e: e,
                                                            input: "parentDataSourceIdForPhotoInput",
                                                            rules: [
                                                                {rule: "required"},
                                                                {rule: "safeinput"}
                                                            ]
                                                        })
                                                    }/>
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                            }
                            <div className="leopard-option-panel-block">
                                <div>
                                    {
                                        (this.state.selectedDataSourceObject !== null && this.state.selectedDataSourceObject.category === "data-table"
                                            && this.state.createDataViewType !== "chart") ?
                                            <React.Fragment>
                                                <i className="leopard-option-panel-stepindex">{this.state.showChartTypeOption ? "7." : "5."}</i>
                                            </React.Fragment>
                                            :
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                                <React.Fragment>
                                                    <i className="leopard-option-panel-stepindex">2.</i>
                                                </React.Fragment>
                                                :
                                                (this.state.createDataViewType === "chart" && this.state.showMenuOptionPanel.dataViewCategory === "default-view") ?
                                                    <React.Fragment>
                                                        <i className="leopard-option-panel-stepindex">4.</i>
                                                    </React.Fragment>
                                                    :
                                                    this.state.createDataViewType === "photo" ?
                                                        <React.Fragment>
                                                            <i className="leopard-option-panel-stepindex">5.</i>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <i className="leopard-option-panel-stepindex">3.</i>
                                                        </React.Fragment>
                                    }
                                    <span>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ? "Name of data view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ? "Name of system view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ? "Name of default view" : ""
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ? "Name of report" : ""
                                        }
                                    </span>
                                    <span style={{marginLeft: "5px"}}>
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "data-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of data view"}
                                                                        text={"Give a name for your data view. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "system-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of system view"}
                                                                        text={"Give a name for your system view. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "default-view" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of default view"}
                                                                        text={"Give a name for your default view. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                        {
                                            this.state.showMenuOptionPanel.dataViewCategory === "report" ?
                                                <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                                        title={"Name of report"}
                                                                        text={"Give a name for your report. The length of this field is limited to 50 characters."}/>
                                                : null
                                        }
                                    </span>
                                </div>
                                <div>
                                    <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                                             id={"dataViewNameInput"}
                                             ref={(e) => this.setInputFieldInstance({
                                                 e: e,
                                                 input: "dataViewNameInput"
                                             })}
                                             onValueChanged={(e) => this.createDataViewNameOnValueChanged(e)}>
                                        <Validator>
                                            <RequiredRule type="custom" validationCallback={
                                                (e) => this.customValidationRuleCallback({
                                                    e: e, input: "dataViewNameInput", rules: [
                                                        {rule: "required"},
                                                        {rule: "safeinput"}
                                                    ]
                                                })
                                            }/>
                                        </Validator>
                                    </TextBox>
                                    <div style={{display: "none"}}>
                                        <TextBox
                                            ref={(e) => this.setInputFieldInstance({
                                                e: e,
                                                input: "validationInput"
                                            })}
                                            onValueChanged={(e) => this.validationInputOnValueChanged(e)}>
                                            <Validator>
                                                <RequiredRule type="custom" validationCallback={
                                                    (e) => this.customValidationRuleCallback({
                                                        e: e, input: "validationInput", rules: [
                                                            {rule: "required"},
                                                            {rule: "safeinput"}
                                                        ]
                                                    })
                                                }/>
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="leopard-option-panel-buttons">
                            <div style={{textAlign: "right"}}>
                                {
                                    this.state.showLoadingProgress === false ? "" :
                                        <span className={"leopard-loading-icon"} style={{
                                            marginRight: "15px", marginLeft: "30px"
                                        }}>
                                    <i className="fas fa-spinner fa-pulse" style={{
                                        color: "#FF8000", fontSize: "25px"
                                    }}></i>
                                  </span>
                                }
                                <span style={{paddingRight: "15px"}}>
                                <Button className="leopard-button" text={'Cancel'}
                                        id={"createDataViewCancelButton"}
                                        disabled={this.state.showLoadingProgress} onClick={
                                    (e) => this.createDataViewCancelButtonOnClick(e)}/>
                              </span>
                                <span style={{paddingRight: "20px"}}>
                            <Button className="leopard-button" text={'Apply'}
                                    id={"createDataViewApplyButton"}
                                    disabled={this.state.showLoadingProgress} useSubmitBehavior={true}/>
                          </span>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelCreateTemplate = () => {
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Create Workspace
                </div>
                <form onSubmit={this.createDashboardApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data views</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"dataViewSelectBoxListForCreateOrEditDashboard_help"}
                                        title={"Select data views"}
                                        text={"A workspace contains a collection of data views. When you create a workspace, you'll need to specify what data views should be included in the dashboard."}/>
                                    </span>
                            </div>
                            {this.renderDataViewSelectBoxListForCreateOrEditDashboard()}
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Name of workspace</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dashboardNameInput_help"}
                                                            title={"Name of workspace"}
                                                            text={"Give a name to your workspace. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox
                                    ref={(e) => this.setInputFieldInstance({e: e, input: "dashboardNameInput"})}
                                    disabled={this.state.showLoadingProgress} maxLength={50}
                                    id={"dashboardNameInput"}
                                    onValueChanged={(e) => this.createDashboardNameInputOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "dashboardNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                                        <i className="fas fa-spinner fa-pulse" style={{
                                            color: "#FF8000", fontSize: "25px"
                                        }}></i>
                                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                        <Button className="leopard-button" text={'Cancel'}
                                disabled={this.state.showLoadingProgress}
                                id={"createDashboardCancelButton"}
                                onClick={(e) => this.createDashboardCancelButtonOnClick(e)}/>
                  </span>
                            <span style={{paddingRight: "20px"}}>
                        <Button className="leopard-button" text={'Apply'} useSubmitBehavior={true}
                                id={"createDashboardApplyButton"}
                                disabled={this.state.showLoadingProgress}/>
                  </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    buildMenuOptionPanelEditTemplate = () => {
        if (this.state.editDashboardInitialized === false) {
            return this.renderProgressLoadingPanel(true);
        }
        return (
            <React.Fragment>
                <div className="leopard-option-panel-title">
                    Edit Workspace
                </div>
                <form onSubmit={this.editDashboardApplyButtonOnClick}>
                    <div className="leopard-option-panel-content">
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">1.</i>
                                <span>Select data views</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon
                                        elementId={"dataViewSelectBoxListForCreateOrEditDashboard_help"}
                                        title={"Select data views"}
                                        text={"A workspace contains a collection of data views. When you create a workspace, you'll need to specify what data views should be included in the dashboard."}/>
                                    </span>
                            </div>
                            {this.renderDataViewSelectBoxListForCreateOrEditDashboard()}
                        </div>
                        <div className="leopard-option-panel-block">
                            <div>
                                <i className="leopard-option-panel-stepindex">2.</i>
                                <span>Rename workspace</span>
                                <span style={{marginLeft: "5px"}}>
                                    <LeopardTooltipWithIcon elementId={"dataViewNameInput_help"}
                                                            title={"Rename workspace"}
                                                            text={"Give a name to your workspace. The length of this field is limited to 50 characters."}/>
                                    </span>
                            </div>
                            <div>
                                <TextBox maxLength={50}
                                         ref={(e) => this.setInputFieldInstance({e: e, input: "dataViewNameInput"})}
                                         disabled={this.state.showLoadingProgress}
                                         id={"dataViewNameInput"}
                                         defaultValue={this.state.editDataViewName}
                                         onValueChanged={(e) => this.editDataViewNameOnValueChanged(e)}>
                                    <Validator>
                                        <RequiredRule type="custom" validationCallback={
                                            (e) => this.customValidationRuleCallback({
                                                e: e, input: "dataViewNameInput", rules: [
                                                    {rule: "required"},
                                                    {rule: "safeinput"}
                                                ]
                                            })
                                        }/>
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>
                    </div>
                    <div className="leopard-option-panel-buttons">
                        <div style={{textAlign: "right"}}>
                            {
                                this.state.showLoadingProgress === false ? "" :
                                    <span className={"leopard-loading-icon"} style={{
                                        marginRight: "15px", marginLeft: "30px"
                                    }}>
                    <i className="fas fa-spinner fa-pulse" style={{
                        color: "#FF8000", fontSize: "25px"
                    }}></i>
                  </span>
                            }
                            <span style={{paddingRight: "15px"}}>
                        <Button className="leopard-button" text={'Delete'}
                                id={"createDashboardDeleteButton"}
                                disabled={this.state.showLoadingProgress} onClick={
                            () => this.createDashboardDeleteButtonOnClick()}/>
                  </span>
                            <span style={{paddingRight: "15px"}}>
                        <Button className="leopard-button" text={'Cancel'}
                                id={"createDashboardCancelButton"}
                                disabled={this.state.showLoadingProgress}
                                onClick={(e) => this.createDashboardCancelButtonOnClick(e)}/>
                  </span>
                            <span style={{marginRight: "20px"}}>
                <Button className="leopard-button" text={'Apply'} useSubmitBehavior={true}
                        id={"createDashboardApplyButton"}
                        disabled={this.state.showLoadingProgress}/>
              </span>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    };

    renderDataSourceIDForCreateOrEditDataView = () => {
        return (
            <React.Fragment>
                <div style={{marginBottom: "8px"}}>
                    <SelectBox dataSource={this.state.availableColumnNamesFromDataSourceWithoutBlank}
                               displayExpr={'name'} valueExpr={'id'}
                               ref={(e) => this.setInputFieldInstance({e: e, input: "dataViewDataSourceIdInput"})}
                               disabled={this.state.showLoadingProgress} id={"dataViewDataSourceIdInput"}
                               defaultValue={this.state.createDataViewDataSourceId}
                               noDataText={"Please select a data source first.<br/>There is no data available to display."}
                               onValueChanged={(e) => this.createDataViewDataSourceIdOnValueChanged(e)}>
                        <Validator>
                            <RequiredRule type="custom" validationCallback={
                                (e) => this.customValidationRuleCallback({
                                    e: e, input: "dataViewDataSourceIdInput", rules: [
                                        {rule: "required"}
                                    ], defaultBlankValue: 0
                                })
                            }/>
                        </Validator>
                    </SelectBox>
                </div>
            </React.Fragment>
        );
    }

    renderDataSourceURIForCreateOrEditDataView = () => {
        return (
            <React.Fragment>
                <div style={{marginBottom: "8px"}}>
                    <TextBox disabled={this.state.showLoadingProgress} maxLength={50}
                             id={"dataSourceURIInput"}
                             ref={(e) => this.setInputFieldInstance({
                                 e: e,
                                 input: "dataSourceURIInput"
                             })}
                             onValueChanged={(e) => this.createDataViewDataSourceURIOnValueChanged(e)}>
                        <Validator>
                            <RequiredRule type="custom" validationCallback={
                                (e) => this.customValidationRuleCallback({
                                    e: e, input: "dataSourceURIInput", rules: [
                                        {rule: "required"},
                                        {rule: "safeinput"}
                                    ]
                                })
                            }/>
                        </Validator>
                    </TextBox>
                </div>
            </React.Fragment>
        );
    }

    renderDataViewSelectBoxListForCreateOrEditDashboard = () => {
        let that = this;
        if (that.state.availableDataViewListForCreate.length === 0) {
            return null;
        }
        let result = this.state.selectedDataViewsForDashboard.map(function (item, i) {
            if (that.state.showMenuOptionPanel.option === "add_template") {
                return (
                    <React.Fragment key={"LeopardCreateTemplateSelectedDataView_" + i}>
                        <div className={"leopard-menuoption-splitter-container"}>
                            <div style={{marginBottom: "5px"}}>
                                <SelectBox dataSource={new DataSource({
                                    store: that.state.availableDataViewListForCreate, key: 'id', group: 'group'
                                })} className={"leopard-grouped-selectbox"} displayExpr={'name'} valueExpr={'id'}
                                           disabled={that.state.showLoadingProgress}
                                           grouped={true} defaultValue={item.selectedId} id={"selectedDataView_" + i}
                                           onValueChanged={(e) => that.selectDataViewValueOnChange({
                                               e: e, index: i, item: item
                                           })}/>
                            </div>
                            <div>
                                <TextBox defaultValue={item.customDataViewTitle} maxLength={25}
                                         placeholder={"Custom title..."}
                                         disabled={that.state.showLoadingProgress} id={"customDataViewTitleInput_" + i}
                                         onValueChanged={(e) => that.selectDataViewCustomTitleOnChange({
                                             e: e, index: i, item: item
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            if (that.state.showMenuOptionPanel.option === "edit_template") {
                return (
                    <React.Fragment key={"LeopardCreateTemplateSelectedDataView_" + i}>
                        <div className={"leopard-menuoption-splitter-container"}>
                            <div style={{marginBottom: "5px"}}>
                                <SelectBox dataSource={new DataSource({
                                    store: that.state.availableDataViewListForEdit, key: 'id', group: 'group'
                                })} className={"leopard-grouped-selectbox"} displayExpr={'name'} valueExpr={'id'}
                                           disabled={that.state.showLoadingProgress}
                                           grouped={true} defaultValue={item.selectedId} id={"selectedDataView_" + i}
                                           onValueChanged={(e) => that.selectDataViewValueOnChange({
                                               e: e, index: i, item: item
                                           })}/>
                            </div>
                            <div>
                                <TextBox defaultValue={item.customDataViewTitle} maxLength={25}
                                         placeholder={"Custom title..."}
                                         disabled={that.state.showLoadingProgress} id={"customDataViewTitleInput_" + i}
                                         onValueChanged={(e) => that.selectDataViewCustomTitleOnChange({
                                             e: e, index: i, item: item
                                         })}>
                                </TextBox>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            return null;
        });
        return result;
    };

    renderProgressLoadingPanel = (showPanel) => {
        if (showPanel === false) return null;

        return (
            <React.Fragment>
        <span className={"leopard-loading-icon"} style={{
            width: "100%", height: "100%", marginLeft: "0px", paddingTop: "40%"
        }}>
          <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
          <span className="leopard-largeloading-progress-text">Loading data...</span>
        </span>
            </React.Fragment>
        );
    };

    renderAddDashboardButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_template"
               })}/>
        );
    };

    renderAddDataViewButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "data-view"
               })}></i>
        );
    };

    renderAddSystemViewButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "system-view"
               })}></i>
        );
    };

    renderAddDefaultViewButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "default-view"
               })}></i>
        );
    };

    renderAddReportButton = (showGridViewAdminToolbar) => {
        if (showGridViewAdminToolbar === false) {
            return (<div style={{height: "13px"}}/>);
        }
        return (
            <i className="leopard-leftmenu-category-addbutton fas fa-plus"
               onClick={() => this.showMenuOptionPanelStatusOnChange({
                   option: "add_dataview_options",
                   dataViewCategory: "report"
               })}></i>
        );
    };

    renderLeftMenuItemsPanel = (showPanel) => {
        if (showPanel === false) return null;

        let showGridViewAdminToolbar = false;
        if (this.props.state.permissions !== undefined &&
            this.props.state.permissions !== null) {
            let permissions = this.props.state.permissions;
            showGridViewAdminToolbar = permissions.ShowGridViewAdminToolbar;
        }

        return (
            <React.Fragment>
                <div className="leopard-leftmenu-category-container">
                    <div className="leopard-screen-cover" style={{display: "none"}}></div>
                    <span className="leopard-screen-menucover" style={{display: "none"}}></span>
                    <div className={"leopard-leftmenu-category"}>
                        <div className="leopard-leftmenu-category-text">Workspaces</div>
                        {this.renderAddDashboardButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuTemplates()}
                    <div className={"leopard-leftmenu-category"}>
                        <div className="leopard-leftmenu-category-text">Data Views</div>
                        {this.renderAddDataViewButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuDataViews(this.dataViewMenuItems, "data-view")}
                    <div className={"leopard-leftmenu-category"}>
                        <div className="leopard-leftmenu-category-text">System Views</div>
                        {this.renderAddSystemViewButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuDataViews(this.systemViewMenuItems, "system-view")}
                    <div className={"leopard-leftmenu-category"}>
                        <div className="leopard-leftmenu-category-text">Default Views</div>
                        {this.renderAddDefaultViewButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuDataViews(this.defaultViewMenuItems, "default-view")}
                    <div className={"leopard-leftmenu-category"}>
                        <div className="leopard-leftmenu-category-text">Reports</div>
                        {this.renderAddReportButton(showGridViewAdminToolbar)}
                    </div>
                    {this.buildLeopardMenuDataViews(this.reportMenuItems, "report")}
                    <div className={"leopard-leftmenu-category"} style={{opacity: "0.3", display: "none"}}>
                        {"MANAGEMENT"}
                    </div>
                    <div className={"leopard-leftmenu-item"} style={{opacity: "0.3", display: "none"}}>
                        <div className={"leopard-leftmenu-item-text"}>Users & Roles</div>
                    </div>
                    <div className={"leopard-leftmenu-item"} style={{opacity: "0.3", display: "none"}}>
                        <div className={"leopard-leftmenu-item-text"}>Permissions</div>
                    </div>

                    <div className={"leopard-leftmenu-category"} style={{opacity: "0.3", display: "none"}}>
                        {"SYSTEM INFO"}
                    </div>
                    <div className={"leopard-leftmenu-item"} style={{opacity: "0.3", display: "none"}}>
                        <div className={"leopard-leftmenu-item-text"}>Service Status</div>
                    </div>
                    <div className={"leopard-leftmenu-item"} style={{opacity: "0.3", display: "none"}}>
                        <div className={"leopard-leftmenu-item-text"}>About</div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    render() {
        let showOption = this.state.showMenuOptionPanel !== null;
        let showPanel = this.state.leftMenuItemsInitialized;
        LRH.ShowOrHideApplicationBackgroundCover(showOption);

        if (this.state.showMenuOptionPanel === undefined || this.state.showMenuOptionPanel === null ||
            showOption === false) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            this.state.showMenuOptionPanel.option === "add_dataview_options" && showOption === true) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px"}}>
                            {this.displayMenuOptionByType({option: "add_dataview_options"})}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            showOption === true && (this.state.showMenuOptionPanel.option === "add_dataview" ||
                this.state.showMenuOptionPanel.option === "export_dataview" ||
                this.state.showMenuOptionPanel.option === "import_dataview" ||
                this.state.showMenuOptionPanel.option === "clone_dataview")) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px", zIndex: "11"}}>
                            {this.displayMenuOptionByType({option: "add_dataview_options"})}
                        </div>
                        <div className="leopard-leftmenu-option-panel"
                             style={{minHeight: "160px", left: "695px", zIndex: "12"}}>
                            {this.displayMenuOptionByType(this.state.showMenuOptionPanel)}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showMenuOptionPanel !== undefined && this.state.showMenuOptionPanel !== null &&
            showOption === true && (this.state.showMenuOptionPanel.option === "edit_template" ||
                this.state.showMenuOptionPanel.option === "manage_dashboard_relationship")) {
            return (
                <React.Fragment>
                    <div className={"leopard-left-panel-container"}>
                        <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px", zIndex: "11"}}>
                            {this.displayMenuOptionByType({option: "edit_dashboard_options"})}
                        </div>
                        <div className="leopard-leftmenu-option-panel"
                             style={{minHeight: "160px", left: "695px", zIndex: "12"}}>
                            {this.displayMenuOptionByType(this.state.showMenuOptionPanel)}
                        </div>
                        {this.renderProgressLoadingPanel(!showPanel)}
                        {this.renderLeftMenuItemsPanel(showPanel)}
                        <br/>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className={"leopard-left-panel-container"}>
                    {
                        showOption === false ? "" :
                            <div className="leopard-leftmenu-option-panel" style={{minHeight: "160px"}}>
                                {this.displayMenuOptionByType(this.state.showMenuOptionPanel)}
                            </div>
                    }
                    {this.renderProgressLoadingPanel(!showPanel)}
                    {this.renderLeftMenuItemsPanel(showPanel)}
                    <br/>
                </div>
            </React.Fragment>
        );
    }
}

const
    RetrieveDataFromReducer = (state) => {
        return {state: state};
    };

const
    SendDataToReducer = (dispatch) => {
        return {
            SetLeftMenuItem: (data) => {
                dispatch(SetLeftMenuItem(data));
            },
            KeepGridViewDataById: (id) => {
                dispatch(KeepGridViewDataById(id));
            },
            KeepChartDataById: (id) => {
                dispatch(KeepChartDataById(id));
            }
        };
    };

export default connect(RetrieveDataFromReducer, SendDataToReducer)(LeopardMasterLeftMenu);