import React from 'react';
import { Auth } from 'aws-amplify';
import LDH from '../helpers/LeopardDataHelper';
import LRH from "../helpers/LeopardReactHelper";

class LeopardSecurity extends React.Component {
    static IsInputValueDangerous = (input) => {
        if (LDH.IsValueEmpty(input)) return false;

        var validity = false;
        if (input.toString().match(/[<>]+/) != null) {
            validity = true;
        }
        return validity;
    };

    static async UserLogin(username, password, callbackSuccess, callbackFailed, isTestMode) {
        if (isTestMode === true) {
            let user = { username: username };
            callbackSuccess("login-success-nochallenge", user);
        }
        else {
            try {
                const user = await Auth.signIn(username, password);
                if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    callbackSuccess("login-success-" + user.challengeName, user);
                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    callbackSuccess("login-success-" + user.challengeName, user);
                } else if (user.challengeName === 'MFA_SETUP') {
                    callbackSuccess("login-success-" + user.challengeName, user);
                } else {
                    callbackSuccess("login-success-nochallenge", user);
                }
            } catch (err) {
                if (err.code === 'UserNotConfirmedException') {
                    callbackFailed("LeopardSecurity_UserLogin_Failed1", err.code);
                } else if (err.code === 'PasswordResetRequiredException') {
                    callbackFailed("LeopardSecurity_UserLogin_Failed2", err.code);
                } else {
                    callbackFailed("LeopardSecurity_UserLogin_Failed3", err);
                }
            }
        }
    }

    static UserLogout(callback) {
        Auth.signOut({ global: false }).then(() => function(){
            if (callback !== undefined && callback !== null) {
                callback();
            }
        }).catch((error) => function(){
            LRH.ShowToast("Failed to logout of this user account. " + error, "error", 5000);
        });
    }

    static GetCurrentAuthenticatedUser(callbackSuccess, callbackFailed) {
        if (LRH.IsControlCentreInTestMode() === false) {
            Auth.currentAuthenticatedUser({bypassCache: false})
                .then(user => callbackSuccess(user))
                .catch(err => callbackFailed(err));
        }
        else{
            callbackSuccess("");
        }
    }

    static GetCurrentUserCredentials(callbackSuccess, callbackFailed) {
        Auth.currentUserCredentials(callbackSuccess, callbackFailed)
            .then(credential => callbackSuccess(credential))
            .catch(err => callbackFailed(err));
    }

    static GetCurrentUserSession(callbackSuccess, callbackFailed) {
        Auth.currentSession(callbackSuccess, callbackFailed)
            .then(session => callbackSuccess(session))
            .catch(err => callbackFailed(err));
    }
}

export default LeopardSecurity;